import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Button } from 'antd';
import { getFirstCharacterFromString, isTouch, openInNewTab } from 'common/utils/commonUtils';
import { logOutUser } from 'state/auth/actions';
import { UserSelector } from 'state/users/selectors';
import TruncatedName from 'common/TruncatedName/TruncatedName';
import './UserMenu.scss';
import { Icon } from '@iconify/react';
import downloadOutline from '@iconify-icons/eva/download-outline';
import { useLocation } from 'react-router-dom';
import { FilterSelector } from '../../../state/filter/selectors';
import ModalReport, { getPdfUrl } from '../../GEO/ModalReport/ModalReport';

const UserMenu: FC = (): JSX.Element | null => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { breakBy, selectedItem, period } = useSelector(FilterSelector);
  const [popupOpened, setPopupOpened] = useState<boolean>(false);
  const user = useSelector(UserSelector);
  if (user) {
    const initials = () =>
      user.firstName && user.lastName ? `${getFirstCharacterFromString(user.firstName)}${getFirstCharacterFromString(user.lastName)}` : '';

    return (
      <div className="user-menu flex items-center justify-end select-none py-12 pl-24 pr-20">
        {user.admin && pathname === '/geo' && selectedItem !== undefined && (
          <div
            className="create-report flex justify-center items-center p-14"
            onMouseDown={() => {
              if (isTouch) {
                openInNewTab(getPdfUrl(selectedItem, breakBy, period, ''));
              } else {
                setPopupOpened(true);
              }
            }}
          >
            <Icon width={25} icon={downloadOutline} />
          </div>
        )}

        <Avatar className="custom-avatar mr-8" size={34} gap={6}>
          {initials()}
        </Avatar>

        <div className="text-lg leading-8 user-name mr-20 ml-8">
          <TruncatedName title={`${user.firstName} ${user.lastName}` || ''} lines={2} />
        </div>
        <ModalReport
          popupOpened={popupOpened}
          setPopupOpened={setPopupOpened}
          breakBy={breakBy}
          selectedItem={selectedItem}
          period={period}
        />

        <Button
          className="logout-button"
          size="large"
          onClick={() => {
            dispatch(logOutUser());
          }}
        >
          Log out
        </Button>
      </div>
    );
  } else {
    return null;
  }
};

export default UserMenu;
