import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducers from 'state';

const enhancers: any[] = [];

const middleware: any[] = [thunk];

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-underscore-dangle
  const devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__;

  // Logger for Redux
  // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
  // const { createLogger } = require('redux-logger');
  // const logger = createLogger({ collapsed: true });
  // middleware.push(logger);

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension({}));
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(rootReducers, composedEnhancers);

export default store;
