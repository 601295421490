import * as types from 'state/auth/actionTypes';
import authService from 'common/services/authService';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/index';
import { AuthenticationRequest } from 'types/models';
import { getCurrentUserAction, GetCurrentUserActionType } from 'state/users/actions';

// All Actions Types
export type ActionsTypes = LoginUserActionType | LogOutUserActionType | GetCurrentUserActionType | LoadingActionType | ErrorActionType;

// Thunk Type
export type ThunkType<R extends ActionsTypes> = ThunkAction<
  Promise<R | ErrorActionType>,
  RootState,
  unknown,
  R | LoadingActionType | ErrorActionType
>;

// Loading action
type LoadingActionType = { type: typeof types.AUTH_LOADING; payload: LoadingTypes };
export const loadingAction = (payload: LoadingTypes): LoadingActionType => ({ type: types.AUTH_LOADING, payload });

// Error action
type ErrorActionType = { type: typeof types.AUTH_ERROR; payload: any };
export const errorAction = (payload: any): ErrorActionType => ({ type: types.AUTH_ERROR, payload });

// Login User
export type LoginUserActionType = { type: typeof types.AUTH_LOGIN_SUCCESS };
export const loginUserAction = (): LoginUserActionType => ({ type: types.AUTH_LOGIN_SUCCESS });
export const loginUser =
  (userCredentials: AuthenticationRequest): ThunkType<LoginUserActionType | GetCurrentUserActionType> =>
  async dispatch => {
    dispatch(loadingAction('post'));
    try {
      const response = await authService.login(userCredentials);
      dispatch(getCurrentUserAction(response.userInfo.user));
      return dispatch(loginUserAction());
    } catch (error: any) {
      return dispatch(errorAction(error.response.data));
    }
  };

// Log Out User
export type LogOutUserActionType = { type: typeof types.AUTH_LOGOUT_SUCCESS };
export const logOutUserAction = (): LogOutUserActionType => ({ type: types.AUTH_LOGOUT_SUCCESS });
export const logOutUser = (): ThunkAction<LogOutUserActionType, RootState, unknown, LogOutUserActionType> => dispatch => {
  authService.logout();
  return dispatch(logOutUserAction());
};
