import React from 'react';

class EnLangHtml {
  public getNationalSymbolsP1() {
    return (
      <>
        <p>
          Each country has symbols that distinguish it from others. Symbols can be seen on money, official documents, seals, postage stamps,
          etc. Our state symbols are listed in <b>Article 20 of the Constitution of Ukraine</b>. The state symbols of Ukraine are the coat
          of arms, the flag, and the national anthem.
        </p>
        <p>
          The national flag of Ukraine is a flag made of two equally sized horizontal stripes of blue and yellow colors. Blue is the color
          of the sky, and yellow is the color of the golden field and the sun. This is how Ukrainians interpret the meaning of national
          colors. National Flag Day in Ukraine is celebrated on <b>August 23</b>.
        </p>
        <p>
          Initially, the Cossacks used flags of different colors, crimson being the most popular. But already in the 18th century blue
          (blue) flags with the image of a Cossack on a golden shield field began to prevail in the <b>Hetmanshina</b>.
        </p>
      </>
    );
  }

  public getNationalSymbolsP2() {
    return (
      <p>
        Blue and yellow are the colors of the state symbols of the <b>Galicia-Volyn principality</b>. In the 19th century, Ukrainian
        patriots of Galicia began to use modern blue and yellow flags - two strips of fabric sewn together are much easier than embroidering
        a lion. At the beginning of the 20th century, blue-yellow flags spread to Transdnieper Ukraine. Ukrainian peasants saw in them a
        blue sky over a field of golden wheat.
      </p>
    );
  }

  public getNationalSymbolsP3() {
    return (
      <>
        <p>
          During the “Spring of Peoples”, on April 22, 1948, Ukrainian patriots hung the flag over the Lviv Town Hall. This was its{' '}
          <b>first public use</b>.
        </p>
        <p>
          The blue-yellow flag was the state flag of the Ukrainian People's Republic (UNR), the West Ukrainian People's Republic (ZUNR) and
          the Ukrainian State. It was taken over the building of the Central Council in March 1917. Ukrainian citizens demonstrated under
          this flag, and Ukrainian soldiers went into battle with it.
        </p>
        <p>
          <b>On August 24, 1991</b>, citizens brought a huge blue-yellow flag to the Verkhovna Rada. Its length was 8 m, and its width was 4
          m. Now it is housed in the museum of the Verkhovna Rada.
        </p>
        <p>In September 1991, the blue-yellow flag was raised over the Verkhovna Rada instead of the red Soviet flag .</p>
      </>
    );
  }

  public getNationalSymbolsP4() {
    return (
      <>
        <p>
          The small national coat of arms of Ukraine is a golden trident on a blue background. It is depicted on government institutions,
          official documents, forms, money, etc. The history of the trident as a symbol of the soul is ancient. He is depicted on the seal
          of Prince Svyatoslav (10th century). The Verkhovna Rada approved it on <b>February 19, 1992</b>.
        </p>
        <p>
          It is unknown whether the trident was actually a Ukrainian symbol or borrowed from neighboring nations. The opinions of
          researchers on this matter differ, as well as regarding what exactly it symbolizes. Some see in it a falcon, a crow, a tree with
          three branches, the top of a mace, an anchor, a bow and arrows, a helmet, an axe, and a fishing tool. To some, the trident
          resembles the top of a grain of bread, and to some, a combination of three natural elements - air, earth, and water.
        </p>
        <p>
          One of the <b>oldest tridents</b> found on the territory of Ukraine is a bronze trident from the Skaf mound. Lutova Mohyla
          (Zaporizhzhia region). Bronze birds sit on each of the teeth of the find. Scientists date it to the 3rd-4th centuries AD.
        </p>
      </>
    );
  }

  public getNationalSymbolsP5() {
    return (
      <>
        <p>
          The trident was a sign of the princely power of <b>Volodymyr the Great</b> and his descendants. Minted on silver coins of
          Volodymyr the Great. On one side of the coins, Vladimir himself was depicted, and on the other - a trident and the inscription:
          "Vladimir is on the table, and this is his silver." As a symbol of princely power, the trident was widespread in our lands for
          several centuries. Tridents were also depicted on rings, weapons, dishes, walls of houses, etc.
        </p>
      </>
    );
  }

  public getNationalSymbolsP5_1() {
    return (
      <>
        <p>
          More than 900 years have passed since the reign of Prince Volodymyr, before the trident again became the emblem of the Ukrainian
          state - the Ukrainian People's Republic. The author of the Coat of Arms was the outstanding Ukrainian artist and architect{' '}
          <b>Vasyl Krychevsky</b>.
        </p>
      </>
    );
  }

  public getNationalSymbolsP6() {
    return (
      <>
        <p>
          The National Anthem of Ukraine is the song <b>"Shche ne vmerla Ukrayiny"</b> (The glory and freedom of Ukraine has not yet
          perished). The anthem is a solemn patriotic song, a symbol of the unity of the Ukrainian nation. Every Ukrainian probably knows
          its text. The anthem is played during official events and sports competitions, on holidays and in difficult times. All solemn
          gatherings and events necessarily begin with the anthem and end with its performance. They listen to him standing. This song was
          born in 1863. During the revolution, it became the national anthem of the Ukrainian People's Republic (UPR) and the West Ukrainian
          People's Republic (WUPR) , and <b>in 1939 it became the national anthem of Ukraine</b>.
        </p>
        <p>
          The poem, which became the text of the anthem, was written by Pavlo Chubynskyi, a member of the Kyiv "Community". And three years
          after the publication of the poem, the priest Mykhailo Verbytsky wrote music for it. The authors of the anthem were not personally
          acquainted.
        </p>
        <p>
          The anthem of Ukraine was officially approved in the years of independence: <b>in 1992</b> - the musical version, and{' '}
          <b>in 2003</b> - the lyrics of the anthem. It consists of only six lines:
        </p>
        <p>
          <b>
            <span>The glory of Ukraine has not yet died, nor the will,</span>
            <span>Still upon us, young brothers, fate shall smile</span>
            <span>Our enemies shall vanish, like dew in the sun.</span>
            <span>We too shall rule, brothers, in our beloved country.</span>
          </b>
        </p>
        <p>
          <b>Chorus</b>
        </p>
        <p>
          <b>
            <span>Soul and body shall we lay down for our freedom,</span>
            <span>And show that we, brothers, are of the Cossack nation!</span>
          </b>
        </p>
      </>
    );
  }

  public getVerbytskyDescription() {
    return <>Mykhailo Verbytsky, the author of the anthem music.</>;
  }

  public getChubynskyDescription() {
    return <>Pavlo Chubynsky, the author of the anthem lyrics.</>;
  }

  public getCoatOfArmsDescription1() {
    return <>Small state coat of arms of the Ukrainian People's Republic of V. Krychevsky.</>;
  }

  public getCoatOfArmsDescription2() {
    return <>Great state coat of arms of the Ukrainian People's Republic according to the project of V. Krychevsky. 1918.</>;
  }

  public getCoatOfArmsDescription3() {
    return <>Great state seal of the UPR of V. Krychevsky. 1918.</>;
  }

  public getMarketingText() {
    return (
      <>
        <p>
          As part of Luxoft's internal initiatives to support its people charity projects, the Luxoft Horizon team created a web application
          to explore Ukraine's interactive map based on data from public sources.
        </p>
        <p>
          It was done to provide a way for interested people to get to know Ukraine's regions and cities, their history, and notable facts
          about them. This application enables those who want to learn about Ukraine to be empowered by data to grasp both the full-scale
          picture in map representation and different country details and facets. Additionally, the application provides the ability to test
          one's knowledge by passing various tests.
        </p>
        <p>
          This information can raise users' interest in a deeper investigation of Ukraine's culture, history, and traditions. Ukrainians now
          face the most difficult times in the history of independent Ukraine, yet show true, unbreakable will and Cossacks courage to
          defend their Motherland.
        </p>
        <p>
          We love our country and want to share this with you. Be curious, study, and test yourself in your spare time. It is trendy to know
          Ukraine!
        </p>
        <p>
          <a className={'link'} href="mailto:exploreukraineapp@gmail.com" target={'_blank'}>
            Contact us
          </a>{' '}
          for more information
        </p>
      </>
    );
  }
}

export default EnLangHtml;
