import jwtDecode from 'jwt-decode';
import axios from 'common/axios';
import Cookies from 'js-cookie';
import { AxiosError, AxiosResponse } from 'axios';
import AuthUtils from 'components/common/Auth/authUtils';
import { AuthenticationRequest, AuthenticationResponse, UserDto } from 'types/models';
import history from 'common/utils/historyUtils';

class AuthService extends AuthUtils {
  init(): void {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = (): void => {
    axios.interceptors.response.use(
      (response: AxiosResponse): AxiosResponse => response,
      (err: AxiosError): Promise<AxiosError> =>
        new Promise(() => {
          if (err.response?.status === 401 || err.response?.status === 403) {
            this.emit('onAutoLogout', 'Invalid access token');
          }
          throw err;
        }),
    );
  };

  handleAuthentication = (): void => {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      this.emit('onNoAccessToken');

      return;
    }

    if (this.isAuthTokenValid(accessToken)) {
      this.setSession(accessToken);
      this.emit('onAutoLogin', true);
    } else {
      this.emit('onAutoLogout', 'access token expired');
    }
  };

  login = ({ username, password }: AuthenticationRequest): Promise<AuthenticationResponse> =>
    new Promise((resolve, reject) => {
      axios
        .post<AuthenticationResponse>('/auth', { username, password })
        .then(response => {
          this.setSession(response.data.jwt);
          resolve(response.data);
        })
        .catch((error: AxiosError) => reject(error));
    });

  getCurrentUser = (): Promise<UserDto> =>
    new Promise((resolve, reject) => {
      axios
        .get<UserDto>('/api/users/current')
        .then(response => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          this.emit('onAutoLogout', 'Invalid access token');
          reject(error);
        });
    });

  logout = (): any => {
    const accessToken = this.getAccessToken();

    this.setSession(null);

    if (this.isAuthTokenValid(accessToken)) {
      return axios.get('/api/out').then(() => {
        this.setSession(null);
        history.push('/login');
      });
    }

    return history.push('/login');
  };

  setSession = (accessToken: string | null): void => {
    if (accessToken) {
      Cookies.set('jwt_token', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      Cookies.remove('jwt_token');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  isAuthTokenValid = (accessToken: string): boolean => {
    if (!accessToken) {
      return false;
    }
    const decoded: { exp: number } = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    return decoded.exp >= currentTime;
  };

  getAccessToken = (): string => <string>Cookies.get('jwt_token');
}

const instance = new AuthService();

export default instance;
