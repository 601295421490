import React, { FC } from 'react';
import { GeoJSON } from 'react-leaflet';
import geoDataRoads from '../../common/geojson/UA/sources/www.diva-gis.org/UKR_rds.json';
import uawhole from '../../common/geojson/UA/sources/www.diva-gis.org/UKR_adm0-2.json';

const Roads: FC = (): JSX.Element => {
  return (
    <>
      <GeoJSON
        data={geoDataRoads as GeoJSON.GeoJsonObject}
        style={() => {
          return {
            weight: 1,
            opacity: 1,
            color: 'rgba(20, 150, 255, 0.5)',
            dashArray: '0',
            fillOpacity: 1,
          };
        }}
      />
      <GeoJSON
        data={uawhole as GeoJSON.GeoJsonObject}
        style={() => {
          return {
            weight: 2,
            opacity: 1,
            fillColor: 'rgba(0, 0, 0, 0)',
            color: 'rgba(20, 150, 255, 0.5)',
            dashArray: '0',
            fillOpacity: 1,
          };
        }}
      />
    </>
  );
};

export default Roads;
