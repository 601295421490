import { createContext } from 'react';

interface ContextType {
  theme: string;
  locale: string;
  devMode: boolean;
  testResults: { [key: string]: number };
  setTheme: (t: string) => void;
  setLocale: (t: string) => void;
  setDevMode: (t: boolean) => void;
  setTestResults: (t: { [key: string]: number }) => void;
}

export default createContext<ContextType>({
  theme: 'dark',
  locale: 'uk-UA',
  devMode: false,
  testResults: {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setTheme: (t: any): void => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setLocale: (t: any): void => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDevMode: (t: any): void => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setTestResults: (t: { [key: string]: number }): void => {},
});
