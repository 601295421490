import { ColorScheme } from '../../types/project';
import { BlueTheme } from './BlueTheme';
import { DarkTheme } from './DarkTheme';
import { LightTheme } from './LightTheme';
import { DefaultTheme } from './DefaultTheme';
import { DefaultThemeRed } from './DefaultThemeRed';

export const Themes: { [name: string]: ColorScheme } = Object.freeze({
  default: DefaultTheme,
  defaultRed: DefaultThemeRed,
  blue: BlueTheme,
  dark: DarkTheme,
  light: LightTheme,
});

export const getThemeColors = (theme: string) => {
  return Themes[theme] || DefaultTheme;
};
