import { ColorScheme } from '../../types/project';

export const DefaultTheme: ColorScheme = Object.freeze({
  name: 'default',

  region: '#595959',
  regionBorder: '#8C8C8C',
  regionHighlight: '#096DD9',
  regionHighlightBorder: '#003A8C',
  regionCorrect: '#BAE637',
  regionCorrectBorder: '#7CB305',
  regionIncorrect: '#FF4D4F',
  regionIncorrectBorder: '#CF1322',
  cityIncorrect: '#FF4D4F',
  cityIncorrectBorder: '#CF1322',

  cityColor: '#FFF700',
});
