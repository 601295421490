import React, { FC, useContext, useEffect, useRef, useState } from 'react';

import L, { LatLngTuple } from 'leaflet';
import { MapContainer, Pane, TileLayer } from 'react-leaflet';
// postCSS import of Leaflet's CSS
import 'leaflet/dist/leaflet.css';
import './Landing.scss';
import { isPhone, isPortrait } from '../../../common/utils/commonUtils';
import AppContext from '../../../common/AppContext';
import DevCityNames from './dev/DevCityNames';
import DevWater from './dev/DevWater';
import CityDots from '../common/components/CityDots';
import DevRailway from './dev/DevRailway';
import Roads from './dev/DevRoads';
import { Button, Divider, Modal, Popover, Select } from 'antd';
import { useIntl } from 'react-intl';
import DevCityTest from './dev/DevCityTest';
import DevKidsMap from './dev/DevKidsMap';
import { Themes } from '../../../common/themes/Themes';
import coatOfArms from '../../../styles/assets/images/UA-coat-of-arms.svg';
import flag from '../../../styles/assets/images/Ukrainian_language.svg';
import VladimirTrident from '../../../styles/assets/images/VladimirsTrident.svg';
import sunnyfield from '../../../styles/assets/images/reaped-field-green-hill-view-sunny-day-france_35977-1520_cr.jpg';
import { useHistory } from 'react-router-dom';
import TestChoosePopup from './TestChoosePopup';
import * as d3 from 'd3';
import { zoomToLayer } from '../../../common/utils/leafletUtils';
import Regions from '../common/components/Regions';
import { Icon } from '@iconify/react';
import closeOutline from '@iconify-icons/eva/close-outline';

const { Option } = Select;

const Landing: FC = (): JSX.Element => {
  const intl = useIntl();
  const history = useHistory();
  const geoJSONRef = React.useRef<any>(null);
  const map = useRef<any | undefined>(undefined);
  const [coatOfArmsPopoverShow, setCoatOfArmsPopoverShow] = useState<boolean>(false);
  const [flagPopoverShow, setFlagPopoverShow] = useState<boolean>(false);
  const [showTestChoosePopup, setShowTestChoosePopup] = useState<boolean>(false);
  const zoomCenter = useRef<LatLngTuple | undefined>([48.8, 32]);
  const zoomLevel = useRef<number | undefined>(5);

  const { theme, setTheme, locale, setLocale, devMode, testResults } = useContext(AppContext);

  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const onResizeScreen = () => {
    setTimeout(() => {
      if (map.current && map.current.getPanes().mapPane !== undefined) {
        map.current.invalidateSize();
        zoomToLayer(map.current, geoJSONRef.current, false);
        forceUpdate();
      }
    }, 200);
  };

  const onOrientationChange = () => {
    forceUpdate();
    onResizeScreen();
  };

  useEffect(() => {
    if (map.current && map.current.getPanes().mapPane !== undefined) {
      map.current.invalidateSize();
    }
    forceUpdate();
  }, [theme, devMode]);

  useEffect(() => {
    window.addEventListener('resize', onResizeScreen);
    window.addEventListener('orientationchange', onOrientationChange);
    return () => {
      window.removeEventListener('resize', onResizeScreen);
      window.removeEventListener('orientationchange', onOrientationChange);
    };
  }, [onResizeScreen, onOrientationChange]);

  const onThemeChange = (newTheme: string): void => {
    setTheme(newTheme);
  };

  const onLocaleChange = (newLocale: string): void => {
    setLocale(newLocale);
  };

  const getDevMenu = () => {
    return (
      <div className={`left-menu flex flex-col p-10`}>
        <div className="flex items-center pr-10 select-none">
          <div
            onMouseDown={() => {
              onLocaleChange('uk-UA');
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/svg/ua.svg`}
              alt={'UA'}
              className={`flag my-6 mr-6 ${locale === 'uk-UA' ? 'selected' : ''}`}
            />
          </div>
          <div
            onMouseDown={() => {
              onLocaleChange('en-US');
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/svg/us.svg`}
              alt={'EN'}
              className={`flag my-6 mr-6 ${locale === 'en-US' ? 'selected' : ''}`}
            />
          </div>
        </div>
        <div className="flex items-center pr-10">
          <Select value={theme} style={{ width: 280 }} onChange={onThemeChange}>
            {Object.keys(Themes).map((themeName: string) => {
              return (
                <Option key={themeName} value={themeName}>
                  {intl.formatMessage({ id: themeName })}
                </Option>
              );
            })}

            <Option value="water">{intl.formatMessage({ id: 'water' })}</Option>
            <Option value="roads">{intl.formatMessage({ id: 'roads' })}</Option>
            <Option value="railway">{intl.formatMessage({ id: 'railway' })}</Option>
            <Option value="designregionstest">{intl.formatMessage({ id: 'regionstest' })}</Option>
            <Option value="designcitiestest">{intl.formatMessage({ id: 'citiestest' })}</Option>
            <Option value="designregionsneighbourstest">{intl.formatMessage({ id: 'regionsneighbourstest' })}</Option>
            <Option value="designregionsShapeTest">{intl.formatMessage({ id: 'regionsShapeTest' })}</Option>
            <Option value="kids">{intl.formatMessage({ id: 'kids' })}</Option>
          </Select>
        </div>
        <Divider />
        <Button
          style={{ width: 280, backgroundColor: 'rgba(255,100,0,0.9)', marginBottom: 10 }}
          onMouseDown={() => {
            onThemeChange('designregionstest');
          }}
        >
          {intl.formatMessage({ id: 'regionstest' })}
        </Button>
        <Button
          style={{ width: 280, backgroundColor: 'rgba(0,100,255,0.9)', marginBottom: 10 }}
          onMouseDown={() => {
            onThemeChange('designcitiestest');
          }}
        >
          {intl.formatMessage({ id: 'citiestest' })}
        </Button>
        <Button
          style={{ width: 280, backgroundColor: 'rgba(100,255,0,0.9)', marginBottom: 10 }}
          onMouseDown={() => {
            onThemeChange('designregionsneighbourstest');
          }}
        >
          {intl.formatMessage({ id: 'regionsneighbourstest' })}
        </Button>
        <Button
          style={{ width: 280, backgroundColor: 'rgba(200,200,100,0.9)', marginBottom: 10 }}
          onMouseDown={() => {
            onThemeChange('designregionsShapeTest');
          }}
        >
          {intl.formatMessage({ id: 'regionsShapeTest' })}
        </Button>
      </div>
    );
  };

  const getMap = () => {
    const southWest = L.latLng(-89.98155760646617, -180);
    const northEast = L.latLng(89.99346179538875, 180);
    const bounds = L.latLngBounds(southWest, northEast);

    return (
      <div className="map-wrapper landing-map">
        <MapContainer
          attributionControl={false}
          center={zoomCenter.current}
          zoom={zoomLevel.current}
          zoomControl={false}
          minZoom={isPhone ? 0 : 1}
          maxZoom={17}
          maxBounds={bounds}
          preferCanvas={false}
          // preferCanvas={theme === 'designcanvas'}
          // renderer={theme === 'designcanvas' ? L.canvas() : L.svg()}
          tap={false}
          // disable zoom and pan
          doubleClickZoom={false}
          closePopupOnClick={false}
          dragging={false}
          zoomSnap={0}
          zoomDelta={0}
          trackResize={false}
          touchZoom={false}
          scrollWheelZoom={false}
          keyboard={false}
          // END disable zoom and pan
          ref={(ref: any) => {
            // map initialized

            // if (ref && ref.map) {
            // @ts-ignore
            if (geoJSONRef && geoJSONRef.current) {
              setTimeout(() => {
                // @ts-ignore
                if (map.current === undefined) {
                  // @ts-ignore
                  zoomToLayer(ref, geoJSONRef.current, false);
                }
                map.current = ref;
              }, 100);

              setTimeout(() => {
                d3.selectAll('.region-city').interrupt();
                d3.selectAll('.region-city')
                  .transition()
                  .duration(700)
                  .ease(d3.easeCubicOut)
                  .attr('stroke-opacity', 1)
                  .attr('fill-opacity', 1);
              }, 1000);
            }
            // }
          }}
        >
          <>
            <Pane name="rivers" style={{ zIndex: 10001, pointerEvents: 'none' }} />
            <Pane name="borders" style={{ zIndex: 10002, pointerEvents: 'none' }} />
            <Pane name="city" style={{ zIndex: 10003 }} />
            <Pane name="markers" style={{ zIndex: 10004 }} />
            <Pane name="tooltips" style={{ zIndex: 10005 }} />

            {theme === 'dark' && (
              <TileLayer
                url="https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                noWrap={false}
              />
            )}

            {theme === 'light' && (
              <TileLayer
                url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png"
                // url="https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}{r}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                noWrap={false}
              />
            )}
          </>

          {theme === 'water' && <DevWater />}

          {theme === 'railway' && <DevRailway />}

          {theme === 'roads' && <Roads />}

          {(theme === 'railway' || theme === 'roads') && <CityDots color={'rgba(20, 150, 255, 0.8)'} />}

          {(theme === 'default' || theme === 'blue') && <CityDots bigCityOpacity={0} className={'region-city'} />}

          {theme === 'designcity' && <DevCityNames />}

          {theme !== 'water' && theme !== 'roads' && theme !== 'railway' && (
            <Regions
              geoJSONRef={geoJSONRef}
              showNameOnHover={true}
              nameOpacity={0}
              customOnClick={() => {
                history.push('/geo/explore');
              }}
            />
          )}
          {theme === 'designcitiestest' && <DevCityTest />}
          {theme === 'kids' && <DevKidsMap />}
        </MapContainer>
      </div>
    );
  };

  const getGreetings = () => {
    const coatOfArmsTooltipContent = (
      <div
        className={'landing-tooltip cursor-pointer'}
        onMouseDown={() => {
          setCoatOfArmsPopoverShow(false);
          history.push('/geo/symbols#coatOfArms');
        }}
      >
        <div className={'flex flex-row'}>
          <div>{intl.formatMessage({ id: 'countryInfoEmblemDescriptionShort' })}</div>
          <div style={{ width: 66 }}>
            <img src={VladimirTrident} alt={'VladimirTrident'} />
          </div>
        </div>
        <div className={'popup-link'}>{intl.formatMessage({ id: 'countryInfoKnowMore' })}</div>
      </div>
    );
    const flagTooltipContent = (
      <div
        className={'landing-tooltip cursor-pointer'}
        style={{ maxWidth: 412 }}
        onMouseDown={() => {
          setFlagPopoverShow(false);
          history.push('/geo/symbols');
        }}
      >
        <div className={'flex flex-row'}>
          <div>{intl.formatMessage({ id: 'countryInfoFlagDescriptionShort' })}</div>
          <div style={{ width: 153 }}>
            <img src={sunnyfield} alt={'sunnyfield'} />
          </div>
        </div>
        <div className={'popup-link'}>{intl.formatMessage({ id: 'countryInfoKnowMore' })}</div>
      </div>
    );

    return (
      <div className={'greeting-text'}>
        <div>
          <div className={'greetings'}>
            <div>{intl.formatMessage({ id: 'greetings1' })}</div>
            <div style={{ marginTop: -10 }}>{intl.formatMessage({ id: 'greetings2' })}</div>
          </div>
          <div className={'supporting-text'}>
            <div>{intl.formatMessage({ id: 'greetings3' })}</div>
            <div>{intl.formatMessage({ id: 'greetings4' })}</div>
          </div>
          <div className={'buttons'}>
            <Button
              className={'test-button'}
              onMouseDown={() => {
                setShowTestChoosePopup(true);
              }}
            >
              {intl.formatMessage({ id: 'testButton' })}{' '}
              <span className={'test-result'}>
                {'(' +
                  (Object.keys(testResults).length > 0
                    ? String(
                        Math.floor(
                          ((testResults.regionbyname | 0) + (testResults.citybyname | 0) + (testResults.regionbylicenseplate | 0)) / 3,
                        ),
                      )
                    : '0') +
                  '%)'}
              </span>
            </Button>
            <Button
              className={'explore-button'}
              onMouseDown={() => {
                history.push('/geo/explore');
              }}
            >
              {intl.formatMessage({ id: 'exploreButton' })}
            </Button>
          </div>
        </div>

        {isPortrait() && getMap()}

        <div>
          <div className={'country-info flex'}>
            <div className={'country-info-column flex flex-col pr-28'}>
              <div className={'country-info-header'}>{intl.formatMessage({ id: 'countryInfoYearCreated' })}</div>
              <div className={'country-info-value'}>{intl.formatMessage({ id: 'countryInfoYearCreatedValue' })}</div>
            </div>
            <div className={'country-info-column flex flex-col'}>
              <div className={'country-info-header'}>{intl.formatMessage({ id: 'countryInfoCapital' })}</div>
              <div className={'country-info-value'}>{intl.formatMessage({ id: 'countryInfoCapitalValue' })}</div>
            </div>
          </div>
          <div className={'country-info flex'}>
            <div className={'country-info-column flex flex-col pr-28'}>
              <div className={'country-info-header'}>{intl.formatMessage({ id: 'countryInfoFormOfGovernment' })}</div>
              <div className={'country-info-value'}>{intl.formatMessage({ id: 'countryInfoFormOfGovernmentValue' })}</div>
            </div>
            <div className={'country-info-column flex flex-col'}>
              <div className={'country-info-header'}>{intl.formatMessage({ id: 'countryInfoPopulation' })}</div>
              <div className={'country-info-value'}>
                {intl.formatMessage({ id: 'countryInfoPopulationValue' })}
                <span className={'country-info-value-comment'}>{intl.formatMessage({ id: 'countryInfoPopulationValueComment' })}</span>
              </div>
            </div>
          </div>
          <div className={'country-info flex'}>
            <div className={'country-info-column flex flex-col pr-28'}>
              <div className={'country-info-header'}>{intl.formatMessage({ id: 'countryInfoArea' })}</div>
              <div className={'country-info-value'}>{intl.formatMessage({ id: 'countryInfoAreaValue' })}</div>
            </div>
            <div className={'country-info-column flex flex-col'}>
              <div className={'country-info-header flex flex-row justify-start'}>
                <div style={{ width: 50 }}>{intl.formatMessage({ id: 'countryInfoEmblemAndFlag' })}</div>
                <Popover
                  placement="right"
                  content={coatOfArmsTooltipContent}
                  trigger="hover"
                  visible={coatOfArmsPopoverShow}
                  onVisibleChange={show => {
                    setCoatOfArmsPopoverShow(show);
                  }}
                >
                  <div
                    onMouseDown={() => {
                      setFlagPopoverShow(false);
                      history.push('/geo/symbols#coatOfArms');
                    }}
                  >
                    <img className={'pl-30 cursor-pointer'} src={coatOfArms} alt={'UA-coat-of-arms'} />
                  </div>
                </Popover>
                <Popover
                  placement="right"
                  content={flagTooltipContent}
                  trigger="hover"
                  visible={flagPopoverShow}
                  onVisibleChange={show => {
                    setFlagPopoverShow(show);
                  }}
                >
                  <div
                    onMouseDown={() => {
                      setFlagPopoverShow(false);
                      history.push('/geo/symbols');
                    }}
                  >
                    <img className={'pl-20 h-64 cursor-pointer'} src={flag} alt={'UA-flag'} />
                  </div>
                </Popover>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col flex-1">
      <div className="geo-root flex-1 flex flex-row">
        {devMode && getDevMenu()}
        {getGreetings()}
        {!isPortrait() && getMap()}
        <Modal
          title={intl.formatMessage({ id: 'testChooseHeader' })}
          centered
          width={'auto'}
          closeIcon={<Icon icon={closeOutline} width={22} height={22} />}
          visible={showTestChoosePopup}
          footer={null}
          onOk={() => setShowTestChoosePopup(false)}
          onCancel={() => setShowTestChoosePopup(false)}
        >
          <TestChoosePopup
            onClose={() => {
              setShowTestChoosePopup(false);
            }}
          />
        </Modal>
      </div>
    </div>
  );
};

export default Landing;
