import { cloneElement, FC, MouseEvent, useState } from 'react';
import { Icon } from '@iconify/react';
import { Button, Drawer } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import UpdateUserForm from 'components/Administration/UpdateUserForm';
import { useSelector } from 'react-redux';
import { UsersLoadingSelector } from 'state/users/selectors';
import closeOutline from '@iconify-icons/eva/close-outline';

interface EditActionProps {
  currentTabKey: string;
  editObject: any;
}

const EditAction: FC<EditActionProps> = ({ currentTabKey, editObject }): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [formObj, setFormObj] = useState<FormObj>();
  const loadingUsers = useSelector(UsersLoadingSelector) === 'put';

  const configTab: CommonObj = {
    users: {
      onUpdateTitle: 'User Details',
    },
  };

  const handleOnClick = (e: any): void => {
    e.stopPropagation();
    setIsModalVisible(true);
  };

  const handleOnClose = (e: any): void => {
    e.stopPropagation();
    setIsModalVisible(false);
  };

  const handleHideModal = (): void => {
    setIsModalVisible(false);
  };

  const UPDATE_FORM_FOR_TAB: Record<string, JSX.Element> = {
    users: <UpdateUserForm user={editObject} />,
  };

  const getCurrentTabFormComponent: JSX.Element = UPDATE_FORM_FOR_TAB[currentTabKey];

  return (
    <div onClick={(e: MouseEvent<HTMLElement>): void => e.stopPropagation()} className="h-full w-full">
      <button type="button" className="text-base text-nero font-bold cursor-pointer h-full w-full edit-button" onClick={handleOnClick}>
        <EditOutlined className="mr-4" />
        Edit
      </button>
      <Drawer
        title={configTab[currentTabKey].onUpdateTitle}
        placement="right"
        maskClosable={false}
        onClose={handleOnClose}
        visible={isModalVisible}
        width={350}
        footerStyle={{ paddingInline: 24 }}
        closeIcon={<Icon icon={closeOutline} width={22} height={22} />}
        footer={
          <div className="flex justify-end">
            <Button type="default" className="mr-12" onClick={handleHideModal}>
              Cancel
            </Button>
            <Button type="primary" onClick={() => formObj?.submit()} loading={loadingUsers}>
              Update
            </Button>
          </div>
        }
      >
        {cloneElement(getCurrentTabFormComponent, { handleHideModal, formObj, setFormObj })}
      </Drawer>
    </div>
  );
};

export default EditAction;
