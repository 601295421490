import { FC, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import UserOffline from './routes/Private/UserOffline';
import UserOnline from './routes/Private/UserOnline';
import GeoHunting from './routes/JobPostingTool/GeoHunting';
import Login from './routes/Login';
import OrganisationAdministrationPage from './routes/Administration/Administration';
import PDFReport from './routes/PDFReport/PDFReport';
import { getIntlMessages } from '../common/localization';
import { IntlProvider } from 'react-intl';
import AppContext from 'common/AppContext';
import { initializeIcons } from '@uifabric/icons';

const App: FC = (): JSX.Element => {
  const [theme, setTheme] = useState<string>(JSON.parse(localStorage.getItem('theme') as string) || 'default');
  const [locale, setLocale] = useState<string>(JSON.parse(localStorage.getItem('locale') as string) || 'uk-UA');
  const [devMode, setDevMode] = useState<boolean>(JSON.parse(localStorage.getItem('devMode') as string) || false);
  const [testResults, setTestResults] = useState<{ [key: string]: number }>(
    JSON.parse(localStorage.getItem('testResults') as string) || {},
  );
  const messages = getIntlMessages(locale);

  useEffect(() => {
    initializeIcons();
  }, []);

  useEffect(() => {
    localStorage.setItem('locale', JSON.stringify(locale));
    localStorage.setItem('theme', JSON.stringify(theme));
    localStorage.setItem('devMode', JSON.stringify(devMode));
    localStorage.setItem('testResults', JSON.stringify(testResults));
  }, [locale, theme, devMode, testResults]);

  return (
    <IntlProvider messages={messages} locale={locale}>
      <AppContext.Provider value={{ theme, setTheme, locale, setLocale, devMode, setDevMode, testResults, setTestResults }}>
        <Switch>
          <UserOffline exact path="/">
            <Redirect to="/geo/landing" />
          </UserOffline>
          <UserOffline exact path="/login" component={Login} pageProps={{ title: 'Log In' }} />
          <UserOnline exact path="/">
            <Redirect to="/geo/landing" />
          </UserOnline>
          <UserOnline exact path="/geo/*" component={GeoHunting} pageProps={{ title: '' }} />
          <UserOnline exact path="/administration">
            <Redirect to="/administration/users" />
          </UserOnline>
          <UserOnline
            exact
            path="/administration/:tabId?"
            component={OrganisationAdministrationPage}
            pageProps={{ title: 'Administration' }}
          />
          <UserOnline exact path="/reportpdf" component={PDFReport} pageProps={{ title: 'PDFReport' }} />
          <Route exact path="*">
            <Redirect to="/geo/landing" />
          </Route>
        </Switch>
      </AppContext.Provider>
    </IntlProvider>
  );
};
export default App;
