import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { compareByDate, compareByParams, compareIgnoringCase } from 'common/utils/commonUtils';
import EditAction from 'components/Administration/common/EditAction';
import Checkmark from 'components/Administration/common/Checkmark';

import { UsersDataSourceSelector, UsersLoadingSelector } from 'state/users/selectors';
import { UserDataSource } from 'types/base';

const Users: FC = (): JSX.Element => {
  const dataSource = useSelector(UsersDataSourceSelector);
  const loading = useSelector(UsersLoadingSelector) === 'getAll';

  const columns: ColumnsType<UserDataSource> = [
    {
      key: 'firstName',
      title: 'First name',
      dataIndex: 'firstName',
      width: 160,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: {
        compare: (a, b) => compareIgnoringCase(a.firstName, b.firstName),
      },
    },
    {
      key: 'lastName',
      title: 'Last Name',
      dataIndex: 'lastName',
      width: 160,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'ascend',
      sorter: {
        compare: (a, b): number => compareIgnoringCase(a.lastName, b.lastName),
      },
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: 250,
      sorter: {
        compare: (a, b): number => compareIgnoringCase(a.email, b.email),
      },
    },
    {
      key: 'admin',
      title: 'Administration access',
      dataIndex: 'admin',
      width: 135,
      align: 'center',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: {
        compare: (a: UserDataSource, b: UserDataSource): number => compareByParams(a, b, 'admin'),
      },
      render: (value: boolean | null): ReactNode => <Checkmark value={value} />,
    },
    {
      key: 'userId',
      title: 'User ID',
      dataIndex: 'userId',
      width: 100,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: {
        compare: (a: UserDataSource, b: UserDataSource): number => compareByParams(a, b, 'userId'),
      },
    },
    {
      key: 'lastModifiedBy',
      title: 'Last Modified by',
      dataIndex: 'lastModifiedBy',
      width: 200,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: {
        compare: (a, b): number => compareIgnoringCase(a.lastModifiedBy, b.lastModifiedBy),
      },
    },
    {
      key: 'lastModified',
      title: 'Last Modified',
      dataIndex: 'lastModified',
      width: 210,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: {
        compare: (a, b): number => compareByDate(a, b, 'lastModified'),
      },
    },
    {
      key: 'edit',
      title: '',
      dataIndex: '',
      align: 'right',
      width: 84,
      render: (user: UserDataSource): ReactNode => (
        <div className="action-block">
          <EditAction currentTabKey="users" editObject={user} />
        </div>
      ),
    },
  ];

  return (
    <Table
      className="admin-table"
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      scroll={{ x: 1100, y: 'calc(100vh - 310px)' }}
      loading={loading}
    />
  );
};

export default Users;
