import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import AppContext from 'common/AppContext';

import './PDFReport.scss';
import { findByAlpha3 } from 'iso-3166-1-ts';
import { getCountryNameByIso3 } from '../../../common/utils/commonUtils';

const PDFReport: FC = () => {
  const [theme, setTheme] = useState<string>('light');
  const [locale, setLocale] = useState<string>('uk-UA');
  const [devMode, setDevMode] = useState<boolean>(false);
  const [testResults, setTestResults] = useState<{ [key: string]: number }>({});
  const appContainer = useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatch();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const selectedItem = urlParams.get('selectedItem') || undefined;

  useEffect(() => {
    // dispatch(getScores(1));
  }, [dispatch]);

  useEffect(() => {
    document.body.className = 'pdf-report-body';
  }, []);

  return (
    <AppContext.Provider
      value={{
        theme,
        setTheme,
        locale,
        setLocale,
        devMode,
        setDevMode,
        testResults,
        setTestResults,
      }}
    >
      <div className={`pdf-report theme-${theme} inset-0 framework-report`} ref={appContainer}>
        <div className="report-header pt-32 pb-16">
          <div style={{ fontSize: '40px' }}>Job Postings</div>
          {getCountryNameByIso3(selectedItem) !== '' ? (
            <div style={{ fontSize: '38px', paddingTop: '20px' }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/svg/${findByAlpha3(selectedItem || '')?.alpha2?.toLowerCase() || 'aq'}.svg`}
                alt={selectedItem}
                className="mr-8 w-48"
              />
              {getCountryNameByIso3(selectedItem) || 'Unknown'}
            </div>
          ) : (
            <div style={{ fontSize: '38px', paddingTop: '20px' }}>{selectedItem || 'Unknown'}</div>
          )}
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default PDFReport;
