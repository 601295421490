import { FC } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { isNumber } from 'lodash';
import { ReactComponent as CheckIcon } from 'styles/assets/images/circle.svg';

interface CheckmarkProps {
  value: boolean | number | null;
}

const Checkmark: FC<CheckmarkProps> = ({ value }): JSX.Element => (
  <>{(isNumber(value) && value >= 0) || value === true ? <CheckCircleFilled className="text-2xl" /> : <CheckIcon />}</>
);

export default Checkmark;
