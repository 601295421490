const ua = {
  'Luxoft Geo': 'Luxoft Geo',
  ukraine: 'Україна',
  menuHeader: 'Меню',
  menuMain: 'Головна',
  menuNationalSymbols: 'Державні символи України',
  aboutus: 'Про проект',
  time: 'Час в Україні',

  geohunting: 'Інтерактивна Україна',
  greetings1: 'Ласкаво просимо',
  greetings2: 'до України',
  greetings3: 'Вітаємо на інтерактивній мапі. Цікавтесь, вивчайте та перевіряйте себе у вільний час.',
  greetings4: 'Знати Україну модно!',
  testButton: 'Перевірити себе',
  exploreButton: 'Вивчати мапу',
  countryInfoYearCreated: 'Рік заснування',
  countryInfoYearCreatedValue: '882 від Р.Х.',
  countryInfoFormOfGovernment: 'Форма правління',
  countryInfoFormOfGovernmentValue: 'Унітарна парламентсько-президентська республіка',
  countryInfoArea: 'Площа',
  countryInfoAreaValue: '603 700 км² ',
  countryInfoCapital: 'Столиця',
  countryInfoCapitalValue: 'Київ',
  countryInfoPopulation: 'Населення',
  countryInfoPopulationValue: '42 929 345 осіб',
  countryInfoPopulationValueComment: ' (лютий 2022, в т.ч Автономна Республіка Крим станом на 01.04.2014 року)',
  countryInfoEmblemAndFlag: 'Державні символи',
  countryInfoEmblemDescriptionShort:
    'Головним елементом малого Державного Герба України є Знак Княжої Держави Володимира Великого (малий Державний Герб України).',
  countryInfoFlagDescriptionShort:
    'Найбільш популярне тлумачення українського прапору таке, що синій колір символізує небо, а жовтий — пшеничне колосся.',
  countryInfoKnowMore: 'Дізнатися більше',
  backToLanding: 'На головну',
  exploreSettings: 'Налаштування',
  exploreShow: 'Відображати',
  exploreNames: 'Назви населених пунктів',
  exploreRivers: 'Річки',
  exploreVyshyvanka: 'Вишиванки',
  exploreDishes: 'Страви',
  back: 'Назад',
  wiki: 'Перейти до вікі',

  regionInfoFoundationYear: 'Рік заснування',
  regionInfoRegionalCenter: 'Обласний центр',
  regionInfoTerritory: 'Площа',
  regionInfoPopulation: 'Населення',
  regionInfoCarRegionIdentifiers: 'Номери машин',
  regionInfoBorders: 'Кордони',
  regionInfoCoatOfArms: 'Герб',
  regionInfoFlag: 'Прапор',
  regionInfoAnthem: 'Гімн',

  testChooseHeader: 'Пізнавальні тести',
  testChooseTest: 'Оберіть тест за темою та збільшуйте ваші знання території України',
  testChooseGuess: 'Вкажіть',
  testChooseSoon: 'незабаром',
  testChooseRegionByName: 'область на мапі',
  testChooseRegionByLicensePlate: 'область за номером авто',
  testChooseRegionByShape: 'область за формою',
  testChooseRegionByNeighbours: 'область за сусідами',
  testChooseCityByName: 'місто на мапі',
  testChooseCityByRegion: 'місто за областю',
  testChooseCityByPlace: 'місто за розташуванням',
  testMapQuestion: 'Позначте на мапі',
  testMapQuestionCity: 'Вкажіть на мапі у межах 30 км',
  testMapStopTest: 'Завершити завчасно',
  testMapAdditionalInfo: 'Відповідь вважається правильною якщо ви влучили у межах 30 км',
  testNotification1: 'Не переймайся якщо помилився!',
  testNotification2: 'У нас є',
  testNotification3: 'роздiл вивчення мапи',
  testNotification4: ', де можна пiдтягнути знання',
  testResult: 'Результат',
  testResultAdditionalText1: 'Тест ',
  testResultAdditionalText2: ' завершено, ваші відповіді:',
  testResultPercent: 'Успіх',
  testResultCorrect: 'Правильних',
  testResultIncorrect: 'Неправильних',
  testResultTime: 'Час виконання',
  testResultTryAgain: 'Спробувати ще',
  testCityDistance: 'км до міста',

  dark: 'Темна',
  light: 'Світла',
  default: 'Дизайн',
  defaultRed: 'Дизайн Червоний',
  blue: 'Дизайн Блакитний',

  water: 'Вода',
  roads: 'Дороги',
  railway: 'Залізниця',
  regionstest: 'Тестування по областям',
  citiestest: 'Тестування по містам',
  regionsneighbourstest: 'Тестування за сусідніми областями',
  regionsShapeTest: 'Тестування по областям за формою',
  kids: 'Мапа для дітей',

  km: 'км',
};

export default ua;
