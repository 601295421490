import React, { FC, useContext, useMemo, useState } from 'react';
import { GeoJSON, Marker, Tooltip } from 'react-leaflet';
import AppContext from '../../../../common/AppContext';
import { getThemeColors } from '../../../../common/themes/Themes';
import L, { LatLng } from 'leaflet';
import uajson from '../../common/geojson/UA/ukraine-geo.json';
import * as topojson from 'topojson-client';
import uacitiesjson from '../../common/geojson/UA-cities/ua-cities.json';
import { useIntl } from 'react-intl';
import * as d3 from 'd3';
import { getHtmlIconStyle } from '../../../../common/utils/leafletUtils';
import dayjs from 'dayjs';

interface TestCityProps {
  geoJSONRef: any;
  questionsAndAnswers: { [key: string]: { correct: boolean | undefined; time: number } };
  localizedQuestions: { [key: string]: { [key: string]: string } };
  currentQuestion: string;
  onFinishTest: () => void;
  nextQuestion: () => void;
}

const TestCity: FC<TestCityProps> = ({
  geoJSONRef,
  currentQuestion,
  onFinishTest,
  questionsAndAnswers,
  nextQuestion,
  localizedQuestions,
}): JSX.Element => {
  const { theme, locale } = useContext(AppContext);
  const themeColors = useMemo(() => getThemeColors(theme), [theme]);
  const intl = useIntl();
  const maxDistanceToWin = 40; // in km
  let startTime = dayjs();

  // @ts-ignore
  const geoDataRegions = topojson.feature(uajson, uajson.objects.regions).features;
  const [answer, setAnswer] = useState<LatLng | undefined>(undefined);

  function selectPoint(latlng: any) {
    setAnswer(latlng);

    const cityToGuessJson = uacitiesjson.filter(city => city.city === currentQuestion)[0];
    const cityToGuessLatLng = L.latLng(Number(cityToGuessJson.lat), Number(cityToGuessJson.lng));
    const distance = Math.round(latlng.distanceTo(cityToGuessLatLng) / 1000);

    setTimeout(() => {
      const markerSelection =
        distance > maxDistanceToWin
          ? d3.selectAll('.leaflet-markers-pane .leaflet-marker-icon')
          : d3.select('.leaflet-markers-pane .leaflet-marker-icon');
      const markerTooltipSelection =
        distance > maxDistanceToWin
          ? d3.selectAll('.leaflet-tooltips-pane .leaflet-tooltip')
          : d3.select('.leaflet-tooltips-pane .leaflet-tooltip');

      markerSelection.interrupt();
      markerSelection.transition().duration(300).ease(d3.easeCubicOut).style('opacity', 1);

      markerTooltipSelection.interrupt();
      markerTooltipSelection.transition().duration(300).ease(d3.easeCubicOut).style('opacity', 1);
    }, 100);

    // @ts-ignore
    questionsAndAnswers[currentQuestion].correct = distance <= maxDistanceToWin;
    questionsAndAnswers[currentQuestion].time = dayjs().diff(startTime);
    const unansweredQuestions = Object.keys(questionsAndAnswers).filter(question => questionsAndAnswers[question].correct === undefined);
    if (unansweredQuestions.length === 0) {
      onFinishTest();
    }

    setTimeout(() => {
      const markerSelection =
        distance > maxDistanceToWin
          ? d3.selectAll('.leaflet-markers-pane .leaflet-marker-icon')
          : d3.select('.leaflet-markers-pane .leaflet-marker-icon');
      const markerTooltipSelection =
        distance > maxDistanceToWin
          ? d3.selectAll('.leaflet-tooltips-pane .leaflet-tooltip')
          : d3.select('.leaflet-tooltips-pane .leaflet-tooltip');

      markerSelection.interrupt();
      markerSelection.transition().duration(300).ease(d3.easeCubicOut).style('opacity', 0);

      markerTooltipSelection.interrupt();
      markerTooltipSelection.transition().duration(300).ease(d3.easeCubicOut).style('opacity', 0);
      // setTimeout(() => {
      //   setAnswer(undefined);
      // }, 400);

      setTimeout(() => {
        nextQuestion();
        setAnswer(undefined);
        startTime = dayjs();
      }, 400);
    }, 1000);
  }

  const geoJsonHandlers = useMemo(
    () => ({
      mousedown(e: any) {
        if (answer === undefined) {
          selectPoint(e.latlng);
        }
      },
    }),
    [answer, currentQuestion],
  );

  const cityToGuessJson = uacitiesjson.filter(
    city => city.city === currentQuestion && (city.capital === 'admin' || city.capital === 'primary'),
  )[0];
  if (currentQuestion !== undefined && currentQuestion !== '' && cityToGuessJson) {
    const cityToGuessLatLng = L.latLng(Number(cityToGuessJson.lat), Number(cityToGuessJson.lng));
    let selectedPointText = '';
    let distance = 0;
    if (answer) {
      distance = Math.round(answer.distanceTo(cityToGuessLatLng) / 1000);
      selectedPointText = String(distance) + ' ' + intl.formatMessage({ id: 'testCityDistance' });
    }

    return (
      <>
        <GeoJSON
          pane={'city'}
          data={geoDataRegions as GeoJSON.GeoJsonObject}
          eventHandlers={geoJsonHandlers}
          style={() => {
            return {
              fillColor: 'transparent',
              weight: 1,
              opacity: 1,
              color: themeColors.regionBorder,
              dashArray: '0',
              fillOpacity: 1,
            };
          }}
          ref={(ref: any) => {
            geoJSONRef.current = ref;
          }}
        >
          {answer && (
            <Marker
              pane={'markers'}
              position={cityToGuessLatLng}
              icon={getHtmlIconStyle(themeColors.regionCorrect, themeColors.regionCorrectBorder)}
              opacity={0}
            >
              <Tooltip pane={'tooltips'} direction="right" offset={[20, -23]} opacity={0} permanent>
                {localizedQuestions[cityToGuessJson.city][locale]}
              </Tooltip>
            </Marker>
          )}

          {answer && distance > maxDistanceToWin && (
            <Marker
              pane={'markers'}
              position={answer}
              icon={getHtmlIconStyle(themeColors.cityIncorrect, themeColors.cityIncorrectBorder)}
              opacity={0}
            >
              <Tooltip pane={'tooltips'} direction="right" offset={[20, -23]} opacity={0} permanent>
                {selectedPointText}
              </Tooltip>
            </Marker>
          )}
        </GeoJSON>
      </>
    );
  } else {
    return <></>;
  }
};

export default TestCity;
