import React, { FC, useContext, useEffect, useMemo } from 'react';
import { GeoJSON, Marker, Tooltip } from 'react-leaflet';
import * as topojson from 'topojson-client';
import uajson from '../geojson/UA/ukraine-geo.json';
import AppContext from '../../../../common/AppContext';
import L from 'leaflet';
import * as d3 from 'd3';
import { getThemeColors } from '../../../../common/themes/Themes';
import { isTouch } from '../../../../common/utils/commonUtils';
import { noIconStyle } from '../../../../common/utils/leafletUtils';
import { saveUserAction } from '../../../../state/data/actions';
import { useDispatch } from 'react-redux';

interface RegionsInfoProps {
  geoJSONRef: any;
  useOnClick?: boolean;
  nameOpacity?: number;
  showNameOnHover?: boolean;
  showRivers?: boolean;
  longRegionName?: boolean;
  tooltipClass?: string;
  onModalChange?: () => void;
  customOnClick?: () => void;
  selectedRegion?: any | undefined;
  selectRegion?: (region: any | undefined) => void;
}

const Regions: FC<RegionsInfoProps> = ({
  geoJSONRef,
  useOnClick = false,
  nameOpacity = 0,
  showNameOnHover = true,
  showRivers = true,
  longRegionName = true,
  tooltipClass,
  onModalChange,
  customOnClick,
  selectedRegion,
  selectRegion,
}): JSX.Element => {
  const dispatch = useDispatch();
  const { theme, locale } = useContext(AppContext);
  const themeColors = useMemo(() => getThemeColors(theme), [theme]);
  const tooltips: any[] = [];
  let highlightedLayer: any;
  // const mylatesttap = 0;
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  // @ts-ignore
  const geoDataRegions = topojson.feature(uajson, uajson.objects.regions).features;
  // @ts-ignore
  const geoDataRivers = topojson.feature(uajson, uajson.objects.rivers).features;
  // @ts-ignore
  const geoDataLakes = topojson.feature(uajson, uajson.objects.lakes).features;

  const onOrientationChange = () => {
    forceUpdate();
  };

  useEffect(() => {
    window.addEventListener('orientationchange', onOrientationChange);
    return () => {
      window.removeEventListener('orientationchange', onOrientationChange);
    };
  }, [onOrientationChange]);

  const resetHighlight = (layer: any) => {
    d3.select(layer._path).interrupt();
    d3.select(layer._path)
      .transition()
      .duration(300)
      .ease(d3.easeCubicOut)
      .attr('fill', 'transparent')
      .attr('stroke-width', 1)
      .attr('stroke', themeColors.regionBorder);

    if (showNameOnHover) {
      const tooltipLayer = tooltips.filter(
        (tooltip: any) => tooltip.options.children === layer.feature.properties.localized_name[locale],
      )[0];
      if (tooltipLayer) {
        d3.select(tooltipLayer.getElement()).interrupt();
        d3.select(tooltipLayer.getElement()).transition().duration(300).ease(d3.easeCubicOut).style('opacity', 0);
      }
    }

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToBack();
    }
  };
  /*
  const resetHighlightAll = () => {
    if (geoJSONRef && geoJSONRef.current) {
      const geoJSON = geoJSONRef.current;
      for (const layerUa of geoJSON.getLayers()) {
        resetHighlight(layerUa);
      }
    }
  };*/

  const highlightLayer = (layer: any, doResetHighlight?: boolean, fill?: string, stroke?: string) => {
    if (doResetHighlight) {
      resetHighlight(highlightedLayer);
    }
    highlightedLayer = layer;

    if (layer !== undefined && layer !== null) {
      d3.select(layer._path).interrupt();
      d3.select(layer._path)
        .transition()
        .duration(300)
        .ease(d3.easeCubicOut)
        .attr('fill', fill ? fill : themeColors.regionHighlight)
        .attr('stroke-width', 2)
        .attr('stroke', stroke ? stroke : themeColors.regionHighlightBorder);

      if (showNameOnHover) {
        const tooltipLayer = tooltips.filter(
          (tooltip: any) => tooltip.options.children === layer.feature.properties.localized_name[locale],
        )[0];
        if (tooltipLayer) {
          d3.select(tooltipLayer.getElement()).interrupt();
          d3.select(tooltipLayer.getElement()).transition().duration(300).ease(d3.easeCubicOut).style('opacity', 1);
        }
      }

      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        layer.bringToFront();
      }
    }
  };

  const selectLayer = (layer: any) => {
    if (selectRegion) {
      if (layer) {
        resetHighlight(layer);
        setTimeout(() => {
          selectRegion(layer);
        }, 100);

        dispatch(
          saveUserAction('click', '', layer.feature.properties.name, layer.feature.properties.localized_name[locale], theme, locale),
        );
      } else {
        selectRegion(layer);
      }
      if (onModalChange) {
        onModalChange();
      }
    }
  };

  function onClick(e: any) {
    // if (isTouch) {
    //   const now = Date.now();
    //   const timesince = now - mylatesttap;
    //   if (timesince < 600 && timesince > 0) {
    //     // double tap
    //     selectLayer(e.layer);
    //   } else {
    //     // too much time to be a doubletap
    //     highlightLayer(e.layer);
    //   }
    //
    //   mylatesttap = Date.now();
    // } else {
    selectLayer(e.layer);
    // }
  }

  const geoJsonHandlers = useMemo(
    () => ({
      mouseover: (e: any) => {
        if (!isTouch) {
          highlightLayer(e.layer);
        }
      },
      mousemove: () => {
        // Tooltip.updateTooltipPosition(e.originalEvent.clientX, e.originalEvent.clientY);
      },
      mouseout: (e: any) => {
        if (!isTouch) {
          resetHighlight(e.layer);
        }
        // Tooltip.immediateCloseTooltip();
      },
      mousedown: (e: any) => {
        if (customOnClick) {
          customOnClick();
        }
        if (!useOnClick) {
          onClick(e);
        }
      },
      click: (e: any) => {
        if (useOnClick) {
          onClick(e);
        }
      },
    }),
    [selectedRegion],
  );

  return (
    <>
      <GeoJSON
        data={geoDataRegions as GeoJSON.GeoJsonObject}
        style={() => {
          return {
            fillColor: themeColors.region,
            weight: 1,
            opacity: 1,
            color: 'transparent',
            dashArray: '0',
            fillOpacity: 1,
          };
        }}
      />

      {showRivers && (
        <>
          <GeoJSON
            pane={'rivers'}
            data={geoDataRivers as GeoJSON.GeoJsonObject}
            style={() => {
              return {
                fillColor: themeColors.regionHighlight,
                weight: 2,
                opacity: 1,
                color: themeColors.regionHighlight,
                dashArray: '0',
                fillOpacity: 1,
              };
            }}
          />
          <GeoJSON
            pane={'rivers'}
            data={geoDataLakes as GeoJSON.GeoJsonObject}
            style={() => {
              return {
                fillColor: themeColors.regionHighlight,
                weight: 1,
                opacity: 1,
                color: themeColors.regionHighlight,
                dashArray: '0',
                fillOpacity: 1,
              };
            }}
          />
        </>
      )}
      <GeoJSON
        pane={'borders'}
        data={geoDataRegions as GeoJSON.GeoJsonObject}
        eventHandlers={geoJsonHandlers}
        style={() => {
          return {
            fillColor: 'transparent',
            weight: 1,
            opacity: 1,
            color: themeColors.regionBorder,
            dashArray: '0',
            fillOpacity: 1,
            pointerE0vents: 'none',
          };
        }}
        ref={(ref: any) => {
          geoJSONRef.current = ref;
        }}
      >
        {uajson.objects.regions.geometries.map((region: any, index: number) => {
          const regionNameLatLng = L.latLng(
            Number(region.properties.label_point[locale][1]),
            Number(region.properties.label_point[locale][0]),
          );
          let name;
          if (longRegionName) {
            name = region.properties.localized_name[locale];
          } else {
            name = region.properties.localized_short_name[locale];
          }
          return (
            <Marker key={index} pane={'markers'} position={regionNameLatLng} icon={noIconStyle()}>
              <Tooltip
                ref={ref => {
                  if (ref) {
                    tooltips.push(ref);
                    // console.log(tooltips);
                  }
                }}
                position={regionNameLatLng}
                pane={'tooltips'}
                className={tooltipClass}
                direction="center"
                opacity={nameOpacity ? nameOpacity : 0}
                permanent
              >
                {name}
              </Tooltip>
            </Marker>
          );
        })}
      </GeoJSON>
    </>
  );
};

export default Regions;
