import dataService from 'common/services/dataService';
import * as types from 'state/users/actionTypes';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/index';
import { CreateUserRequest, UpdateUserRequest, UserDto } from 'types/models';

// All Actions Types
export type ActionsTypes =
  | LoadingActionType
  | ErrorActionType
  | GetUsersActionType
  | GetCurrentUserActionType
  | CreateUserActionType
  | UpdateUserActionType;

// Thunk Type
type ThunkType<R extends ActionsTypes> = ThunkAction<
  Promise<R | ErrorActionType>,
  RootState,
  unknown,
  R | LoadingActionType | ErrorActionType
>;

// Loading action
type LoadingActionType = { type: typeof types.USERS_LOADING; payload: LoadingTypes };
export const loadingAction = (payload: LoadingTypes): LoadingActionType => ({ type: types.USERS_LOADING, payload });

// Error action
type ErrorActionType = { type: typeof types.USERS_ERROR; payload: any };
export const errorAction = (payload: any): ErrorActionType => ({ type: types.USERS_ERROR, payload });

// Get Current User
export type GetCurrentUserActionType = { type: typeof types.GET_CURRENT_USER_SUCCESS; payload: UserDto };
export const getCurrentUserAction = (payload: UserDto): GetCurrentUserActionType => ({
  type: types.GET_CURRENT_USER_SUCCESS,
  payload,
});
export const getCurrentUser =
  (response: UserDto): ThunkAction<GetCurrentUserActionType, RootState, unknown, GetCurrentUserActionType> =>
  dispatch =>
    dispatch(getCurrentUserAction(response));

// Get Users
type GetUsersActionType = { type: typeof types.GET_USERS_SUCCESS; payload: UserDto[] };
export const getUsersAction = (payload: UserDto[]): GetUsersActionType => ({ type: types.GET_USERS_SUCCESS, payload });
export const getUsers = (): ThunkType<GetUsersActionType> => async dispatch => {
  dispatch(loadingAction('getAll'));
  try {
    const response = await dataService.getUsers();
    return dispatch(getUsersAction(response));
  } catch (error: any) {
    return dispatch(errorAction(error.response.data));
  }
};

// Create User
type CreateUserActionType = { type: typeof types.CREATE_USER_SUCCESS; payload: UserDto };
export const createUserAction = (payload: UserDto): CreateUserActionType => ({
  type: types.CREATE_USER_SUCCESS,
  payload,
});
export const createUser =
  (user: CreateUserRequest): ThunkType<CreateUserActionType> =>
  async dispatch => {
    dispatch(loadingAction('post'));
    try {
      const response = await dataService.createUser(user);
      return dispatch(createUserAction(response));
    } catch (error: any) {
      return dispatch(errorAction(error.response.data));
    }
  };

// Update User
export type UpdateUserActionType = { type: typeof types.UPDATE_USER_SUCCESS; payload: UserDto; lastModifiedBy?: string };
export const updateUserAction = (payload: UserDto, lastModifiedBy?: string): UpdateUserActionType => ({
  type: types.UPDATE_USER_SUCCESS,
  payload,
  lastModifiedBy,
});
export const updateUser =
  (userId: number, user: UpdateUserRequest): ThunkType<UpdateUserActionType> =>
  async (dispatch, getState) => {
    const lastModifiedBy = getState().users.user?.lastModifiedBy;

    dispatch(loadingAction('put'));
    try {
      const response = await dataService.updateUser(userId, user);
      return dispatch(updateUserAction(response, lastModifiedBy));
    } catch (error: any) {
      return dispatch(errorAction(error.response.data));
    }
  };
