import { createSelector } from 'reselect';
import { formatLongDateWithTime } from 'common/utils/dateUtils';
import { RootState } from 'state/index';
import { AccessPermission, UserDto, UserPermissionsDto } from 'types/models';
import { InstanceUser, UserDataSource } from 'types/base';
import { UsersState } from './reducer';

export const UserSelector = createSelector([(state: RootState): UsersState => state.users], (users): UserDto => <UserDto>users.user);
export const UsersSelector = createSelector([(state: RootState): UsersState => state.users], (users): UserDto[] => users.users);
export const UsersDataSourceSelector = createSelector([UsersSelector], (users): UserDataSource[] =>
  users.map(
    ({ id, lastModified, userPermissions, ...restParams }): UserDataSource =>
      <UserDataSource>{
        ...restParams,
        id,
        key: id,
        userId: id,
        lastModified: formatLongDateWithTime(lastModified),
        userPermissions: Object.entries(userPermissions).map(
          ([instanceId, permission]): AccessPermission => ({
            instanceId: +instanceId,
            permission,
          }),
        ),
      },
  ),
);
export const InstanceUsersSelector = createSelector([UsersSelector], (users): InstanceUser[] =>
  users.map(
    ({ id, firstName, lastName, userPermissions }): InstanceUser => ({
      id,
      fullName: `${firstName} ${lastName}`,
      userPermissions: Object.entries(userPermissions).map(
        ([userId, permission]): UserPermissionsDto => ({
          userId: +userId,
          permission,
        }),
      ),
    }),
  ),
);
export const UsersErrorSelector = createSelector([(state: RootState): UsersState => state.users], (users): any => users.error);
export const UsersLoadingSelector = createSelector([(state: RootState): UsersState => state.users], (users): LoadingTypes => users.loading);
