import { ColorScheme } from '../../types/project';

export const BlueTheme: ColorScheme = Object.freeze({
  name: 'blue',

  region: '#086DD9',
  regionBorder: 'rgba(255,255,255,0.5)',
  regionHighlight: '#022cce',
  regionHighlightBorder: '#FFFFFF',
  regionCorrect: 'rgba(51,153,51, 0.8)',
  regionCorrectBorder: '#FFF',
  regionIncorrect: 'rgba(153,51,51, 0.8)',
  regionIncorrectBorder: '#FFF',
  cityIncorrect: '#FF4D4F',
  cityIncorrectBorder: '#CF1322',

  cityColor: '#FFF700',
});
