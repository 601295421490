import { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { UserSelector } from 'state/users/selectors';
import { useSelector } from 'react-redux';
import AppPage from 'components/common/AppPage';

interface Props extends RouteProps {
  pageProps?: PageProps;
}

const UserOffline: FC<Props> = ({ component: Component, children, pageProps, ...rest }) => {
  const user = useSelector(UserSelector);

  return (
    <Route
      {...rest}
      render={props =>
        !props.location.pathname.includes('geo') && !user ? (
          <AppPage pageProps={pageProps}>{Component ? <Component {...props} /> : children}</AppPage>
        ) : (
          <Redirect
            to={{
              pathname: '/geo',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default UserOffline;
