const en = {
  'Luxoft Geo': 'Luxoft Geo',
  ukraine: 'Ukraine',
  menuHeader: 'Menu',
  menuMain: 'Home',
  menuNationalSymbols: 'National symbols of Ukraine',
  aboutus: 'About project',
  time: 'Time in Ukraine',

  geohunting: 'Interactive Map of Ukraine',
  greetings1: 'Welcome',
  greetings2: 'to Ukraine',
  greetings3: 'Welcome to the interactive map. Be curious, study and test yourself in your spare time.',
  greetings4: 'It is trendy to know Ukraine!',
  testButton: 'Test yourself',
  exploreButton: 'Explore the map',
  countryInfoYearCreated: 'Year of formation',
  countryInfoYearCreatedValue: '882 A.D.',
  countryInfoFormOfGovernment: 'Form of government',
  countryInfoFormOfGovernmentValue: 'Unitary parliamentary-presidential republic',
  countryInfoArea: 'Area',
  countryInfoAreaValue: '603 700 km² ',
  countryInfoCapital: 'Capital',
  countryInfoCapitalValue: 'Kyiv',
  countryInfoPopulation: 'Population',
  countryInfoPopulationValue: '42 929 345 people',
  countryInfoPopulationValueComment: ' (February 2022, including Autonomous Republic of Crimea as of April 1, 2014)',
  countryInfoEmblemAndFlag: 'National symbols',
  countryInfoEmblemDescriptionShort:
    'The main element of the large State Coat of Arms of Ukraine is the Emblem of the Princely State of Volodymyr the Great (small State Coat of Arms of Ukraine).',
  countryInfoFlagDescriptionShort:
    'The most popular interpretation of the Ukrainian flag is that the blue color symbolizes the sky, and the yellow color symbolizes the ear of wheat.',
  countryInfoKnowMore: 'Know more',
  backToLanding: 'Home',
  exploreSettings: 'Settings',
  exploreShow: 'Show',
  exploreNames: 'City names',
  exploreRivers: 'Rivers',
  exploreVyshyvanka: 'Vyshyvanka',
  exploreDishes: 'Dishes',
  back: 'Back',
  wiki: 'Go to wiki',

  regionInfoFoundationYear: 'Founded',
  regionInfoRegionalCenter: 'Regional Center',
  regionInfoTerritory: 'Territory',
  regionInfoPopulation: 'Population',
  regionInfoCarRegionIdentifiers: 'License plate codes',
  regionInfoBorders: 'Borders',
  regionInfoCoatOfArms: 'Coat of arms',
  regionInfoFlag: 'Flag',
  regionInfoAnthem: 'Anthem',

  testChooseHeader: 'Informative tests',
  testChooseTest: 'Choose a test by topic and increase your knowledge of the territory of Ukraine',
  testChooseGuess: 'Point',
  testChooseSoon: 'soon',
  testChooseRegionByName: 'Oblast identification on the map',
  testChooseRegionByLicensePlate: 'Oblast identification by car license plate',
  testChooseRegionByShape: 'Oblast identification by shape',
  testChooseRegionByNeighbours: 'Oblast identification by neighbors',
  testChooseCityByName: 'City identification on the map',
  testChooseCityByRegion: 'City identification by oblast',
  testChooseCityByPlace: 'City identification by location',
  testMapQuestion: 'Click on the map',
  testMapQuestionCity: 'Point on the map within 30 km',
  testMapStopTest: 'Interrupt test',
  testMapAdditionalInfo: 'Answer is considered as correct if you point a city within 30 km',
  testNotification1: "Don't worry if you made a mistake!",
  testNotification2: 'We have ',
  testNotification3: 'explore the map section',
  testNotification4: ', where you can learn more',
  testResult: 'Result',
  testResultAdditionalText1: 'Test ',
  testResultAdditionalText2: ' is complete, your results:',
  testResultPercent: 'Success',
  testResultCorrect: 'Correct',
  testResultIncorrect: 'Incorrect',
  testResultTime: 'Execution time',
  testResultTryAgain: 'Try again',
  testCityDistance: 'km to city',

  dark: 'Dark',
  light: 'Light',
  default: 'Design',
  defaultRed: 'Design Red',
  blue: 'Design Blue',

  water: 'Water',
  roads: 'Roads',
  railway: 'Railway',
  regionstest: 'Start regions test',
  citiestest: 'Start cities test',
  regionsneighbourstest: 'Start regions by neighbours test',
  regionsShapeTest: 'Start regions by shape test',
  kids: 'Ukraine map for kids',

  km: 'km',
};

export default en;
