import React, { FC, useContext, useMemo } from 'react';

import { LatLngTuple } from 'leaflet';
import { Circle, Tooltip } from 'react-leaflet';
import uacitiesjson from '../geojson/UA-cities/ua-cities.json';
import { DefaultTheme } from '../../../../common/themes/DefaultTheme';
import * as d3 from 'd3';
import AppContext from '../../../../common/AppContext';
import { saveUserAction } from '../../../../state/data/actions';
import { useDispatch } from 'react-redux';
import { isTouch } from '../../../../common/utils/commonUtils';
// import closeOutline from '@iconify-icons/eva/close-outline';
// import { Icon } from '@iconify/react';
// const translit = require('translitit-cyrillic-ukrainian-to-latin');
// const convert = require('translit-english-ukrainian');

interface CityDotsProps {
  map?: any;
  cityCircles?: any;
  color?: string;
  bigCityOpacity?: number;
  bigCityRadius?: number;
  smallCityOpacity?: number;
  smallCityRadius?: number;
  tooltipOpacity?: number;
  interactive?: boolean;
  className?: string;
  tooltipClass?: string;
  region?: any;
  useDirection?: boolean;
  showAdminCenterOnly?: boolean;
  showNameOnHover?: boolean;
  showNamesAdminCenterOnly?: boolean;
  showNames?: boolean;
  onCityClick?: (cityName: string, tooltipLayer: any) => void;
  selectedCity?: string | undefined;
}

export function getWikiUrl(cityName: string, locale: string) {
  const cityJsons = uacitiesjson.filter(city => Object.values(city.name).includes(cityName));
  let url = '';
  if (cityJsons.length > 0) {
    // @ts-ignore
    url = cityJsons[0].link[locale];
  }

  if (url === '') {
    url =
      locale === 'uk-UA'
        ? `https://uk.wikipedia.org/wiki/${encodeURIComponent(cityName)}`
        : locale === 'en-US'
        ? `https://en.wikipedia.org/wiki/${cityName}`
        : '';
  }
  return url;
}

export function getMobileWikiUrl(cityName: string, locale: string) {
  return getWikiUrl(cityName, locale).replace('wikipedia.org', 'm.wikipedia.org');
}

const CityDots: FC<CityDotsProps> = ({
  // map,
  cityCircles,
  color,
  bigCityOpacity = 1,
  bigCityRadius,
  smallCityOpacity = 1,
  smallCityRadius,
  tooltipOpacity = 0,
  interactive = false,
  className = '',
  tooltipClass = '',
  region,
  showAdminCenterOnly = true,
  showNames = false,
  showNamesAdminCenterOnly = false,
  showNameOnHover = true,
  useDirection = true,
  onCityClick,
  selectedCity,
}): JSX.Element => {
  const dispatch = useDispatch();
  const { theme, locale } = useContext(AppContext);

  const cityEventHandlers = useMemo(
    () => ({
      mouseover: (event: any) => {
        if (!isTouch) {
          const tooltipLayer = event.target.getTooltip();
          if (tooltipLayer) {
            d3.select(tooltipLayer.getElement()).interrupt();
            if (showNameOnHover) {
              d3.select(tooltipLayer.getElement()).transition().duration(300).ease(d3.easeCubicOut).style('opacity', 1);
            }
            tooltipLayer.bringToFront();

            let newTransform = d3.select(tooltipLayer.getElement()).style('transform');
            if (d3.select(tooltipLayer.getElement()).style('transform').indexOf('scale(') >= 0) {
              newTransform = d3
                .select(tooltipLayer.getElement())
                .style('transform')
                .substring(0, d3.select(tooltipLayer.getElement()).style('transform').indexOf('scale(') - 1);
            }

            d3.select(tooltipLayer.getElement())
              .transition()
              .duration(300)
              .ease(d3.easeCubicOut)
              .style('transform', newTransform + ' scale(1.2, 1.2)');
          }
        }
      },
      mouseout: (event: any) => {
        if (!isTouch) {
          const tooltipLayer = event.target.getTooltip();
          if (tooltipLayer) {
            d3.select(tooltipLayer.getElement()).interrupt();
            if (showNameOnHover) {
              d3.select(tooltipLayer.getElement()).transition().duration(300).ease(d3.easeCubicOut).style('opacity', 0);
            }

            if (d3.select(tooltipLayer.getElement()).style('transform').indexOf('scale(') > 0) {
              d3.select(tooltipLayer.getElement())
                .transition()
                .duration(300)
                .ease(d3.easeCubicOut)
                .style(
                  'transform',
                  d3
                    .select(tooltipLayer.getElement())
                    .style('transform')
                    .substring(0, d3.select(tooltipLayer.getElement()).style('transform').indexOf('scale(') - 1),
                );
            }
          }
        }
      },
      click: (event: any) => {
        const tooltipLayer = event.target.getTooltip();
        if (tooltipLayer) {
          const cityName: string = tooltipLayer.getElement().innerText;
          const url = getWikiUrl(cityName, locale);

          if (onCityClick) {
            onCityClick(cityName, tooltipLayer);
          } else {
            if (interactive) {
              // if (url !== '') {
              //   window.open(url, '_blank')?.focus();
              // }
            }
          }
          dispatch(saveUserAction('click', '', cityName, url, theme, locale));
        }
      },
    }),
    [locale],
  );
  // ualocations.forEach((uaLocation: any) => {
  // uaLocation.name.en = translit(uaLocation.name.uk);

  // uaLocation.name.en = uaLocation.name.en.replace(/'/g, '');
  // uaLocation.name.en = uaLocation.name.en.replace(/’/g, '');

  // uaLocation.name.en = uaLocation.name.en.trim();
  // uaLocation.name.ru = uaLocation.name.ru.trim();
  // uaLocation.name.uk = uaLocation.name.uk.trim();
  // });
  // console.log(ualocations);

  // console.log(
  //   uacitiesjson
  //     .map((city: any) => {
  //       const name: { [key: string]: string } = {};
  //       name['en-US'] = city.city;
  //
  //       const cityLocation = ualocations.filter((uaLocation: any) => {
  //         return (
  //           Object.values(uaLocation.name)
  //             .filter(name => name !== undefined && name !== null)
  //             .includes(city.city) && uaLocation.type !== 'STATE'
  //         );
  //       })[0];
  //       if (cityLocation !== undefined) {
  //         name['uk-UA'] = cityLocation.name.uk;
  //       }
  //
  //       // console.log(city, cityLocation);
  //       if (name['uk-UA'] === undefined) {
  //         const population = uacitiespopulationjson.filter(cityLocal => cityLocal.Name.includes(city.city));
  //         if (population.length > 0) {
  //           name['uk-UA'] = population[0].Native;
  //         }
  //       }
  //
  //       if (name['uk-UA'] === undefined) {
  //         name['uk-UA'] = convert(city.city);
  //         console.log(city.city, name['uk-UA']);
  //       }
  //
  //       if (name['uk-UA'] !== undefined) {
  //         return {
  //           city: city.city,
  //           lat: city.lat,
  //           lng: city.lng,
  //           admin_name: city.admin_name,
  //           capital: city.capital,
  //           population: city.population,
  //           name: name,
  //         };
  //       } else {
  //         // console.log(city.capital, city.city);
  //       }
  //     })
  //     .filter(city => city !== undefined),
  // );

  if (cityCircles) {
    cityCircles.current = {};
  }

  return (
    <>
      {uacitiesjson.map((city: any) => {
        const isCapital = city.capital === 'primary' || city.capital === 'admin';
        const regionCityOfInterest = !showAdminCenterOnly && city.show === true; /*city.capital === 'minor' || city.population > 20000*/
        const insideSelectedRegion = region === undefined || city.admin_name === region.feature.properties.localized_name['en-US'];

        const cityName = city.name[locale];
        // const url = getMobileWikiUrl(cityName, locale);

        if ((isCapital || regionCityOfInterest) && insideSelectedRegion) {
          const isSmallCity = city.capital !== 'primary' && city.capital !== 'admin';
          const direction = useDirection ? city.tooltipDirection : 'top';

          return (
            <Circle
              key={cityName}
              pane={'city'}
              center={[city.lat, city.lng] as LatLngTuple}
              fill={true}
              color={selectedCity === cityName ? DefaultTheme.cityColor : color ? color : DefaultTheme.cityColor}
              fillColor={selectedCity === cityName ? DefaultTheme.cityColor : color ? color : DefaultTheme.cityColor}
              fillOpacity={isSmallCity ? smallCityOpacity : bigCityOpacity}
              opacity={isSmallCity ? smallCityOpacity : bigCityOpacity}
              radius={city.capital === 'primary' ? 8000 : city.capital === 'admin' ? bigCityRadius || 3500 : smallCityRadius || 2500}
              className={`city-circle ${className} ${isSmallCity ? 'city-circle-small' : 'city-circle-big'} ${
                selectedCity === cityName ? 'selected' : ''
              }`}
              eventHandlers={cityEventHandlers}
              ref={(ref: any) => {
                if (ref && cityCircles) {
                  cityCircles.current[cityName] = { ref: ref, isAdminCenter: !isSmallCity, tooltipDirection: city.tooltipDirection2 };
                }
              }}
              interactive={interactive}
            >
              {/*{showDetailsPopup && (
                  <Popup
                    position={[city.lat, city.lng] as LatLngTuple}
                    className={`${tooltipClass} ${className}-tooltip ${isSmallCity ? 'city-tooltip-small' : 'city-tooltip-big'}`}
                    pane={'popups'}
                    offset={[0, 463]}
                    maxHeight={700}
                    minWidth={400}
                    closeButton={false}
                    closeOnEscapeKey={true}
                    autoClose={true}
                    closeOnClick={true}
                  >
                    <iframe style={{ height: 400, width: 393 }} title={'wiki'} src={url} />
                    <div
                      className={'close-icon'}
                      onMouseDown={() => {
                        if (map && map.getPanes().mapPane !== undefined) {
                          map.closePopup();
                        }
                      }}
                    >
                      <Icon icon={closeOutline} width={22} height={22} />
                    </div>
                  </Popup>
                )}*/}

              {(showNames || (showNamesAdminCenterOnly && !isSmallCity)) && (
                <Tooltip
                  position={[city.lat, city.lng] as LatLngTuple}
                  opacity={tooltipOpacity}
                  permanent
                  direction={direction !== undefined ? direction : 'top'}
                  className={`${tooltipClass} ${className}-tooltip ${isSmallCity ? 'city-tooltip-small' : 'city-tooltip-big'} ${
                    selectedCity === cityName ? 'selected' : ''
                  }`}
                  pane={'tooltips'}
                  offset={
                    direction === 'left'
                      ? [-5, 0]
                      : direction === 'bottom'
                      ? [0, 5]
                      : direction === 'right'
                      ? [5, 0]
                      : direction === 'center'
                      ? [0, 0]
                      : [0, -5] // top by default
                  }
                  interactive={interactive}
                >
                  {cityName}
                </Tooltip>
              )}
            </Circle>
          );
        }
      })}
    </>
  );
};

export default CityDots;
