import { ColorScheme } from '../../types/project';

export const LightTheme: ColorScheme = Object.freeze({
  name: 'light',

  region: 'rgba(64, 120, 192, 0.2)',
  regionBorder: 'rgba(64, 120, 192, 0.5)',
  regionHighlight: 'rgba(64, 120, 192, 0.8)',
  regionHighlightBorder: 'rgba(100, 100, 100, 0.5)',
  regionCorrect: 'rgba(51,153,51, 0.8)',
  regionCorrectBorder: '#393',
  regionIncorrect: 'rgba(153,51,51, 0.8)',
  regionIncorrectBorder: '#933',
  cityIncorrect: '#FF4D4F',
  cityIncorrectBorder: '#CF1322',

  cityColor: '#FFF700',
});
