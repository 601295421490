import axios from 'common/axios';

const processRequest = async <T = any>(axiosRequest: any): Promise<T> => {
  try {
    const response = await axiosRequest;

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getQueryString = (params: any): string => {
  if (!params || !Object.keys(params).length) return '';

  const esc = encodeURIComponent;

  let query = '?';

  query += Object.keys(params)
    .map(k => `${esc(k)}=${esc(params[k])}`)
    .join('&');

  return query;
};

export const BASE_URL = process.env.PUBLIC_URL;

export const getUrl = (url: string): string => (url.startsWith('http') ? url : BASE_URL + url);
export const isDev = (): boolean => !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
export const getFileLoadUrl = (url: string): string => (isDev() ? `http://localhost:9005${getUrl(url)}` : getUrl(url));

const getDefaultRequestOptions = (modifier: any = {}) => {
  const headerModifier = modifier.headers ? { ...modifier.headers } : {};

  return {
    ...modifier,
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/json',
      ...headerModifier,
    },
  };
};

const httpGet = (url: string, queryParams = {}, headerModifier = {}, fullResponse = false): Promise<any> => {
  const headers = getDefaultRequestOptions(headerModifier);
  const axiosData = axios.get(`${url}${getQueryString(queryParams)}`, headers);

  return fullResponse ? axiosData : processRequest(axiosData);
};

const httpPost = <T = any>(url: string, data: any, headerModifier = {}): Promise<T> => {
  const headers = getDefaultRequestOptions(headerModifier);
  const reqData = headers.headers['Content-Type'] === 'multipart/form-data' ? data : JSON.stringify(data);
  const request = axios.post(url, reqData, headers);

  return processRequest(request);
};

const httpPut = <T = any>(url: string, data: any, headerModifier = {}): Promise<T> => {
  const headers = getDefaultRequestOptions(headerModifier);
  const reqData = headers.headers['Content-Type'] === 'multipart/form-data' ? data : JSON.stringify(data);
  const request = axios.put(url, reqData, headers);

  return processRequest(request);
};

const httpPatch = <T = any>(url: string, data: any): Promise<T> => {
  const request = axios.patch(url, JSON.stringify(data), getDefaultRequestOptions());

  return processRequest(request);
};

const httpDelete = <T = any>(url: string): Promise<T> => {
  const defaultOptions = getDefaultRequestOptions();
  const options = { ...defaultOptions };
  const request = axios.delete(url, options);

  return processRequest(request);
};

export default {
  get: httpGet,
  post: httpPost,
  patch: httpPatch,
  put: httpPut,
  delete: httpDelete,
};
