import axios, { AxiosRequestTransformer } from 'axios';
import dayjs from 'dayjs';

// const qs = require('qs');

const dateTransformer = (data: any): any => {
  try {
    const dataLocal = JSON.parse(data);
    try {
      dataLocal.created = dayjs(dataLocal.created, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY/MM/DD HH:mm:ss ZZ');
    } catch (e) {
      //ignore
    }
    return JSON.stringify(dataLocal);
  } catch (e) {
    //ignore
  }
  return data;
};

const axiosInstance = axios.create({
  baseURL: `${process.env.PUBLIC_URL}`,
  transformRequest: [dateTransformer, ...(axios.defaults.transformRequest as AxiosRequestTransformer[])],
});

// axiosInstance.interceptors.request.use(config => {
//   console.log(config);
//   config.paramsSerializer = params => {
//     console.log(params);
//     return qs.stringify(params, {
//       serializeDate: (date: Date) => {
//         console.log(date);
//         return dayjs(date).format('YYYY/MM/DD HH:mm:ss ZZ');
//       },
//     });
//   };
//   return config;
// });

export default axiosInstance;
