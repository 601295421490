import { FC, useContext, useEffect, useRef, useState } from 'react';
import AppContext from 'common/AppContext';
import Header from 'components/common/Header';
import Error from 'components/common/Error';
import { useSelector } from 'react-redux';
import { UserSelector } from '../../state/users/selectors';
import { isPortrait, isSafari, isSafariIPhone, isSafariMacintosh, isTouch, isPhone } from '../../common/utils/commonUtils';
import { Themes } from '../../common/themes/Themes';
import { locales } from '../../common/localization';

const AuthLayout: FC = ({ children }): JSX.Element => {
  const { theme, locale } = useContext(AppContext);
  const appContainer = useRef<HTMLDivElement | null>(null);
  const currentUser = useSelector(UserSelector);
  const [, updateState] = useState<any>({});

  useEffect(() => {
    const orientationChangeListener = () => {
      updateState({});
    };
    window.addEventListener('orientationchange', orientationChangeListener);

    // clean up function
    return () => {
      window.removeEventListener('orientationchange', orientationChangeListener);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('gesturestart', e => {
      e.preventDefault();
    });
  }, []);

  useEffect(() => {
    for (const themeName of Object.keys(Themes)) {
      document.body.classList.remove(`theme-${themeName}`);
    }
    for (const localeName of Object.keys(locales)) {
      document.body.classList.remove(`locale-${localeName}`);
    }
    document.body.classList.add(`theme-${theme}`);
    document.body.classList.add(`locale-${locale}`);
  }, [theme, locale]);

  return (
    <div
      className={`theme-${theme} locale-${locale} absolute inset-0 overflow-hidden 
            ${currentUser && currentUser.admin ? 'admin-view' : 'user-view'} 
            ${isSafari ? 'safari' : ''}
            ${isSafariMacintosh ? 'macintosh' : ''}
            ${isSafariIPhone ? 'iphone' : ''}
            ${isTouch ? 'touch' : ''}
            ${isPhone ? 'phone' : ''}
            ${isPortrait() ? 'portrait' : 'landscape'}
            `}
      ref={appContainer}
    >
      {!isPhone && (
        <div className="absolute inset-0 overflow-hidden flex flex-col bg-ivory transition duration-300">
          <Header />

          {children}

          <Error />
        </div>
      )}
      {isPhone && (
        <>
          <div className={'mobile-stub'}>
            <div>
              {window.screen.width}x{window.screen.height}
            </div>
            Мобільна версія в розробці Mobile version is in progress
          </div>
        </>
      )}
    </div>
  );
};

export default AuthLayout;
