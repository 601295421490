import httpHelper from 'common/utils/httpUtils';
import { CreateUserRequest, UpdateUserRequest, UserAction, UserDto } from 'types/models';
import dayjs from 'dayjs';
// import realDataRegions from '../../realData/real-data-regions.json';
// import realDataCompanies from '../../realData/real-data-companies.json';
// import realDataSkills from '../../realData/real-data-skills.json';
// import realDataVacancies from '../../realData/real-data-vacancies.json';

const getUsers = (): Promise<UserDto[]> => httpHelper.get('/api/users');

const createUser = (createRequest: CreateUserRequest): Promise<UserDto> => httpHelper.post('/api/users', createRequest);

const updateUser = (userId: number, user: UpdateUserRequest): Promise<UserDto> => httpHelper.put(`/api/users/${userId}`, user);

// const saveUserAction = (payload: UserAction): Promise<void> => httpHelper.post(`/api/data/quality-increase-actions`, payload);

const saveUserAction = (payload: UserAction): void => {
  // Test that we have support
  // @ts-ignore
  if (navigator.sendBeacon) {
    // URL to send the data to, e.g.
    const url = process.env.PUBLIC_URL + '/api/data/quality-increase-actions';

    const replacer = function (key: string, value: any) {
      // @ts-ignore
      if (this[key] instanceof Date) {
        // @ts-ignore
        return dayjs(this[key]).format('YYYY/MM/DD HH:mm:ss ZZ');
      }

      return value;
    };

    const blobData = new window.Blob([JSON.stringify(payload, replacer)], {
      type: 'application/json',
    });

    navigator.sendBeacon(url, blobData);
  }
};

export default {
  getUsers,
  createUser,
  updateUser,
  saveUserAction,
};
