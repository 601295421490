import React, { FC, useState, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { Collapse, Divider, Drawer, Modal } from 'antd';
import { Icon } from '@iconify/react';
import closeOutline from '@iconify-icons/eva/close-outline';
import menuOutline from '@iconify-icons/eva/menu-outline';
import history from 'common/utils/historyUtils';

import './LeftMenu.scss';
import { getIntlHtmlMessages } from '../../../common/localization';
import AppContext from '../../../common/AppContext';
import { capitalize } from 'lodash';

const { Panel } = Collapse;

const LeftMenu: FC = (): JSX.Element => {
  const intl = useIntl();
  const { pathname, search } = useLocation();
  const [visible, setVisible] = useState(false);
  const [showAboutUsPopup, setShowAboutUsPopup] = useState<boolean>(false);
  const animationInProgress = useRef(false);
  const { locale } = useContext(AppContext);
  const textLocalized = getIntlHtmlMessages(locale);

  const onClose = (): void => {
    setVisible(false);
  };

  const onToggle = (): void => {
    setVisible(!visible);
    animationInProgress.current = true;
  };

  const onClick = (path: string): void => {
    history.push(path);
    onClose();
  };

  /*  useEffect(() => {
    // code to run after render goes here
    d3.select('.ant-drawer-mask').on('mouseenter', function () {
      /!*!// @ts-ignore
      const related = d3.event.relatedTarget;
      // console.log(related);
      if (related === null || related === undefined || related === window || !related.classList.contains('menu-button')) {
        setVisible(false);
      }*!/
      setVisible(false);
    });
  });*/

  return (
    <>
      <div
        /*onMouseEnter={() => {
          setVisible(true);
          animationInProgress.current = true;
        }}
        onMouseDown={() => {
          if (!animationInProgress.current) {
            onToggle();
          }
        }}
        onMouseLeave={(e: any) => {
          // console.log(e);
          const related = e.relatedTarget;
          // @ts-ignore
          if (related === null || related === undefined || related === window) {
            setVisible(false);
          }
        }}*/
        onClick={() => {
          onToggle();
        }}
        className="menu-button cursor-pointer select-none"
      >
        <Icon icon={menuOutline} width={32} height={32} />
      </div>
      <Drawer
        className="left-menu"
        title={<span className="text-xl text-black font-bold">{intl.formatMessage({ id: 'menuHeader' })}</span>}
        placement="right"
        onClose={onClose}
        visible={visible}
        afterVisibleChange={() => {
          animationInProgress.current = false;
        }}
        closeIcon={<Icon icon={closeOutline} width={22} height={22} />}
        headerStyle={{ display: 'none' }}
        bodyStyle={{
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
        width={350}
      >
        {/*{navModulesList.map(({ navId, label, path }) => {
          const isActiveMenuItem = curPath.startsWith(path) || (curPath === '/' && navId === 0);
          if (currentUser && !currentUser.admin && path === '/administration') return null;
          return (
            <div key={navId} className="cursor-pointer">
              <div className={clsx('text-base menu-item', isActiveMenuItem && 'active')} onMouseDown={() => onClick(path)}>
                {label}
              </div>
            </div>
          );
        })}*/}
        <div className={clsx('text-base menu-item', pathname === '/geo/landing' && 'active')} onMouseDown={() => onClick('/geo/landing')}>
          {intl.formatMessage({ id: 'menuMain' })}
        </div>
        <Divider />
        <Collapse defaultActiveKey={['1']}>
          <Panel header={intl.formatMessage({ id: 'testChooseHeader' }) + ':'} key={1}>
            {/*<div className={clsx('text-base menu-item disabled-header')}>{intl.formatMessage({ id: 'testChooseHeader' })}:</div>*/}
            <div
              className={clsx('text-base menu-item sub-menu-item', pathname + search === '/geo/test?type=regionbyname' && 'active')}
              onMouseDown={() => onClick('/geo/test?type=regionbyname')}
            >
              {capitalize(intl.formatMessage({ id: 'testChooseRegionByName' }))}
            </div>
            <div
              className={clsx('text-base menu-item sub-menu-item', pathname + search === '/geo/test?type=regionbylicenseplate' && 'active')}
              onMouseDown={() => onClick('/geo/test?type=regionbylicenseplate')}
            >
              {capitalize(intl.formatMessage({ id: 'testChooseRegionByLicensePlate' }))}
            </div>
            <div
              className={clsx(
                'text-base menu-item sub-menu-item disabled',
                pathname + search === '/geo/test?type=regionbyshape' && 'active',
              )}
              onMouseDown={() => onClick('/geo/test?type=regionbyshape')}
            >
              {capitalize(intl.formatMessage({ id: 'testChooseRegionByShape' }))}
              <span className={'soon'}>{intl.formatMessage({ id: 'testChooseSoon' })}</span>
            </div>
            <div
              className={clsx(
                'text-base menu-item sub-menu-item disabled',
                pathname + search === '/geo/test?type=regionbyneighbours' && 'active',
              )}
              onMouseDown={() => onClick('/geo/test?type=regionbyneighbours')}
            >
              {capitalize(intl.formatMessage({ id: 'testChooseRegionByNeighbours' }))}
              <span className={'soon'}>{intl.formatMessage({ id: 'testChooseSoon' })}</span>
            </div>
            <div
              className={clsx('text-base menu-item sub-menu-item', pathname + search === '/geo/test?type=citybyname' && 'active')}
              onMouseDown={() => onClick('/geo/test?type=citybyname')}
            >
              {capitalize(intl.formatMessage({ id: 'testChooseCityByName' }))}
            </div>
            <div
              className={clsx(
                'text-base menu-item sub-menu-item disabled',
                pathname + search === '/geo/test?type=citybyregion' && 'active',
              )}
              onMouseDown={() => onClick('/geo/test?type=citybyregion')}
            >
              {capitalize(intl.formatMessage({ id: 'testChooseCityByRegion' }))}
              <span className={'soon'}>{intl.formatMessage({ id: 'testChooseSoon' })}</span>
            </div>
            <div
              className={clsx('text-base menu-item sub-menu-item disabled', pathname + search === '/geo/test?type=citybyplace' && 'active')}
              onMouseDown={() => onClick('/geo/test?type=citybyplace')}
            >
              {capitalize(intl.formatMessage({ id: 'testChooseCityByPlace' }))}
              <span className={'soon'}>{intl.formatMessage({ id: 'testChooseSoon' })}</span>
            </div>
          </Panel>
        </Collapse>
        <Divider />
        <div
          className={clsx('text-base menu-item', pathname + search === '/geo/explore' && 'active')}
          onMouseDown={() => onClick('/geo/explore')}
        >
          {intl.formatMessage({ id: 'exploreButton' })}
        </div>
        <Divider />
        <div className={clsx('text-base menu-item', pathname === '/geo/symbols' && 'active')} onMouseDown={() => onClick('/geo/symbols')}>
          {intl.formatMessage({ id: 'menuNationalSymbols' })}
        </div>
        <Divider />
        <div
          className={clsx('text-base menu-item')}
          onMouseDown={() => {
            setShowAboutUsPopup(true);
            onToggle();
          }}
        >
          {intl.formatMessage({ id: 'aboutus' })}
        </div>
        <Divider />
      </Drawer>
      <Modal
        title={
          <div>
            <img
              height={40}
              style={{ marginRight: 13, marginLeft: 1 }}
              src={`${process.env.PUBLIC_URL}/images/UA-coat-of-arms.svg`}
              alt="UA-coat-of-arms"
            />

            {intl.formatMessage({ id: 'aboutus' })}
          </div>
        }
        centered
        width={'700px'}
        closeIcon={<Icon icon={closeOutline} width={22} height={22} />}
        visible={showAboutUsPopup}
        footer={null}
        onOk={() => setShowAboutUsPopup(false)}
        onCancel={() => setShowAboutUsPopup(false)}
      >
        <div className="marketing-text">{textLocalized.getMarketingText()}</div>
      </Modal>
    </>
  );
};

export default LeftMenu;
