import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Form, Input, Layout } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { loginUser } from 'state/auth/actions';

import { AuthErrorSelector, AuthLoadingSelector } from 'state/auth/selectors';
import { AuthenticationRequest } from 'types/models';

import './Login.scss';

const Login: FC = (): JSX.Element => {
  const dispatch = useDispatch<any>();
  const loading = useSelector(AuthLoadingSelector) === 'post';
  const error = useSelector(AuthErrorSelector);
  const [form] = Form.useForm();
  const { Content } = Layout;

  const onFinish = (credentials: AuthenticationRequest) => {
    dispatch(loginUser(credentials));
  };

  return (
    <Layout className="login-form h-screen bg-login">
      <Content className="flex flex-col items-center">
        <h1 className="text-6xl font-bold text-black mt-108 mb-84">Log in</h1>
        <Form name="normal_login" className="w-360 relative" onFinish={onFinish} form={form}>
          {error && <Alert message="Email or password is incorrect" type="error" showIcon className="login-error-message" />}

          <Form.Item name="username">
            <Input prefix={<UserOutlined className="site-form-item-icon text-dodger-blue" />} placeholder="email" size="large" />
          </Form.Item>

          <Form.Item name="password">
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon text-dodger-blue" />}
              placeholder="password"
              size="large"
            />
          </Form.Item>

          <Form.Item noStyle>
            <Button
              style={{ backgroundColor: '#1890ff', borderColor: '#1890ff', padding: '6.4px 15px' }}
              type="primary"
              block
              size="large"
              htmlType="submit"
              loading={loading}
            >
              Sign In
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
};

export default Login;
