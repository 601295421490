import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Tabs } from 'antd';
import Users from 'components/Administration/Users/Users';
import CreateNewAction from 'components/Administration/CreateNewAction';
import CreateUserFrom from 'components/Administration/CreateUserFrom';

import { getUsers } from 'state/users/actions';
import { UserSelector } from 'state/users/selectors';

interface Params {
  organisationIdParam: string;
  tabId: string;
}

const Administration: FC = (): JSX.Element | null => {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const history = useHistory<History>();
  const { tabId } = useParams<Params>();
  const [currentTabKey, setCurrentTabKey] = useState<string>(tabId || 'users');
  const currentUser = useSelector(UserSelector);

  const FORM_FOR_TAB: Record<string, JSX.Element> = {
    users: <CreateUserFrom />,
  };

  const getCurrentTabFormComponent: JSX.Element = FORM_FOR_TAB[currentTabKey];

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const handleChangeTab = (key: string): void => {
    if (tabId !== key) {
      history.push(`/administration/${key}`);
      setCurrentTabKey(key);

      switch (key) {
        case 'users': {
          dispatch(getUsers());
          break;
        }
        default:
          break;
      }
    }
  };

  const extraContent = <CreateNewAction currentTabKey={currentTabKey}>{getCurrentTabFormComponent}</CreateNewAction>;

  return currentUser.admin ? (
    <div>
      <div className="mx-24 my-20">
        <Tabs tabBarExtraContent={extraContent} onChange={(key: string): void => handleChangeTab(key)} defaultActiveKey={currentTabKey}>
          <TabPane tab="Users" key="users">
            <Users />
          </TabPane>
        </Tabs>
      </div>
      {/* <AdministrationFooter /> */}
    </div>
  ) : null;
};

export default Administration;
