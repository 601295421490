import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { getIconClassName } from '@uifabric/styling';
import { Button } from 'antd';
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share';

interface TestResultProps {
  test: string;
  correct: number;
  incorrect: number;
  time: string;
  happuCatIndex: number;
  unhappuCatIndex: number;
  onGoToMain: () => void;
  onTryAgain: () => void;
}

export const minHappuCatPercent = 85;

const TestResult: FC<TestResultProps> = ({
  test,
  correct,
  incorrect,
  time,
  onGoToMain,
  onTryAgain,
  happuCatIndex,
  unhappuCatIndex,
}): JSX.Element => {
  const intl = useIntl();

  const correctPercent = Math.round((correct / (correct + incorrect)) * 100);
  return (
    <div className={'flex flex-col'}>
      <div className={'flex flex-row pt-8'}>
        <div className={'flex flex-col w-full'}>
          <div className={'pb-24'}>
            {intl.formatMessage({ id: 'testResultAdditionalText1' })} <b>{test}</b>{' '}
            {intl.formatMessage({ id: 'testResultAdditionalText2' })}
          </div>

          <div className={'flex flex-row'}>
            <div>
              <div className={'flex flex-row'}>
                <div className={'flex flex-col'} style={{ width: 56 }}>
                  <div className={'column-header'}>{intl.formatMessage({ id: 'testResultPercent' })}</div>
                  <div className={'test-result-value pt-2'}>{correctPercent}%</div>
                </div>
                <div className={'flex flex-col pl-24'}>
                  <div className={'column-header'}>{intl.formatMessage({ id: 'testResultCorrect' })}</div>
                  <div className={'test-result-value pt-2 correct flex flex-row items-center'}>
                    <i className={getIconClassName('CheckMark') + ' pr-4'} />
                    {correct}
                  </div>
                </div>
                <div className={'flex flex-col pl-24'}>
                  <div className={'column-header'}>{intl.formatMessage({ id: 'testResultIncorrect' })}</div>
                  <div className={'test-result-value pt-2 incorrect flex flex-row items-center'}>
                    <i className={getIconClassName('Cancel') + ' pr-4'} />
                    {incorrect}
                  </div>
                </div>
              </div>
              <div className={'flex flex-col pt-24'}>
                <div className={'column-header'}>{intl.formatMessage({ id: 'testResultTime' })}</div>
                <div className={'pt-2 flex flex-row items-center'}>
                  <i className={getIconClassName('Timer') + ' pr-8'} />
                  {time}
                </div>
              </div>

              <div className={'relative'}>
                <div className={'absolute mt-20'}>
                  <FacebookShareButton
                    url={'https://ukraine-explore.fun/'}
                    quote={`I've got ${correctPercent}% on "Explore Ukraine"!`}
                    hashtag="#ukraineexplore"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton
                    className={'ml-6'}
                    url={'https://ukraine-explore.fun/'}
                    title={`I've got ${correctPercent}% on "Explore Ukraine"!`}
                    hashtags={['ukraineexplore']}
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                </div>
              </div>
            </div>
            <div className={'pl-16 test-result-image'}>
              {correctPercent < minHappuCatPercent && (
                <img src={`${process.env.PUBLIC_URL}/images/unhappucats/${unhappuCatIndex}.jpg`} alt="unhappy cat" />
              )}
              {correctPercent >= minHappuCatPercent && (
                <img src={`${process.env.PUBLIC_URL}/images/happucats/${happuCatIndex}.jpg`} alt="happy cat" />
              )}
            </div>
          </div>

          <div className={'pt-48 w-full flex justify-center'}>
            <Button
              block={false}
              onMouseDown={() => {
                onGoToMain();
              }}
            >
              {intl.formatMessage({ id: 'backToLanding' })}
            </Button>
            <Button
              className={'ml-16'}
              block={false}
              type="primary"
              onMouseDown={() => {
                onTryAgain();
              }}
            >
              {intl.formatMessage({ id: 'testResultTryAgain' })}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestResult;
