import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import AppContext from '../../../common/AppContext';
import { capitalize } from 'lodash';

interface TestChooseProps {
  onClose: () => void;
}

const TestChoosePopup: FC<TestChooseProps> = ({ onClose }): JSX.Element => {
  const intl = useIntl();
  const history = useHistory();
  const { testResults } = useContext(AppContext);

  const getTestResult = (value: number) => {
    if (value === undefined || value === null) {
      value = 0;
    }
    const color = value === 0 ? '#8C8C8C' : value <= 20 ? '#D4380D' : value < 100 ? '#096DD9' : '#5B8C00';
    return (
      <span className={'test-result'} style={{ color: color }}>
        {'(' + String(value) + '%)'}
      </span>
    );
  };

  return (
    <div className={'flex flex-col'}>
      <div>{intl.formatMessage({ id: 'testChooseTest' })}</div>
      <div className={'flex flex-row pt-24'}>
        <div className={'flex flex-col pr-48'}>
          <div className={'font-bold pb-8'}>{intl.formatMessage({ id: 'testChooseGuess' })}</div>
          <Button
            className={'my-8'}
            block={true}
            onMouseDown={() => {
              onClose();
              history.push('/geo/test?type=regionbyname');
            }}
          >
            {capitalize(intl.formatMessage({ id: 'testChooseRegionByName' }))} {getTestResult(testResults.regionbyname)}
          </Button>
          <Button
            className={'my-8'}
            block={true}
            onMouseDown={() => {
              onClose();
              history.push('/geo/test?type=regionbylicenseplate');
            }}
          >
            {capitalize(intl.formatMessage({ id: 'testChooseRegionByLicensePlate' }))} {getTestResult(testResults.regionbylicenseplate)}
          </Button>
          <Button className={'my-8'} block={true} disabled>
            {capitalize(intl.formatMessage({ id: 'testChooseRegionByShape' }))} {getTestResult(0)}
          </Button>
          <Button className={'my-8'} block={true} disabled>
            {capitalize(intl.formatMessage({ id: 'testChooseRegionByNeighbours' }))} {getTestResult(0)}
          </Button>
        </div>
        <div className={'flex flex-col'}>
          <div className={'font-bold pb-8'}>{'\u00A0'}</div>
          <Button
            className={'my-8'}
            block={true}
            onMouseDown={() => {
              onClose();
              history.push('/geo/test?type=citybyname');
            }}
          >
            {capitalize(intl.formatMessage({ id: 'testChooseCityByName' }))} {getTestResult(testResults.citybyname)}
          </Button>
          <Button className={'my-8'} block={true} disabled>
            {capitalize(intl.formatMessage({ id: 'testChooseCityByRegion' }))} {getTestResult(0)}
          </Button>
          <Button className={'my-8'} block={true} disabled>
            {capitalize(intl.formatMessage({ id: 'testChooseCityByPlace' }))} {getTestResult(0)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TestChoosePopup;
