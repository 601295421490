/* tslint:disable */
/* eslint-disable */
import {Component, createRef} from 'react';
import Truncate from 'react-truncate';
import PropTypes from 'prop-types';

import './TruncatedName.scss';
import {Popover} from "antd";

class TruncatedName extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        width: PropTypes.number,
        className: PropTypes.string,
        outerClassName: PropTypes.string,
        lines: PropTypes.number,
        showTooltip: PropTypes.bool
    };

    state = {
        isTruncated: undefined
    };

    ref = createRef();
    interval;


    componentDidMount() {
        this.interval = setInterval(() => {
            this.onResize();
        }, 500)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    onResize() {
        // this.setState({isTruncated: false}, () => {
        this.ref.current.onResize();
        // });
    }

    handleTruncate = isTruncated => {
        const {showTooltip = true} = this.props;

        if (isTruncated !== this.state.isTruncated && showTooltip === true) {
            this.setState({isTruncated});
        }
    }

    renderTruncatedTitle = () => {
        const {title, lines, width, style, outerClassName = '', className = ''} = this.props;
        return (
            <div className={`w-full h-full flex items-center ${outerClassName}`}>
                <Truncate
                    ref={this.ref}
                    trimWhitespace
                    lines={lines}
                    width={width}
                    style={style}
                    className={`truncatedText ${className}`}
                    onTruncate={this.handleTruncate}
                >
                    {title}
                </Truncate>
            </div>
        );
    };

    render() {
        const {isTruncated} = this.state;
        const {title, className = '', showTooltip = true} = this.props;


        if (showTooltip === false) return (
            <div className={`w-full h-full truncated-name ${className}`}>
                {this.renderTruncatedTitle()}
            </div>
        )
        if (!isTruncated) return this.renderTruncatedTitle();

        return (
            <Popover
                placement="top"
                content={title}
                trigger="hover"
                overlayClassName="truncated-name-popup"
                className='truncated-name'
            >
                <div className={`w-full h-full ${className}`}>
                    {this.renderTruncatedTitle()}
                </div>
            </Popover>
        );
    }
}

export default TruncatedName;
