import React, { FC, useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation, withRouter } from 'react-router-dom';
import { History } from 'history';
import { useDispatch, useSelector } from 'react-redux';
import { DataLoadingSelector } from '../../state/data/selectors';
import { Tooltip } from '../../common/Tooltip/Tooltip';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Landing from './Landing';
import NationalSymbolsOfUkraine from './NationalSymbolsOfUkraine/NationalSymbolsOfUkraine';
import Explore from './Explore/Explore';
import Test from './Test/Test';
import { notification, Spin } from 'antd';
import './GEO.scss';
import { saveUserAction } from '../../state/data/actions';
import AppContext from '../../common/AppContext';
import dayjs from 'dayjs';

interface DashboardProps {
  history: History;
}

const GEO: FC<DashboardProps> = ({ history }): JSX.Element => {
  let prevLocationPath = '';
  const dispatch = useDispatch();
  const location = useLocation();
  const loading = useSelector(DataLoadingSelector);
  const { theme, locale } = useContext(AppContext);
  let startTime = dayjs();

  useEffect(() => {
    // User enters
    dispatch(saveUserAction('navigation', '', location.pathname, '', theme, locale));
    startTime = dayjs();

    prevLocationPath = location.pathname;

    history.listen(({ pathname }) => {
      notification.destroy();
      // User navigates
      if (pathname !== prevLocationPath) {
        dispatch(saveUserAction('navigation', prevLocationPath, pathname, String(dayjs().diff(startTime)), theme, locale));
        startTime = dayjs();

        prevLocationPath = pathname;
      }
    });

    const onBeforeUnload = () => {
      // User leaves
      dispatch(saveUserAction('navigation', location.pathname, '', String(dayjs().diff(startTime)), theme, locale));
    };
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // initial data loading
    // dispatch(getCompanies(top20, period));

    Tooltip.initTooltipPopup();

    const outsideTapListener = () => {
      Tooltip.immediateCloseTooltip();
      const event = new CustomEvent('outsidetap');
      window.dispatchEvent(event);
    };
    // set resize listener
    window.addEventListener('touchstart', outsideTapListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('touchstart', outsideTapListener);
    };
  }, [dispatch]);

  return (
    <div className="flex-1 flex flex-row overflow-hidden">
      <>
        <div className="dashboard flex flex-col w-full flex-1">
          <div className="dashboard-body flex flex-col w-full flex-1">
            {loading === 'idle' && (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  timeout={{ appear: 400, enter: 400, exit: 400 }}
                  addEndListener={(node, done) => {
                    node.addEventListener('transitionend', done, false);
                  }}
                  classNames="fade-slide-up"
                >
                  {/*<div className="w-full flex-1 flex flex-col chart-wrapper">*/}
                  <Switch location={location}>
                    <Route path="/geo/landing" children={<Landing />} />
                    <Route path="/geo/symbols" children={<NationalSymbolsOfUkraine />} />
                    <Route path="/geo/explore" children={<Explore />} />
                    <Route path="/geo/test" children={<Test />} />
                    <Route exact path="*">
                      <Redirect to="/geo/landing" />
                    </Route>
                  </Switch>
                  {/*</div>*/}
                </CSSTransition>
              </SwitchTransition>
            )}
            {loading !== 'idle' && <Spin className="flex-1 flex items-center justify-center" />}
          </div>
        </div>
      </>
    </div>
  );
};

export default withRouter(GEO);
