import React, { FC, useMemo, useState } from 'react';
import { GeoJSON, Marker, Tooltip } from 'react-leaflet';
import * as topojson from 'topojson-client';
import uajson from '../../common/geojson/UA/ukraine-geo.json';
import L, { DivIcon, LatLng } from 'leaflet';
import uacitiesjson from '../../common/geojson/UA-cities/ua-cities.json';
import { useIntl } from 'react-intl';

const DevCityTest: FC = ({}): JSX.Element => {
  const cityToGuess: string | undefined = 'Kyiv';
  const [selectedPoint, setSelectedPoint] = useState<LatLng | undefined>(undefined);
  const intl = useIntl();

  // @ts-ignore
  const geoDataRegions = topojson.feature(uajson, uajson.objects.regions).features;

  const getIconStyle2 = (color: string): DivIcon => {
    // const iconStyle2 = L.icon({
    //   iconUrl: markerSvg,
    //   iconSize: [32, 32],
    //   iconAnchor: [16, 32],
    // });
    const myCustomColour = color ? color : '#583470';

    const markerHtmlStyles = `
      fill: ${myCustomColour};
      width: 32px;
      max-width: 32px;
      height: 32px;
    `;
    const iconStyle2 = L.divIcon({
      className: 'my-custom-pin-2',
      iconAnchor: [15, 44],
      // labelAnchor: [-6, 0],
      popupAnchor: [0, -36],
      // eslint-disable-next-line max-len
      html: `<div style="${markerHtmlStyles}"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"/></svg></div>`,
    });

    return iconStyle2;
  };

  const getIconStyle1 = (color: string): DivIcon => {
    const myCustomColour = color ? color : '#583470';

    const markerHtmlStyles = `
      background-color: ${myCustomColour};
      width: 32px;
      height: 32px;
      display: block;
      left: -16px;
      top: -16px;
      position: relative;
      border-radius: 16px 16px 0;
      transform: rotate(45deg);
      border: 1px solid #FFFFFF;`;

    const iconStyle1 = L.divIcon({
      className: 'my-custom-pin',
      iconAnchor: [0, 23],
      // labelAnchor: [-6, 0],
      // popupAnchor: [30, -36],
      html: `<span style="${markerHtmlStyles}" />`,
    });
    return iconStyle1;
  };

  const cityToGuessJson = uacitiesjson.filter(city => city.city === cityToGuess)[0];
  const cityToGuessLatLng = L.latLng(Number(cityToGuessJson.lat), Number(cityToGuessJson.lng));

  let selectedPointText = '';
  let distance = 0;
  if (selectedPoint) {
    distance = Math.round(selectedPoint.distanceTo(cityToGuessLatLng) / 1000);
    selectedPointText = '- ' + String(distance) + ' ' + intl.formatMessage({ id: 'km' });
  }

  const geoJsonHandlers = useMemo(
    () => ({
      mousedown(e: any) {
        setSelectedPoint(e.latlng);
      },
    }),
    [],
  );

  return (
    <>
      <GeoJSON
        pane={'city'}
        data={geoDataRegions as GeoJSON.GeoJsonObject}
        eventHandlers={geoJsonHandlers}
        style={() => {
          return {
            fillColor: 'rgba(0,0,0,0)',
            color: 'rgba(0,0,0,0)',
          };
        }}
      >
        {selectedPoint && (
          <Marker pane={'markers'} position={cityToGuessLatLng} icon={getIconStyle1('rgba(51,153,51, 0.8)')}>
            <Tooltip pane={'tooltips'} direction="right" offset={[20, -23]} opacity={1} permanent>
              {cityToGuessJson.city}
            </Tooltip>
          </Marker>
        )}

        {selectedPoint && (
          <Marker pane={'markers'} position={selectedPoint} icon={getIconStyle2('rgba(0,0,0, 0.8)')}>
            <Tooltip pane={'tooltips'} direction="right" offset={[20, -23]} opacity={1} permanent>
              {selectedPointText}
            </Tooltip>
          </Marker>
        )}
      </GeoJSON>
    </>
  );
};

export default DevCityTest;
