import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Spin } from 'antd';
import { logOutUser } from 'state/auth/actions';
import { getCurrentUser } from 'state/users/actions';
import AuthService from 'common/services/authService';
import { RootState } from 'state';
import { UserDto } from 'types/models';

type MapDispatchToProps = {
  getCurrentUser?: (user: UserDto) => void;
  logOutUser?: () => void;
};

type Props = CommonObj;

type State = {
  waitAuthCheck: boolean;
};

type PropsTypes = Props & MapDispatchToProps;

class Auth extends Component<PropsTypes, State> {
  constructor(props: PropsTypes) {
    super(props);

    this.state = {
      waitAuthCheck: true,
    };
  }

  componentDidMount(): Promise<void> {
    return Promise.all([this.jwtCheck()]).then((): void => {
      this.setState({ waitAuthCheck: false });
    });
  }

  jwtCheck = (): Promise<void> =>
    new Promise(resolve => {
      AuthService.on('onAutoLogin', () => {
        AuthService.getCurrentUser()
          .then(user => {
            if (this.props.getCurrentUser) this.props.getCurrentUser(user);

            resolve();
          })
          .catch(() => {
            resolve();
          });
      });

      AuthService.on('onAutoLogout', () => {
        if (this.props.logOutUser) this.props.logOutUser();

        resolve();
      });

      AuthService.on('onNoAccessToken', () => {
        resolve();
      });

      AuthService.init();

      return Promise.resolve();
    });

  render() {
    return this.state.waitAuthCheck ? (
      <Spin className="h-screen bg-ivory flex items-center justify-center" style={{ background: 'rgba(9, 9, 9, 1)' }} />
    ) : (
      <>{this.props.children}</>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getCurrentUser,
      logOutUser,
    },
    dispatch,
  );
}

export default connect<any, MapDispatchToProps, Props, RootState>(null, mapDispatchToProps)(Auth);
