import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import regionsInfoUA from '../datajson/RegionsInfoUA.json';
import regionsInfoUS from '../datajson/RegionsInfoUS.json';
import { isPhone } from '../../../../common/utils/commonUtils';
import * as d3 from 'd3';
import { GeoJSON, MapContainer, Pane } from 'react-leaflet';
import uajson from '../geojson/UA/ukraine-geo.json';
import { LatLngTuple } from 'leaflet';
import { zoomToLayer } from '../../../../common/utils/leafletUtils';
import * as topojson from 'topojson-client';
import { getThemeColors } from '../../../../common/themes/Themes';
import AppContext from '../../../../common/AppContext';
import CityDots from './CityDots';
import './RegionInfoPopup.scss';
import RegionInfoPopupDetailsPanel from './RegionInfoPopupDetailsPanel';

interface RegionDescriptionProps {
  selectedRegion: any;
  selectedCity: string | undefined;
  onMount: (args: any) => void;
}

let setSelectedCity: any;

const RegionInfoPopup: FC<RegionDescriptionProps> = ({ selectedRegion, selectedCity, onMount }): JSX.Element => {
  const [map, setMap] = useState<any | undefined>(undefined);
  const geoJSONRef = useRef<any | undefined>(undefined);
  const zoomCenter = useRef<LatLngTuple | undefined>([48.8, 32]);
  const zoomLevel = useRef<number | undefined>(5);
  const { theme, locale } = useContext(AppContext);
  const themeColors = useMemo(() => getThemeColors(theme), [theme]);
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const onResizeScreen = () => {
    if (map && map.getPanes().mapPane !== undefined) {
      map.invalidateSize();
    }
    zoomToLayer(map, selectedRegion);
  };

  const onOrientationChange = () => {
    forceUpdate();
    onResizeScreen();
  };

  useEffect(() => {
    if (map && map.getPanes().mapPane !== undefined) {
      map.invalidateSize();
    }
  }, [theme]);

  useEffect(() => {
    onMount(setSelectedCity);
  }, [onMount]);

  useEffect(() => {
    window.addEventListener('resize', onResizeScreen);
    window.addEventListener('orientationchange', onOrientationChange);
    return () => {
      window.removeEventListener('resize', onResizeScreen);
      window.removeEventListener('orientationchange', onOrientationChange);
    };
  }, [onResizeScreen, onOrientationChange]);

  // @ts-ignore
  const geoDataRegions = topojson.feature(uajson, uajson.objects.regions).features;

  /*  const onTabChange = () => {
    if (map && map.getPanes().mapPane !== undefined) {
      map.invalidateSize();
    }
    // forceUpdate();
  };*/

  const resetHighlightAll = () => {
    if (geoJSONRef && geoJSONRef.current) {
      const geoJSON = geoJSONRef.current;
      for (const layerUa of geoJSON.getLayers()) {
        d3.select(layerUa._path).interrupt();
        if (layerUa.feature.properties.name !== selectedRegion.feature.properties.name) {
          d3.select(layerUa._path).transition().duration(300).ease(d3.easeCubicOut).attr('fill', 'rgba(0,0,0,0.2)');
        } else {
          d3.select(layerUa._path).attr('fill', '#096DD9').attr('stroke', '#003A8C').attr('stroke-width', 2);
        }
      }
    }
  };

  const regionsInfo = locale === 'uk-UA' ? regionsInfoUA : regionsInfoUS;
  const regionsInfoFiltered = regionsInfo.filter(
    regionInfoLocal => regionInfoLocal.name === selectedRegion.feature.properties.localized_name[locale],
  );
  const regionInfo = regionsInfoFiltered.length > 0 ? regionsInfoFiltered[0] : regionsInfo[0];

  return (
    <div className={'region-info flex'}>
      <RegionInfoPopupDetailsPanel
        regionInfo={regionInfo}
        onMount={([, setSelectedCityLocal]) => {
          setSelectedCity = setSelectedCityLocal;
        }}
        onBack={() => {
          // d3.selectAll('.region-info-city-tooltip')
          //   .transition()
          //   .duration(300)
          //   .ease(d3.easeCubicOut)
          //   .style('background-color', '#FFFFFF')
          //   .style('border-color', '#FFFFFF');
          d3.selectAll('.region-info-city-tooltip').classed('selected', false);
        }}
      />
      <div className={'side-panel flex relative'}>
        <div className={'right-background'} />
        <div className="geo-root flex flex-row">
          <div className={'map-wrapper overflow-hidden'}>
            <MapContainer
              attributionControl={false}
              center={zoomCenter.current}
              zoom={zoomLevel.current}
              zoomControl={false}
              minZoom={isPhone ? 0 : 1}
              maxZoom={17}
              preferCanvas={false}
              // preferCanvas={theme === 'designcanvas'}
              // renderer={theme === 'designcanvas' ? L.canvas() : L.svg()}
              tap={false}
              // disable zoom and pan
              doubleClickZoom={false}
              closePopupOnClick={false}
              dragging={false}
              zoomSnap={0}
              zoomDelta={0}
              trackResize={false}
              touchZoom={false}
              scrollWheelZoom={false}
              keyboard={false}
              // END disable zoom and pan
              ref={(ref: any) => {
                // if (ref && ref.map) {
                // @ts-ignore
                if (geoJSONRef && geoJSONRef.current) {
                  setTimeout(() => {
                    if (map === undefined) {
                      d3.selectAll('.region-info-city').attr('stroke-opacity', 0).attr('fill-opacity', 0);
                      d3.selectAll('.region-info-city-tooltip').classed('active', false);
                      // @ts-ignore
                      zoomToLayer(ref, selectedRegion);
                    }
                    setMap(ref);
                    resetHighlightAll();

                    const geoJSON = geoJSONRef.current;
                    if (geoJSON) {
                      for (const layerUa of geoJSON.getLayers()) {
                        if (layerUa.feature.properties.name === selectedRegion.feature.properties.name) {
                          layerUa.bringToFront();
                        }
                      }
                    }
                  }, 400);

                  setTimeout(() => {
                    d3.selectAll('.region-info-city').interrupt();
                    d3.selectAll('.region-info-city')
                      .transition()
                      .duration(700)
                      .ease(d3.easeCubicOut)
                      .attr('stroke-opacity', 1)
                      .attr('fill-opacity', 1);

                    // d3.selectAll('.region-info-city-tooltip').interrupt();
                    // d3.selectAll('.region-info-city-tooltip')
                    //   .style('pointer-events', 'all')
                    //   .transition()
                    //   .duration(700)
                    //   .ease(d3.easeCubicOut)
                    //   .style('opacity', 1);
                    d3.selectAll('.region-info-city-tooltip').classed('active', true);
                  }, 1200);
                }
                // }
              }}
            >
              <Pane name="city" style={{ zIndex: 10001 }} />
              <Pane name="markers" style={{ zIndex: 10002 }} />
              <Pane name="tooltips" style={{ zIndex: 10003 }} />
              <CityDots
                showAdminCenterOnly={false}
                showNameOnHover={false}
                showNames={true}
                // color={'#FFFFFF'}
                bigCityOpacity={0}
                smallCityOpacity={0}
                bigCityRadius={8000}
                className={'region-info-city'}
                tooltipClass={'region-info-city-tooltip'}
                region={selectedRegion}
                selectedCity={selectedCity}
                interactive={true}
                onCityClick={(cityName: string, tooltipLayer: any) => {
                  setSelectedCity(cityName);

                  // d3.selectAll('.region-info-city-tooltip')
                  //   .transition()
                  //   .duration(300)
                  //   .ease(d3.easeCubicOut)
                  //   .style('background-color', '#FFFFFF')
                  //   .style('border-color', '#FFFFFF');
                  //
                  // d3.select(tooltipLayer.getElement()).interrupt();
                  // d3.select(tooltipLayer.getElement())
                  //   .transition()
                  //   .duration(300)
                  //   .ease(d3.easeCubicOut)
                  //   .style('background-color', DefaultTheme.cityColor)
                  //   .style('border-color', '#777');
                  d3.selectAll('.region-info-city-tooltip').classed('selected', false);
                  d3.select(tooltipLayer.getElement()).classed('selected', true);
                }}
              />
              <GeoJSON
                data={geoDataRegions as GeoJSON.GeoJsonObject}
                // eventHandlers={geoJsonHandlers}
                style={() => {
                  return {
                    fillColor: themeColors.region,
                    weight: 1,
                    strokeWidth: 2,
                    opacity: 1,
                    color: themeColors.regionBorder,
                    dashArray: '0',
                    fillOpacity: 1,
                  };
                }}
                ref={(ref: any) => {
                  geoJSONRef.current = ref;
                }}
              />
            </MapContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegionInfoPopup;
