import * as types from 'state/data/actionTypes';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../index';
import { UserAction } from '../../types/models';
import dataService from '../../common/services/dataService';
import dayjs from 'dayjs';
import isMobile from 'ismobilejs';
import Bowser from 'bowser';

// All Actions Types
export type ActionsTypes = LoadingActionType | ErrorActionType | SaveUserActionType;

// Thunk Type
export type ThunkType<R extends ActionsTypes> = ThunkAction<
  Promise<R | ErrorActionType>,
  RootState,
  unknown,
  R | LoadingActionType | ErrorActionType
>;

// Loading action
type LoadingActionType = { type: typeof types.DATA_LOADING; payload: LoadingTypes };
export const loadingAction = (payload: LoadingTypes): LoadingActionType => ({ type: types.DATA_LOADING, payload });

// Error action
type ErrorActionType = { type: typeof types.DATA_LOADING_ERROR; payload: any };
export const errorAction = (payload: any): ErrorActionType => ({ type: types.DATA_LOADING_ERROR, payload });

// Save user action
export type SaveUserActionType = { type: typeof types.SAVE_USER_ACTION_SUCCESS; payload: UserAction };
export const saveUserActionAction = (payload: UserAction): SaveUserActionType => ({
  type: types.SAVE_USER_ACTION_SUCCESS,
  payload,
});
export const saveUserAction =
  (action: string, oldValue: string, value: string, meta: string, theme: string, locale: string): ThunkType<SaveUserActionType> =>
  async dispatch => {
    const isMobileCheck = isMobile(window.navigator.userAgent);
    const browser = Bowser.getParser(window.navigator.userAgent);

    const payload: UserAction = {
      action: action,
      session: 'we_do_not_hold_this_information',
      path: window.location.pathname,
      created: dayjs().toDate(),
      oldValue: oldValue,
      value: value,
      meta: meta,
      theme: theme,
      locale: locale,
      isMobile: `${isMobileCheck.any ? 'mobile' : 'desktop'}${isMobileCheck.phone ? ' phone' : ''}${' ' + browser.getBrowserName()}${
        isMobileCheck.tablet ? ' tablet' : ''
      }`,
    };

    try {
      await dataService.saveUserAction(payload);
      return dispatch(saveUserActionAction(payload));
    } catch (error: any) {
      return dispatch(errorAction(error?.response?.data));
    }
  };
