import { isEqual, mergeWith, toLower, trim } from 'lodash';
import { findByAlpha3 } from 'iso-3166-1-ts';
import json from '../../components/GEO/common/geojson/world/countries.geo.json';

export const isDev = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const compareByParams = (a: any, b: any, param: string): number => (a[param] > b[param] ? 1 : -1);
export const compareByDate = (a: any, b: any, param: string): any => new Date(b[param]).getTime() - new Date(a[param]).getTime();
export const compareIgnoringCase = (a: string, b: string): number => (a.toLowerCase() > b.toLowerCase() ? 1 : -1);

export const capitalize = (s: string) => {
  return s ? s.charAt(0).toUpperCase() + s.slice(1) : '';
};

export const deepCompareObj = (oldObj: CommonObj, newObj: CommonObj) => {
  const updatedObj: any = {};
  mergeWith(oldObj, newObj, (objectValue, sourceValue, key) => {
    if (!isEqual(objectValue, sourceValue) && Object(objectValue) !== objectValue) {
      updatedObj[key] = sourceValue;
    }
  });
  return updatedObj;
};

export const getFirstCharacterFromString = (val: string): string => String(val.charAt(0).toUpperCase());

export const normalizeStrForSearch = (val: string): string => trim(toLower(val));

export const groupBy = (array: any[], key: string) =>
  array.reduce((rv: { [key: string]: any }, x: any) => {
    // eslint-disable-next-line no-param-reassign
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

export const isTouchDevice = () =>
  'ontouchstart' in window ||
  navigator.maxTouchPoints > 0 ||
  // @ts-ignore
  navigator.msMaxTouchPoints > 0;

export const isTouch = isTouchDevice();

export const isPhone = window.screen.width < 600 || window.screen.height < 600;
export const isPortrait = () => window.orientation === 0;

export const isSafari = /Safari\//.test(navigator.userAgent) && !/(Chrome\/|Android\s)/.test(navigator.userAgent);
export const isSafariMacintosh = isSafari && navigator.userAgent.includes('Macintosh');
export const isSafariIPhone = isSafari && navigator.userAgent.includes('iPhone');

export const openInNewTab = (url: string) => {
  window.open(url, '_blank')?.focus();
};

export const getCountryNameByIso3 = (iso3: string | undefined): string => {
  let countryName;
  const featureFound = json.features.filter((feature: any) => feature.id === iso3)[0];
  if (featureFound) {
    countryName = featureFound.properties.name;
  }

  if (countryName === undefined) {
    countryName = findByAlpha3(iso3 || '')?.name;

    if (countryName?.indexOf('(') !== -1) {
      countryName = countryName?.slice(0, countryName?.indexOf('('));
    }
  }
  return countryName || '';
};

export const getTooltipContent = (name: string, value: number | undefined, delta: number | undefined, parentSum: number) => {
  return `<div class="content-caption">${name}</div>
        <div class="content-row"><span class="content-row-caption">Active jobs <span class="content-row-sub">[ on period start ]</span></span><span>${
          value || 0
        }</span></div>
        <div class="content-row"><span class="content-row-caption">Market share %: </span><span>${Math.round(
          ((value || 0) / parentSum) * 100,
        )}%</span></div>
        <div class="content-row"><span class="content-row-caption">Change in period: </span><span>${delta || 0}</span></div>
        <div class="content-row"><span class="content-row-caption">Change in period %: </span><span>${Math.round(
          ((delta || 0) / (value || 1)) * 100,
        )}%</span></div>`;
};

// Check if `child` is a descendant of `parent`
export const isDescendant = (parent: any, child: any) => {
  let node = child.parentNode;
  while (node) {
    if (node === parent) {
      return true;
    }

    // Traverse up to the parent
    node = node.parentNode;
  }

  // Go up until the root but couldn't find the `parent`
  return false;
};
