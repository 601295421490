import { ColorScheme } from '../../types/project';

export const DefaultThemeRed: ColorScheme = Object.freeze({
  name: 'default',

  region: '#595959',
  regionBorder: '#8C8C8C',
  regionHighlight: '#096DD9',
  regionHighlightBorder: '#003A8C',
  regionCorrect: '#BAE637',
  regionCorrectBorder: '#7CB305',
  regionIncorrect: '#FF7A45',
  regionIncorrectBorder: '#D4380D',
  cityIncorrect: '#FF4D4F',
  cityIncorrectBorder: '#CF1322',

  cityColor: '#FFF700',
});
