import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { LatLngTuple } from 'leaflet';
import { GeoJSON, MapContainer, Pane } from 'react-leaflet';
// postCSS import of Leaflet's CSS
import 'leaflet/dist/leaflet.css';
import { isPhone, isPortrait } from '../../../common/utils/commonUtils';
import AppContext from '../../../common/AppContext';
import { Button, Modal, notification } from 'antd';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { zoomToLayer } from '../../../common/utils/leafletUtils';
import TestCity from './components/TestCity';
import TestRegions from './components/TestRegions';
import uacitiesjson from '../common/geojson/UA-cities/ua-cities.json';
import smile from '../../../styles/assets/images/smile.png';
import { locales } from '../../../common/localization';
import uajson from '../common/geojson/UA/ukraine-geo.json';
import RegionsInfoUA from '../common/datajson/RegionsInfoUA.json';
import { cloneDeep } from 'lodash';
import { getIconClassName } from '@uifabric/styling';
import Timer from 'react-compound-timer';
import TestResult, { minHappuCatPercent } from './components/TestResult';
import dayjs from 'dayjs';

import './Test.scss';
import * as topojson from 'topojson-client';
import { getThemeColors } from '../../../common/themes/Themes';
import { saveUserAction } from '../../../state/data/actions';
import { useDispatch } from 'react-redux';
import { Icon } from '@iconify/react';
import closeOutline from '@iconify-icons/eva/close-outline';
import TestRegionsLicensePlate from './components/TestRegionsLicensePlate';

let notificationShown = false;
const localizedQuestions: { [key: string]: { [key: string]: string } } = {};
const answers: { [key: string]: string } = {};
let questionsAndResults: { [key: string]: { correct: boolean | undefined; time: number } } = {};
let currentQuestion = '';
const maxHappuCatIndex = 12;
let happuCatIndex = 1;
const maxUnhappuCatIndex = 16;
let unhappuCatIndex = 1;

const Test: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const { theme, locale, testResults, setTestResults } = useContext(AppContext);

  const [map, setMap] = useState<any | undefined>(undefined);
  const geoJSONRef = useRef<any | undefined>(undefined);
  const timerRef = useRef<any | undefined>(undefined);
  const zoomCenter = useRef<LatLngTuple | undefined>([48.8, 32]);
  const zoomLevel = useRef<number | undefined>(5);
  const [showTestResultPopup, setShowTestResultPopup] = useState<boolean>(false);
  const themeColors = useMemo(() => getThemeColors(theme), [theme]);

  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  // Test data ------------------------------------------------------------------
  const answeredQuestions = Object.keys(questionsAndResults).filter(question => questionsAndResults[question].correct !== undefined);
  const correctAnswers = Object.keys(questionsAndResults).filter(question => questionsAndResults[question].correct === true);
  const incorrectAnswers = Object.keys(questionsAndResults).filter(question => questionsAndResults[question].correct === false);
  // ----------------------------------------------------------------------------

  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('type');
  const testName =
    type === 'regionbyname'
      ? intl.formatMessage({ id: 'testChooseRegionByName' })
      : type === 'regionbylicenseplate'
      ? intl.formatMessage({ id: 'testChooseRegionByLicensePlate' })
      : type === 'citybyname'
      ? intl.formatMessage({ id: 'testChooseCityByName' })
      : '';

  // @ts-ignore
  const geoDataRegions = topojson.feature(uajson, uajson.objects.regions).features;
  // @ts-ignore
  const geoDataRivers = topojson.feature(uajson, uajson.objects.rivers).features;
  // @ts-ignore
  const geoDataLakes = topojson.feature(uajson, uajson.objects.lakes).features;

  const onOrientationChange = () => {
    forceUpdate();
  };

  useEffect(() => {
    window.addEventListener('orientationchange', onOrientationChange);
    return () => {
      window.removeEventListener('orientationchange', onOrientationChange);
    };
  }, [onOrientationChange]);

  const renderHeader = () => (
    <span>
      {intl.formatMessage({ id: 'testNotification1' })} <img className={'smile'} src={smile} alt={'smile'} />
    </span>
  );

  const renderDescription = () => (
    <span>
      {intl.formatMessage({ id: 'testNotification2' })}{' '}
      <span
        className={'anchor'}
        onMouseDown={() => {
          history.push('/geo/explore');
          notification.destroy();
        }}
      >
        {intl.formatMessage({ id: 'testNotification3' })}
      </span>
      {intl.formatMessage({ id: 'testNotification4' })}
    </span>
  );

  const nextQuestion = () => {
    const unansweredQuestions = Object.keys(questionsAndResults).filter(question => questionsAndResults[question].correct === undefined);
    if (unansweredQuestions.length > 0) {
      const incorrectAnswersLocal = Object.keys(questionsAndResults).filter(question => questionsAndResults[question].correct === false);
      if (incorrectAnswersLocal.length === 2) {
        if (!notificationShown) {
          notificationShown = true;

          notification.open({
            message: renderHeader(),
            description: renderDescription(),
            className: 'test-result-notification',
            duration: 0,
            placement: isPortrait() ? 'bottomRight' : 'topLeft',
            closeIcon: <Icon icon={closeOutline} width={22} height={22} />,
          });
        }
      }

      currentQuestion = unansweredQuestions[Math.floor(Math.random() * unansweredQuestions.length)];

      forceUpdate();
    } else {
      setShowTestResultPopup(true);
    }
  };

  const startTest = () => {
    notificationShown = false;
    questionsAndResults = {};
    if (type === 'citybyname') {
      uacitiesjson
        .filter(city => city.capital === 'primary' || city.capital === 'admin')
        .map((city: any /*, index*/) => {
          // if (index === 0) {
          if (questionsAndResults[city.city] === undefined) {
            questionsAndResults[city.city] = { correct: undefined, time: 0 };
          }

          localizedQuestions[city.city] = {};

          Object.keys(locales).map(localeLocal => {
            localizedQuestions[city.city][localeLocal] = city.name[localeLocal];
          });
          // }
        });
    } else if (type === 'regionbyname') {
      uajson.objects.regions.geometries.map((region: any /*, index*/) => {
        // if (index === 0) {
        if (questionsAndResults[region.properties.name] === undefined) {
          questionsAndResults[region.properties.name] = { correct: undefined, time: 0 };
        }
        // properties.localized_name.ua
        localizedQuestions[region.properties.name] = {};

        Object.keys(locales).map(localeLocal => {
          // @ts-ignore
          const name = uajson.objects.regions.geometries.filter(regionLocal => regionLocal.properties.name === region.properties.name)[0]
            .properties.localized_name[localeLocal];
          localizedQuestions[region.properties.name][localeLocal] = name === undefined ? region.properties.name : name;
        });
        // }
      });
    } else if (type === 'regionbylicenseplate') {
      RegionsInfoUA.map((region: any) => {
        const licensePlate = region.carNumbers;
        // const licensePlates = region.carNumbers.replaceAll(' ', '').split(',');
        // for (const licensePlate of licensePlates) {
        if (questionsAndResults[licensePlate] === undefined) {
          questionsAndResults[licensePlate] = { correct: undefined, time: 0 };
        }

        localizedQuestions[licensePlate] = {};

        Object.keys(locales).map(localeLocal => {
          // @ts-ignore
          localizedQuestions[licensePlate][localeLocal] = licensePlate;
        });

        answers[licensePlate] = region.name;
        // }
      });
    }

    nextQuestion();

    if (timerRef && timerRef.current) {
      timerRef.current.reset();
      timerRef.current.start();
    }
  };

  useEffect(() => {
    startTest();
  }, []);

  function zoomAgain() {
    if (map && map.getPanes().mapPane !== undefined) {
      map.setView([48.8, 32], 5, { animate: false });
      zoomToLayer(map, geoJSONRef.current, false);
    }
  }

  useEffect(() => {
    if (type !== 'citybyname' && type !== 'regionbyname' && type !== 'regionbylicenseplate') {
      history.push('/geo/landing');
    }

    currentQuestion = '';

    startTest();
    forceUpdate();
    setTimeout(() => {
      zoomAgain();
    }, 0);
  }, [location.search]);

  const onResizeScreen = () => {
    if (map && map.getPanes().mapPane !== undefined) {
      map.invalidateSize();
      zoomToLayer(map, geoJSONRef.current, false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onResizeScreen);
    window.addEventListener('orientationchange', onResizeScreen);
    return () => {
      window.removeEventListener('resize', onResizeScreen);
      window.removeEventListener('orientationchange', onResizeScreen);
    };
  }, [onResizeScreen]);

  const onFinishTest = (test: string) => {
    timerRef.current.stop();

    const correctPercent = Math.round((correctAnswers.length / (correctAnswers.length + incorrectAnswers.length)) * 100);

    if (correctPercent >= minHappuCatPercent) {
      if (happuCatIndex < maxHappuCatIndex) {
        happuCatIndex++;
      } else {
        happuCatIndex = 1;
      }
    } else {
      if (unhappuCatIndex < maxUnhappuCatIndex) {
        unhappuCatIndex++;
      } else {
        unhappuCatIndex = 1;
      }
    }

    const correctAnswersLocal = Object.keys(questionsAndResults).filter(question => questionsAndResults[question].correct === true);
    const currentTestPercent = Math.round((correctAnswersLocal.length / Object.keys(questionsAndResults).length) * 100);
    if (testResults[test] === undefined || currentTestPercent > (testResults[test] | 0)) {
      testResults[test] = currentTestPercent;
      setTestResults(cloneDeep(testResults));
    }

    dispatch(saveUserAction('test', '', String(currentTestPercent), JSON.stringify(questionsAndResults), theme, locale));
  };

  function onTryAgain() {
    notification.destroy();
    setShowTestResultPopup(false);
    startTest();
    zoomAgain();
  }

  return (
    <div className="flex flex-col flex-1 test">
      <div className={`geo-root flex-1 flex`}>
        <div className={'map-wrapper'}>
          <MapContainer
            attributionControl={false}
            center={zoomCenter.current}
            zoom={zoomLevel.current}
            zoomControl={false}
            minZoom={isPhone ? 0 : 1}
            maxZoom={17}
            preferCanvas={false}
            // preferCanvas={theme === 'designcanvas'}
            // renderer={theme === 'designcanvas' ? L.canvas() : L.svg()}
            tap={false}
            // disable zoom and pan
            doubleClickZoom={false}
            closePopupOnClick={false}
            dragging={false}
            zoomSnap={0}
            zoomDelta={0}
            trackResize={false}
            touchZoom={false}
            scrollWheelZoom={false}
            keyboard={false}
            // END disable zoom and pan
            ref={(ref: any) => {
              // if (ref && ref.map) {
              // @ts-ignore
              if (geoJSONRef && geoJSONRef.current) {
                setTimeout(() => {
                  if (map === undefined) {
                    // @ts-ignore
                    zoomToLayer(ref, geoJSONRef.current, false);
                  }

                  setMap(ref);
                }, 100);
              }
              // }
            }}
          >
            <Pane name="rivers" style={{ zIndex: 10001, pointerEvents: 'none' }} />
            <Pane name="borders" style={{ zIndex: 10002, pointerEvents: 'none' }} />
            <Pane name="city" style={{ zIndex: 10003 }} />
            <Pane name="markers" style={{ zIndex: 10004 }} />
            <Pane name="tooltips" style={{ zIndex: 10005 }} />
            <GeoJSON
              data={geoDataRegions as GeoJSON.GeoJsonObject}
              style={() => {
                return {
                  fillColor: themeColors.region,
                  weight: 1,
                  opacity: 1,
                  color: 'transparent',
                  dashArray: '0',
                  fillOpacity: 1,
                };
              }}
            />
            {type === 'citybyname' && (
              <TestCity
                geoJSONRef={geoJSONRef}
                questionsAndAnswers={questionsAndResults}
                localizedQuestions={localizedQuestions}
                currentQuestion={currentQuestion}
                nextQuestion={nextQuestion}
                onFinishTest={() => {
                  onFinishTest(type);
                }}
              />
            )}
            {type === 'regionbyname' && (
              <TestRegions
                geoJSONRef={geoJSONRef}
                questionsAndAnswers={questionsAndResults}
                localizedQuestions={localizedQuestions}
                currentQuestion={currentQuestion}
                nextQuestion={nextQuestion}
                onFinishTest={() => {
                  onFinishTest(type);
                }}
              />
            )}
            {type === 'regionbylicenseplate' && (
              <TestRegionsLicensePlate
                geoJSONRef={geoJSONRef}
                questionsAndAnswers={questionsAndResults}
                answers={answers}
                currentQuestion={currentQuestion}
                nextQuestion={nextQuestion}
                onFinishTest={() => {
                  onFinishTest(type);
                }}
              />
            )}

            <GeoJSON
              pane={'rivers'}
              data={geoDataRivers as GeoJSON.GeoJsonObject}
              style={() => {
                return {
                  fillColor: themeColors.regionHighlight,
                  weight: 2,
                  opacity: 1,
                  color: themeColors.regionHighlight,
                  dashArray: '0',
                  fillOpacity: 1,
                };
              }}
            />
            <GeoJSON
              pane={'rivers'}
              data={geoDataLakes as GeoJSON.GeoJsonObject}
              style={() => {
                return {
                  fillColor: themeColors.regionHighlight,
                  weight: 1,
                  opacity: 1,
                  color: themeColors.regionHighlight,
                  dashArray: '0',
                  fillOpacity: 1,
                };
              }}
            />
          </MapContainer>
        </div>
        <div className={'side-panel p-24 relative'}>
          <div className={'flex flex-row items-center test-header'}>
            <i className={getIconClassName('Timer')} />
            <div className={'mx-8'}>
              <Timer initialTime={0} direction="forward" ref={timerRef} startImmediately={true}>
                {() => (
                  <>
                    <Timer.Minutes formatValue={value => `${value < 10 ? '0' + String(value) : value}`} />:
                    <Timer.Seconds formatValue={value => `${value < 10 ? '0' + String(value) : value}`} />
                  </>
                )}
              </Timer>
            </div>
            <div className={'test-name whitespace-nowrap'}>
              {locale === 'uk-UA' && intl.formatMessage({ id: 'testChooseGuess' })} {testName}
            </div>
          </div>

          <div className={'test-progress flex flex-row items-center'}>
            <i className={getIconClassName('CannedChat')} />
            <div className={'questions-counter ml-8'}>
              {answeredQuestions.length}/{Object.keys(questionsAndResults).length}
            </div>
            <div className={'correct-questions-counter ml-16 flex flex-row items-center'}>
              <i className={getIconClassName('CheckMark')} />
              <div className={'pl-4'}>{correctAnswers.length}</div>
            </div>
            <div className={'incorrect-questions-counter ml-8 flex flex-row items-center'}>
              <i className={getIconClassName('Cancel')} />
              <div className={'pl-4'}>{incorrectAnswers.length}</div>
            </div>
          </div>

          <div className={'question-block flex flex-col mt-24'}>
            {/*{type === 'citybyname' ? (*/}
            {/*  <div className={'question'}>{intl.formatMessage({ id: 'testMapQuestionCity' })}</div>*/}
            {/*) : (*/}
            <div className={'question'}>{intl.formatMessage({ id: 'testMapQuestion' })}</div>
            {/*)}*/}
            <div className={'question-value mt-8'}>{currentQuestion ? localizedQuestions[currentQuestion][locale] : ''}</div>
          </div>

          <div className={`buttons flex flex-col w-full ${isPortrait() ? 'items-end' : 'items-center'}`}>
            {type === 'citybyname' && (
              <div className={'flex flex-row pb-24 additional-info'}>
                <i className={`${getIconClassName('Info')} mr-8 mt-2`} />
                <div>{intl.formatMessage({ id: 'testMapAdditionalInfo' })}</div>
              </div>
            )}
            {/*<Button
              className={'explore-button'}
              onMouseDown={() => {
                history.push('/geo/explore');
              }}
            >
              {intl.formatMessage({ id: 'exploreButton' })}
            </Button>

            <div
              className={'stop-test'}
              onClick={() => {
                history.push('/geo/landing');
              }}
            >
              {intl.formatMessage({ id: 'testMapStopTest' })}
            </div>*/}
            <Button
              block={false}
              onMouseDown={() => {
                onTryAgain();
              }}
            >
              {intl.formatMessage({ id: 'testResultTryAgain' })}
            </Button>
          </div>
        </div>

        <Modal
          title={intl.formatMessage({ id: 'testResult' })}
          centered
          width={600}
          closeIcon={<Icon icon={closeOutline} width={22} height={22} />}
          className={'test-result'}
          visible={showTestResultPopup}
          footer={null}
          onOk={() => {
            setShowTestResultPopup(false);
            startTest();
          }}
          onCancel={() => {
            setShowTestResultPopup(false);
            startTest();
          }}
        >
          <TestResult
            test={testName}
            correct={correctAnswers.length}
            incorrect={incorrectAnswers.length}
            time={dayjs(timerRef?.current?.getTime() || 0).format('mm:ss')}
            onGoToMain={() => {
              setShowTestResultPopup(false);
              history.push('/geo/landing');
            }}
            onTryAgain={() => {
              onTryAgain();
            }}
            happuCatIndex={happuCatIndex}
            unhappuCatIndex={unhappuCatIndex}
          />
        </Modal>
        <Button
          className={'cursor-pointer back'}
          onMouseDown={() => {
            history.push('/geo/landing');
          }}
        >
          {intl.formatMessage({ id: 'backToLanding' })}
        </Button>
      </div>
    </div>
  );
};

export default Test;
