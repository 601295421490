import { FC, useEffect } from 'react';
import { Checkbox, Form, Input } from 'antd';
import { CreateUserRequest, UpdateUserRequest } from 'types/models';

interface UserFormProps {
  type: 'create' | 'update';
  onFinish: (values: CreateUserRequest & UpdateUserRequest) => void;
  setFormObj?: (form: FormObj) => void;
}

const UserFrom: FC<UserFormProps> = ({ type, onFinish, setFormObj }): JSX.Element => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (setFormObj) setFormObj(form);
  }, [setFormObj, form]);

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Form.Item
        name="firstName"
        label="First Name"
        className="mb-16"
        required
        rules={[
          {
            required: true,
            message: 'First Name is required',
          },
          {
            max: 80,
            message: 'Field First Name cannot exceed 80 characters',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="lastName"
        label="Last Name"
        className="mb-16"
        required
        rules={[
          {
            required: true,
            message: 'Last Name is required',
          },
          {
            max: 80,
            message: 'Field Last Name cannot exceed 80 characters',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.email !== curValues.email}>
        {({ getFieldValue }) => (
          <Form.Item
            name="email"
            label="Email"
            className="mb-16"
            required
            rules={[
              {
                required: true,
                message: 'Email is required',
              },
              {
                type: 'email',
                message: 'Email format is invalid',
              },
              {
                max: 254,
                message: 'Field Email cannot exceed 254 characters',
              },
            ]}
          >
            <Input title={type === 'update' ? getFieldValue('email') : ''} disabled={type === 'update'} />
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        className="mb-40"
        required
        rules={[
          {
            required: type === 'create',
            message: 'Password is required',
          },
          {
            min: 8,
            message: 'Field Password cannot be less than 8 characters',
          },
          {
            max: 20,
            message: 'Field Password cannot exceed 20 characters',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <h2>Permissions</h2>
      <Form.Item name="admin" valuePropName="checked" initialValue={false} className="mb-0">
        <Checkbox className="mb-18 text-nero leading-8 custom-checkbox">Administrator access</Checkbox>
      </Form.Item>
    </Form>
  );
};

export default UserFrom;
