import { AnyAction, combineReducers } from 'redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import authReducer from './auth/reducer';
import filterReducer from './filter/reducer';
import usersReducer from './users/reducer';
import dataReducer from './data/reducer';

const appReducers = combineReducers({
  auth: authReducer,
  users: usersReducer,
  filter: filterReducer,
  data: dataReducer,
});

const rootReducers = (state: any, action: AnyAction) => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
  const { AUTH_LOGOUT_SUCCESS } = require('state/auth/actionTypes');

  if (action.type === AUTH_LOGOUT_SUCCESS) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return appReducers(state, action);
};

export type RootState = ReturnType<typeof rootReducers>;
type PropertiesTypes<T> = T extends { [key: string]: infer U } ? U : never;
export type InferActionsTypes<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<PropertiesTypes<T>>;
export type ReturnNestedType<T> = T[keyof T] extends (...args: any) => infer R ? R : never;

/** Application specific strongly typed wrapper around redux's useSelector() and useDispatch() */

export const useAppDispatch = () => useDispatch<any>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default rootReducers;
