import React, { FC } from 'react';
import { ImageOverlay } from 'react-leaflet';
import L from 'leaflet';

const DevKidsMap: FC = ({}): JSX.Element => {
  const bounds = L.latLngBounds(L.latLng(44.3, 21.6), L.latLng(52.47, 40.5));
  // const bounds = L.latLngBounds(L.latLng(43.95, 20.9), L.latLng(52.57, 40.6));

  return (
    <>
      <ImageOverlay bounds={bounds} url="./images/506910_2.jpeg" />
    </>
  );
};

export default DevKidsMap;
