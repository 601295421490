import { cloneElement, FC, useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { Button, Drawer } from 'antd';
import { useSelector } from 'react-redux';
import { UsersLoadingSelector } from 'state/users/selectors';
import closeOutline from '@iconify-icons/eva/close-outline';

interface CreateNewActionProps {
  currentTabKey: string;
  children?: any;
}

const CreateNewAction: FC<CreateNewActionProps> = ({ currentTabKey, children }): JSX.Element | null => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [formObj, setFormObj] = useState<FormObj>();
  const loadingUsers = useSelector(UsersLoadingSelector) === 'post';

  useEffect(() => {
    if (isModalVisible) formObj?.resetFields();
  }, [formObj, isModalVisible]);

  const handleShowModal = (): void => {
    setIsModalVisible(true);
  };

  const handleHideModal = (): void => {
    setIsModalVisible(false);
  };

  const configTab: CommonObj = {
    users: {
      onCreateTitle: 'New User',
    },
  };

  return (
    <>
      {currentTabKey !== 'frameworks' ? (
        <>
          <Button type="primary" onClick={handleShowModal}>
            Create New
          </Button>
          <Drawer
            title={configTab[currentTabKey].onCreateTitle}
            placement="right"
            width={350}
            maskClosable={false}
            onClose={handleHideModal}
            visible={isModalVisible}
            footerStyle={{ paddingInline: 24 }}
            closeIcon={<Icon icon={closeOutline} width={22} height={22} />}
            footer={
              <div className="flex justify-end">
                <Button type="default" className="mr-12" onClick={handleHideModal}>
                  Cancel
                </Button>
                <Button type="primary" onClick={() => formObj?.submit()} loading={loadingUsers}>
                  Create
                </Button>
              </div>
            }
          >
            {cloneElement(children, { handleHideModal, formObj, setFormObj })}
          </Drawer>
        </>
      ) : null}
    </>
  );
};

export default CreateNewAction;
