import { select } from 'd3';
import './Tooltip.scss';

export class Tooltip {
  static tooltipPopup: any;

  static tooltipPopupId: string;

  static closeTooltip = (tooltipPopupId: string) => {
    if (Tooltip.tooltipPopupId === tooltipPopupId) {
      Tooltip.tooltipPopup = select('body .tooltipPopup');

      Tooltip.tooltipPopup.transition().duration(500).style('opacity', 0);

      Tooltip.tooltipPopup.transition().delay(600).style('display', 'none').style('visibility', 'hidden');
    }
  };

  static immediateCloseTooltip = () => {
    Tooltip.tooltipPopup = select('body .tooltipPopup');

    Tooltip.tooltipPopup.style('opacity', 0).style('display', 'none').style('visibility', 'hidden');
  };

  static initTooltipPopup = () => {
    // const onMouseEnter = () => {
    //   tooltipPopup.interrupt();
    //   tooltipPopup
    //     .style('display', 'block')
    //     .style('visibility', 'visible')
    //     .style('opacity', 1);
    // };
    if (!document.querySelector('body .tooltipPopup')) {
      select('body')
        .append('div')
        .classed('ui center right popup transition tooltipPopup', true)
        .style('opacity', 0)
        .style('display', 'none')
        .style('position', 'absolute')
        .append('div')
        .classed('content', true);
      // .on('mouseenter', onMouseEnter)
      // .on('mousemove', onMouseEnter)
      // .on('mouseleave', () => {
      //   tooltipPopup
      //     .transition()
      //     .duration(500)
      //     .style('opacity', 0);
      //
      //   tooltipPopup
      //     .transition()
      //     .delay(600)
      //     .style('display', 'none')
      //     .style('visibility', 'hidden');
      // });
    }

    Tooltip.tooltipPopup = select('body .tooltipPopup');
  };

  static updateTooltipPosition = (clientX: number, clientY: number) => {
    if (clientX !== undefined) {
      Tooltip.tooltipPopup = select('body .tooltipPopup');
      const tooltipBBOx = Tooltip.tooltipPopup.node().getBoundingClientRect();
      const tooltipWidth = tooltipBBOx.width; // isTouch ? 0.47 * Math.min(window.screen.width, window.screen.height) : 250;
      const tooltipHeight = tooltipBBOx.height; // isTouch ? 0.47 * Math.min(window.screen.width, window.screen.height) : 250;

      const top = clientY + window.pageYOffset + 7 - tooltipBBOx.height / 2;
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      if (clientX + window.pageXOffset + 20 + tooltipWidth > window.innerWidth) {
        document.querySelector('.tooltipPopup')?.classList.remove('right');
        document.querySelector('.tooltipPopup')?.classList.add('left');

        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const left = clientX + window.pageXOffset - (20 + tooltipWidth);
        Tooltip.tooltipPopup
          .style('left', '0px')
          .style('top', '0px')
          .style('transform', `translate(${left}px, ${top}px)`)
          .attr('data-left', left)
          .attr('data-top', top);
      } else {
        document.querySelector('.tooltipPopup')?.classList.remove('left');
        document.querySelector('.tooltipPopup')?.classList.add('right');

        const left = clientX + window.pageXOffset + 20;
        Tooltip.tooltipPopup
          .style('left', '0px')
          .style('top', '0px')
          .style('transform', `translate(${left}px, ${top}px)`)
          .attr('data-left', left)
          .attr('data-top', top);
      }

      document.querySelector('.tooltipPopup')?.classList.remove('top');
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      if (clientY + window.pageYOffset + tooltipHeight / 2 > window.innerHeight) {
        document.querySelector('.tooltipPopup')?.classList.add('top');
      }
    }
  };

  static showTooltip = (tooltipPopupId: string, html: string) => {
    Tooltip.tooltipPopup = select('body .tooltipPopup');
    Tooltip.tooltipPopupId = tooltipPopupId;

    Tooltip.tooltipPopup.interrupt();
    Tooltip.tooltipPopup.style('display', 'block').style('visibility', 'visible').select('.content').html(html);

    Tooltip.tooltipPopup.transition().duration(200).style('opacity', 1);
  };
}
