import React, { FC } from 'react';
import { GeoJSON } from 'react-leaflet';
import geoDataRailway from '../../common/geojson/UA/sources/www.diva-gis.org/UKR_rrd.json';
import uawhole from '../../common/geojson/UA/sources/www.diva-gis.org/UKR_adm0-2.json';

const DevRailway: FC = (): JSX.Element => {
  return (
    <>
      <GeoJSON
        data={geoDataRailway as GeoJSON.GeoJsonObject}
        style={() => {
          return {
            weight: 1,
            opacity: 1,
            color: 'rgba(20, 150, 255, 0.5)',
            dashArray: '0',
            fillOpacity: 1,
          };
        }}
      />
      <GeoJSON
        data={uawhole as GeoJSON.GeoJsonObject}
        style={() => {
          return {
            weight: 2,
            opacity: 1,
            fillColor: 'rgba(0, 0, 0, 0)',
            color: 'rgba(20, 150, 255, 0.5)',
            dashArray: '0',
            fillOpacity: 1,
          };
        }}
      />
    </>
  );
};

export default DevRailway;
