import React, { FC, useEffect, useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { reportDateNow } from 'common/utils/dateUtils';
import { isDev } from '../../../common/utils/commonUtils';

import './ModalReport.scss';
import { Icon } from '@iconify/react';
import closeOutline from '@iconify-icons/eva/close-outline';

type ModalReportProps = {
  popupOpened: boolean;
  setPopupOpened: (popupOpened: boolean) => void;
  breakBy: string;
  selectedItem: string | undefined;
  period: string;
};

export const getPdfUrl = (selectedItem: string | undefined, breakBy: string, period: string, randomHash: string) => `
 ${isDev() ? 'http://localhost:9001/geo/' : ''}pdf/report/Job Postings [${selectedItem || 'Unknown'}] ${reportDateNow}.pdf
            ?dev=${isDev() ? 'true' : 'false'}&breakBy=${breakBy}&selectedItem=${selectedItem || 'Unknown'}&period=${period}#${randomHash}`;

const ModalReport: FC<ModalReportProps> = ({ popupOpened, setPopupOpened, breakBy, selectedItem, period }): JSX.Element => {
  const [showSpinner, setShowSpinner] = useState<boolean>(true);
  // random hash is needed for browser to know that content (PDF report) needs to be downloaded again on every popup open
  // (PDF report url isn't changed, and otherwise browser caches the PDF content
  const [randomHash, setRandomHash] = useState<string>('');

  useEffect(() => {
    // dec2hex :: Integer -> String
    // i.e. 0-255 -> '00'-'ff'
    function dec2hex(dec: number) {
      return dec.toString(16).padStart(2, '0');
    }

    // generateId :: Integer -> String
    function generateId(len: number): string {
      const arr = new Uint8Array((len || 40) / 2);
      window.crypto.getRandomValues(arr);
      return Array.from(arr, dec2hex).join('');
    }

    setShowSpinner(true);
    if (popupOpened) {
      setRandomHash('');

      setTimeout(() => {
        const hash = generateId(20);
        setRandomHash(hash);
      }, 0);
    }
  }, [popupOpened]);

  return (
    <Modal
      title="Report"
      centered
      visible={popupOpened}
      closeIcon={<Icon icon={closeOutline} width={22} height={22} />}
      // icon={<QuestionOutlined />}
      onOk={() => setPopupOpened(false)}
      onCancel={() => setPopupOpened(false)}
      width={1100}
      // height={700}
      className="report-modal"
      footer={[
        <Button key="ok" size="middle" type="primary" onMouseDown={() => setPopupOpened(false)}>
          OK
        </Button>,
      ]}
    >
      <div className="iframe-container flex justify-center items-center">
        {showSpinner && <Spin />}
        {randomHash !== '' && (
          <iframe
            height={700}
            src={getPdfUrl(selectedItem, breakBy, period, randomHash)}
            title="PDF"
            onLoad={() => setShowSpinner(false)}
          />
        )}
      </div>
    </Modal>
  );
};

export default ModalReport;
