import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createUser } from 'state/users/actions';
import { CREATE_USER_SUCCESS, USERS_ERROR } from 'state/users/actionTypes';
import UserForm from 'components/Administration/UserForm';
import { CreateUserRequest } from 'types/models';
import { UserInstance } from 'types/base';

interface CreateUserFormProps {
  handleHideModal?: () => void;
  formObj?: FormObj;
  setFormObj?: (form: FormObj) => void;
}

const CreateUserFrom: FC<CreateUserFormProps> = ({ handleHideModal, formObj, setFormObj }): JSX.Element => {
  const dispatch = useDispatch<any>();
  const [instances, setInstances] = useState<UserInstance[]>([]);

  useEffect(() => {
    if (handleHideModal) setInstances([]);
  }, [handleHideModal]);

  const handleSubmit = (values: CreateUserRequest): void => {
    dispatch(
      createUser({
        ...values,
        accessPermissions: instances.map(item => item.userPermissions[0]),
      }),
    ).then(({ type, payload }: any) => {
      if (type === CREATE_USER_SUCCESS) {
        if (handleHideModal) handleHideModal();
      }
      if (type === USERS_ERROR) {
        if (payload.violations) {
          formObj.setFields(
            payload.violations.map((err: ErrorFields) => ({
              name: err.fieldName,
              errors: [err.message],
            })),
          );
        }
      }
    });
  };

  return <UserForm type="create" onFinish={handleSubmit} setFormObj={setFormObj} />;
};

export default CreateUserFrom;
