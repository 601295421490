import React, { FC } from 'react';

import L from 'leaflet';
import { SVGOverlay } from 'react-leaflet';
import uacitiesjson from '../../common/geojson/UA-cities/ua-cities.json';

const DevCityNames: FC = (): JSX.Element => {
  return (
    <>
      {uacitiesjson.map((city: any, index: number) => {
        const southWestLocal = L.latLng(Number(city.lat) - 0.6, city.lng);
        const northEastLocal = L.latLng(Number(city.lat) + 0.5, Number(city.lng) + 10);
        const boundsLocal = L.latLngBounds(southWestLocal, northEastLocal);

        if (city.capital === 'primary') {
          return (
            <SVGOverlay key={index} pane={'city'} bounds={boundsLocal}>
              <text x="13" y="51%" stroke="#333" fontSize={17}>
                {city.city}
              </text>
            </SVGOverlay>
          );
        } else if (city.capital === 'admin') {
          return (
            <SVGOverlay key={index} pane={'city'} bounds={boundsLocal}>
              {/*<rect x="0" y="0" width="100%" height="100%" fill="red" />*/}
              <text x="10" y="50%" stroke="#333" fontSize={13}>
                {city.city}
              </text>
            </SVGOverlay>
          );
        } /*else {
              return <Circle pane={'city'} center={[city.lat, city.lng] as LatLngTuple} color={'#F9F516'} radius={100} />;
            }*/
      })}
    </>
  );
};

export default DevCityNames;
