import * as types from 'state/users/actionTypes';
import { ActionsTypes } from 'state/users/actions';
import { UserDto } from 'types/models';

export interface UsersState {
  error: any;
  loading: LoadingTypes;
  users: UserDto[];
  user: UserDto | null;
}

const initialState: UsersState = {
  error: null,
  loading: 'idle',
  users: [],
  user: null,
};

const reducer = (state = initialState, action: ActionsTypes): UsersState => {
  switch (action.type) {
    case types.USERS_LOADING: {
      return { ...state, loading: action.payload, error: null };
    }
    case types.USERS_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: 'idle',
      };
    }
    case types.GET_USERS_SUCCESS: {
      return {
        ...state,
        loading: 'idle',
        users: action.payload,
      };
    }
    case types.CREATE_USER_SUCCESS: {
      return {
        ...state,
        loading: 'idle',
        users: [...state.users, action.payload],
      };
    }
    case types.GET_CURRENT_USER_SUCCESS: {
      return {
        ...state,
        loading: 'idle',
        user: action.payload,
      };
    }
    case types.UPDATE_USER_SUCCESS: {
      return {
        ...initialState,
        users: [
          ...state.users
            .filter(user => user.id !== action.payload.id)
            .map(user => ({
              ...user,
              lastModifiedBy: action.lastModifiedBy === user.lastModifiedBy ? action.payload.lastModifiedBy : user.lastModifiedBy,
            })),
          action.payload,
        ],
        user:
          action.payload.id === state.user?.id
            ? {
                ...state.user,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                lastModifiedBy: action.payload.lastModifiedBy,
                lastModified: action.payload.lastModified,
                admin: action.payload.admin,
                userPermissions: action.payload.userPermissions,
              }
            : state.user,
      };
    }
    default:
      return state;
  }
};

export default reducer;
