import React from 'react';

class UaLangHtml {
  public getNationalSymbolsP1() {
    return (
      <>
        <p>
          Кожна держава має символіку, що відрізняє її від інших. Символи можна побачити на грошах, офіційних документах, печатках, поштових
          марках тощо. Наші державні символи перелічені у <b>статті 20 Конституції України</b>. Державними символами України є малий герб,
          прапор і гімн.
        </p>
        <p>
          Державний прапор України — стяг із двох рівновеликих горизонтальних смуг синього й жовтого кольорів. Синій — колір неба, а жовтий
          — золотої ниви та сонця. Так українці тлумачать значення національних кольорів. День державного Прапора в Україні{' '}
          <b>відзначають 23 серпня</b>.
        </p>
        <p>Національний прапор - неодмінний атрибут державних, політичних і громадських заходів.</p>
        <p>За правилом геральдики, кольори прапора читаються згори вниз, тому назва українського прапора "жовто-блакитний" дещо невірна.</p>
        <p>
          Спочатку козаки користувалися прапорами різних кольорів, найпопулярнішим був малиновий. Але вже в XVIII ст. у <b>Гетьманщині</b>{' '}
          почали переважати сині (блакитні) прапори із зображенням козака на золотому щитовому полі.
        </p>
      </>
    );
  }

  public getNationalSymbolsP2() {
    return (
      <p>
        Синій і жовтий це кольори державної символіки <b>Галицько-Волинського князівства</b>. У XIX ст. українські патріоти Галичини почали
        використовувати сучасні синьо-жовті прапори — дві зшиті смуги тканини значно простіше, ніж вишивати лева. На початку XX ст.
        синьо-жовті прапори поширилися й Наддніпрянською Україною. Українські селяни вбачали в них синє небо над полем золотої пшениці.
      </p>
    );
  }

  public getNationalSymbolsP3() {
    return (
      <>
        <p>
          Під час "Весни народів", 22 квітня 1948 року, українські патріоти вивісили прапор над Львівською ратушею. Це було його{' '}
          <b>перше публічне використання</b>.{' '}
        </p>
        <p>
          Синьо-жовтий стяг був державним прапором Української Народної Республіки (УНР), Західноукраїнської Народної Республіки (ЗУНР) та
          Української Держави. Над будинком центральної Ради його підняли в березні 1917 року. Під цим прапором виходили на демонстрації
          українські громадяни, а українські вояки йшли з ним у бій.
        </p>
        <p>
          <b>24 серпня 1991 року</b> громадяни принесли до Верховної Ради величезний синьо-жовтий прапор. Його довжина складала 8 м, а
          ширина — 4 м. Зараз він зберігається в музеї Верховної Ради.
        </p>
        <p>У вересні 1991 року синьо-жовтий прапор замість червоного радянського підняли над Верховною Радою.</p>
      </>
    );
  }

  public getNationalSymbolsP4() {
    return (
      <>
        <p>
          Державний малий герб України — золотий тризуб на синьому тлі. Він зображується на державних установах, офіційних документах,
          бланках, грошах тощо. Історія тризуба як символу дуже давня. Він зображений на печатці князя Святослава (Х ст.).
        </p>
        <p>
          Верховна Рада затвердила його <b>19 лютого 1992 року</b>.
        </p>
        <p>
          Невідомо, чи був тризуб власне українським символом, чи запозичений у сусідніх народів. Думки дослідників з цього приводу
          різняться, як і стосовно того, що саме він собою символізує. Дехто вбачає в ньому сокола, ворону, дерево з трьома гілками,
          вершечок булави, якір, лук і стріли, шолом, сокиру, рибальське знаряддя. Комусь тризуб нагадує верхівку хлібного колоса, а комусь
          поєднання трьох природних стихій — повітря, землі й води.
        </p>
        <p>
          Один з <b>найдавніших тризубів</b> знайдених на території України бронзовий тризуб зі скіфського кургану Лугова Могила (Запорізька
          область). На кожному з зубців знахідки сидять бронзові пташки. Науковці датують його III - IV ст. н.е.
        </p>
      </>
    );
  }

  public getNationalSymbolsP5() {
    return (
      <>
        <p>
          Тризуб був знаком княжої влади <b>Володимира Великого</b> та його нащадків. Карбувався на срібних монетах Володимира Великого. З
          одного боку монет зображувався сам Володимир, а з іншого - тризуб і напис: "Володимир на столі, а се — його срібло". Як символ
          князівської влади тризуб був поширений на наших землях упродовж кількох століть.
        </p>
        <p>
          Також Тризуби зображували на перснях, зброї, посуді, стінах будинків тощо. До нашого часу збереглося понад 2000 таких зображень.
        </p>
        <p>
          Якийсь час у Великому Князівстві Литовському був такий князівський герб. Його називають «Колюмни» або «Гедимінові стовпи». Деякі
          дослідники вважають, що цей Герб є оновленою версією давньоруського тризуба.
        </p>
      </>
    );
  }

  public getNationalSymbolsP5_1() {
    return (
      <>
        <p>
          Минуло понад 900 років від правління князя Володимира, перш ніж тризуб знову став гербом української держави — Української
          Народної Республіки. Автором Герба став видатний український художник та архітектор <b>Василь Кричевський</b>.{' '}
        </p>
        <p>
          Гетьман Павло Скоропадський планував затвердити новий Державний Герб із поєднанням князівських та козацьких символів. Проєкт
          намалював художник Георгій Нарбут.
        </p>
      </>
    );
  }

  public getNationalSymbolsP6() {
    return (
      <>
        <p>
          Державний Гімн України — пісня <b>«Ще не вмерла України і слава і воля»</b>. Гімн — урочиста патріотична пісня, символ єднання
          української нації.{' '}
        </p>
        <p>
          Її текст, напевно, знає кожний українець. Гімн лунає під час офіційних заходів і спортивних змагань, у свята й у важку годину. Всі
          урочисті збори та події обов'язково розпочинаються з гімну і закінчуються його виконанням. Слухають його стоячи.
        </p>
        <p>
          Ця пісня народилася у 1863 р. У часи революції вона стала державним гімном Української Народної Республіки (УНР) та
          Західноукраїнської Народної Республіки (ЗУНР), а <b>1939 р — гімном України</b>.
        </p>
        <p>
          Вірш, що став текстом гімну, написав член київської «Громади» Павло Чубинський. А через три роки після публікації вірша священник
          Михайло Вербицький написав до нього музику. Автори гімну не були знайомі особисто.
        </p>
        <p>
          Офіційно гімн України було затверджено в роки незалежності: <b>в 1992 році</b> — музичну редакцію, а <b>у 2003 році</b> — слова
          гімну. Він складається всього з шести рядків:
        </p>
        <p>
          <b>
            <span>Ще не вмерла України і слава, і воля,</span>
            <span>Ще нам, браття молодії, усміхнеться доля.</span>
            <span>Згинуть наші воріженьки, як роса на сонці,</span>
            <span>Запануєм і ми, браття, у своїй сторонці.</span>
          </b>
        </p>
        <p>
          <b>Приспів</b>
        </p>
        <p>
          <b>
            <span>Душу й тіло ми положим за нашу свободу,</span>
            <span>І покажем, що ми, браття, козацького роду.</span>
          </b>
        </p>
      </>
    );
  }

  public getVerbytskyDescription() {
    return <>Михайло Вербицький, автор музики гімну.</>;
  }

  public getChubynskyDescription() {
    return <>Павло Чубинський, автор тексту гімну.</>;
  }

  public getCoatOfArmsDescription1() {
    return <>Малий державний герб УНР Кричевського.</>;
  }

  public getCoatOfArmsDescription2() {
    return <>Великий державний герб УНР за проєктом Кричевського. 1918.</>;
  }

  public getCoatOfArmsDescription3() {
    return <>Велика державна печатка УНР Кричевського. 1918.</>;
  }

  public getMarketingText() {
    return (
      <>
        <p>
          За підтримки Luxoft внутрішніх ініціатив по розробці благодійних проектів її спеціалістами, був створений веб-додаток для вивчення
          інтерактивної мапи України на основі даних із відкритих джерел.
        </p>
        <p>
          Ініціатива створена, щоб допомогти усім бажаючим ознайомитись із областями та містами України, їхньою історією та визначними
          фактами про них. Додаток дає змогу всім зацікавленим громадянам та іноземцям вивчати географію та різні культурні аспекти України
          на інтерактивній мапі, а також перевіряти свої знання шляхом проходження різноманітних тестів. Є можливість відслідковувати свій
          прогрес тестування за категоріями. Для цього два окремих розділи додатку реалізовано англійською та українською мовами.
        </p>
        <p>
          Ми щиро сподіваємось, що ця ініціатива допоможе заохотити користувачів до подальшого дослідження культури, історії та традицій
          України. Бо українці зараз переживають найважчі часи в історії Незалежної України, демонструючи справжню незламність волі та
          козацьку мужність у триваючому захисту Батьківщини.
        </p>
        <p>
          Ми любимо нашу країну і хочемо поділитися цим із вами. Будьте допитливі, при нагоді вивчайте та перевіряйте здобуті знання.
          Обізнаність про Україну - це тренд! 
        </p>
        <p className={'pt-10'}>
          Запити на отримання додаткової інформації та Ваші пропозиції можете надсилати нам{' '}
          <a className={'link'} href="mailto:exploreukraineapp@gmail.com" target={'_blank'}>
            електронною поштою
          </a>
          .
        </p>
      </>
    );
  }
}

export default UaLangHtml;
