import L, { DivIcon } from 'leaflet';
import { isTouch } from './commonUtils';

export const zoomToLayer = (map: any, layer: any, bringToFront = true) => {
  if (map && layer) {
    const southWest = L.latLng(
        layer.getBounds().getSouth() - 0.1 * Math.abs(layer.getBounds().getNorth() - layer.getBounds().getSouth()),
        layer.getBounds().getWest() - 0.1 * Math.abs(layer.getBounds().getEast() - layer.getBounds().getWest()),
      ),
      northEast = L.latLng(
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        layer.getBounds().getNorth() + 0.1 * Math.abs(layer.getBounds().getNorth() - layer.getBounds().getSouth()),
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        layer.getBounds().getEast() + 0.1 * Math.abs(layer.getBounds().getEast() - layer.getBounds().getWest()),
      );
    const bounds = L.latLngBounds(southWest, northEast);
    // @ts-ignore
    map.flyToBounds(bounds, isTouch ? { duration: 0.001 } : { duration: 0.71, easeLinearity: 0.1 });

    if (bringToFront && !L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  }
};

export const noIconStyle = (): DivIcon => {
  const markerHtmlStyles = `
      width: 0px;
      height: 0px;
      pointer-events: none;`;

  const iconStyle = L.divIcon({
    className: 'no-icon-pin',
    iconAnchor: [0, 23],
    // labelAnchor: [-6, 0],
    // popupAnchor: [30, -36],
    html: `<span style="${markerHtmlStyles}" />`,
  });
  return iconStyle;
};

export const getSvgIconStyle = (color: string): DivIcon => {
  // const svgIconStyle = L.icon({
  //   iconUrl: markerSvg,
  //   iconSize: [32, 32],
  //   iconAnchor: [16, 32],
  // });
  const myCustomColour = color ? color : '#583470';

  const markerHtmlStyles = `
      fill: ${myCustomColour};
      width: 32px;
      max-width: 32px;
      height: 32px;
    `;
  const svgIconStyle = L.divIcon({
    className: 'my-custom-pin-2',
    iconAnchor: [15, 44],
    // labelAnchor: [-6, 0],
    popupAnchor: [0, -36],
    // eslint-disable-next-line max-len
    html: `<div style="${markerHtmlStyles}"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path stroke="#FFF" stroke-width="15" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"/></svg></div>`,
  });

  return svgIconStyle;
};

export const getHtmlIconStyle = (fillColor = '#583470', borderColor = '#FFFFFF'): DivIcon => {
  const markerHtmlStyles = `
      background-color: ${fillColor};
      width: 32px;
      height: 32px;
      display: block;
      left: -16px;
      top: -16px;
      position: relative;
      border-radius: 16px 16px 0;
      transform: rotate(45deg);
      border: 1px solid ${borderColor};`;

  const htmlIconStyle = L.divIcon({
    className: 'my-custom-pin',
    iconAnchor: [0, 23],
    // labelAnchor: [-6, 0],
    // popupAnchor: [30, -36],
    html: `<span style="${markerHtmlStyles}" />`,
  });
  return htmlIconStyle;
};
