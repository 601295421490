class EventEmitter {
  events: any;

  constructor() {
    this.events = {};
  }

  getEventListByName(eventName: string) {
    if (typeof this.events[eventName] === 'undefined') {
      this.events[eventName] = new Set();
    }

    return this.events[eventName];
  }

  on(eventName: string, fn: any) {
    this.getEventListByName(eventName).add(fn);
  }

  once(eventName: string, fn: any) {
    const onceFn = (...args: any[]) => {
      this.removeListener(eventName, onceFn);
      fn.apply(this, args);
    };
    this.on(eventName, onceFn);
  }

  emit(eventName: string, ...args: (string | boolean | undefined)[]) {
    this.getEventListByName(eventName).forEach((fn: any) => {
      fn.apply(this, args);
    });
  }

  removeListener(eventName: string, fn: any) {
    this.getEventListByName(eventName).delete(fn);
  }
}

export default EventEmitter;
