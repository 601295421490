import enLang from './lang/en';
import uaLang from './lang/ua';
import UaLangHtml from './html/uk-UA';
import EnLangHtml from './html/en-US';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export const locales: { [key: string]: any } = {
  'en-US': {
    title: 'English',
    flag: 'us flag',
    messages: enLang,
    htmlMessages: new EnLangHtml(),
  },
  'uk-UA': {
    title: 'Ukrainian',
    flag: 'ua flag',
    messages: uaLang,
    htmlMessages: new UaLangHtml(),
  },
};

export const i18n = (key: string, values: Record<string, ReactNode> | undefined) => <FormattedMessage id={key} values={values} />;

export const getIntlMessages = (locale: string) => {
  if (Object.keys(locales).includes(locale)) {
    return locales[locale].messages;
  } else {
    return locales['en-US'].messages;
  }
};

export const getIntlHtmlMessages = (locale: string) => {
  if (Object.keys(locales).includes(locale)) {
    return locales[locale].htmlMessages;
  } else {
    return locales['en-US'].htmlMessages;
  }
};
