import * as types from 'state/filter/actionTypes';
import { ActionsTypes } from 'state/filter/actions';

export interface FilterState {
  breakBy: string;
  selectedItem: string | undefined;
  period: string;
  top20: boolean;
  groupBySector: boolean;
}

const initialState: FilterState = {
  breakBy: 'Regions',
  selectedItem: undefined,
  period: 'latest',
  top20: true,
  groupBySector: false,
};

const reducer = (state = initialState, action: ActionsTypes): FilterState => {
  switch (action.type) {
    case types.SELECT_BREAK_BY:
      return { ...state, breakBy: action.payload };
    case types.SELECT_ITEM:
      return { ...state, selectedItem: action.payload };
    case types.SELECT_PERIOD:
      return { ...state, period: action.payload };
    case types.SELECT_TOP20:
      return { ...state, top20: action.payload };
    case types.SELECT_GROUP_BY_SECTOR:
      return { ...state, groupBySector: action.payload };
    default:
      return state;
  }
};

export default reducer;
