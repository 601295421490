import { Button, Menu } from 'antd';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import './NationalSymbolsOfUkraine.scss';
import Group245 from '../../../styles/assets/images/nationalsymbols/Group 245.svg';
import Group246 from '../../../styles/assets/images/nationalsymbols/Group 246.svg';
import Group247 from '../../../styles/assets/images/nationalsymbols/Group 247.svg';
import verhovna_rada_flag from '../../../styles/assets/images/nationalsymbols/verhovna_rada_flag.jpg';
import image31 from '../../../styles/assets/images/nationalsymbols/image 31.png';
import Group137 from '../../../styles/assets/images/nationalsymbols/Group 137.svg';
import coins1 from '../../../styles/assets/images/nationalsymbols/Odessa numismatic museum photo 05.png';
import coins2 from '../../../styles/assets/images/nationalsymbols/coins.png';
import Coat_of_Arms_of_UNR from '../../../styles/assets/images/nationalsymbols/Coat_of_Arms_of_UNR.svg';
import Great_state_seal_of_the_UPR from '../../../styles/assets/images/nationalsymbols/Great_state_seal_of_the_UPR.svg';
import KrychevskyGreat_State_Emblem_of_Ukrainian_Peoples_Republic from '../../../styles/assets/images/nationalsymbols/Krychevsky-Great_State_Emblem_of_Ukrainian_Peoples_Republic_(1918).png';
import Anthem from '../../../styles/assets/audio/Anthem-of-Ukraine_Chorus_Veryovka.mp3';
import Mykhaylo_Verbytsky from '../../../styles/assets/images/nationalsymbols/Mykhaylo_Verbytsky.jpg';
import Pavlo_Chubynsky from '../../../styles/assets/images/nationalsymbols/Pavlo_Chubynsky6.png';
import Shche_ne_vmerla_Ukraina from '../../../styles/assets/images/nationalsymbols/Shche_ne_vmerla_Ukraina.png';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { useOnScreen } from '../common/useOnScreen';
import AppContext from '../../../common/AppContext';
import { getIntlHtmlMessages } from '../../../common/localization';

const NationalSymbolsOfUkraine: FC = (): JSX.Element => {
  const intl = useIntl();
  const history = useHistory();
  const { locale } = useContext(AppContext);

  const navLinks = [
    { text: intl.formatMessage({ id: 'regionInfoFlag' }), navLinkId: 'flag' },
    { text: intl.formatMessage({ id: 'regionInfoCoatOfArms' }), navLinkId: 'coatOfArms' },
    { text: intl.formatMessage({ id: 'regionInfoAnthem' }), navLinkId: 'anthem' },
  ];

  const [activeLink, setActiveLink] = useState<string>(navLinks[0].navLinkId);

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash && navLinks.map(link => link.navLinkId).includes(hash)) {
      setActiveLink(hash);

      document.getElementById(hash)?.scrollIntoView({
        behavior: 'smooth', // gives an ease-in-out effect to our scroll
      });
    }
  }, []);

  const useNav = (navLinkId: string) => {
    const ref = useRef(null);

    const isOnScreen = useOnScreen(ref);

    useEffect(() => {
      if (isOnScreen) {
        setActiveLink(navLinkId);
      }
    }, [isOnScreen, setActiveLink, navLinkId]);

    return ref;
  };

  const flagRef = useNav('flag');
  const coatOfArmsRef = useNav('coatOfArms');
  const anthemRef = useNav('anthem');

  const textLocalized = getIntlHtmlMessages(locale);

  return (
    <div className="geo-root flex-1 flex symbols">
      <Button
        className={'cursor-pointer back'}
        onMouseDown={() => {
          history.push('/geo/landing');
        }}
      >
        {intl.formatMessage({ id: 'backToLanding' })}
      </Button>

      <div className={'side-panel p-24 relative'}>
        <div className={'symbols-header'}>{intl.formatMessage({ id: 'menuNationalSymbols' })}</div>
        <Menu mode={'inline'} selectedKeys={[activeLink]}>
          {navLinks.map(navLink => {
            return (
              <Menu.Item
                key={navLink.navLinkId}
                onMouseDown={() => {
                  setActiveLink(navLink.navLinkId);
                  document.getElementById(navLink.navLinkId)?.scrollIntoView({
                    behavior: 'smooth', // gives an ease-in-out effect to our scroll
                  });
                }}
              >
                {navLink.text}
              </Menu.Item>
            );
          })}
        </Menu>
      </div>

      <div className={'px-24 pb-24 content'}>
        <div>
          <div id={'flag'} className={'content-header'} ref={flagRef}>
            {intl.formatMessage({ id: 'regionInfoFlag' })}
          </div>
          <div className={'flex flex-row'}>
            <div className={'text'}>{textLocalized.getNationalSymbolsP1()}</div>
            <div className={'images'}>
              <Zoom zoomMargin={20} wrapStyle={{ marginBottom: 64 }}>
                <img src={Group247} alt={'Group247'} />
              </Zoom>
              <Zoom zoomMargin={20} wrapStyle={{ marginBottom: 64 }}>
                <img src={Group245} alt={'Group245'} />
              </Zoom>
            </div>
          </div>
          <div className={'flex flex-row'}>
            <div className={'text'}>{textLocalized.getNationalSymbolsP2()}</div>
            <div className={'images'}>
              <Zoom zoomMargin={20} wrapStyle={{ marginBottom: 64 }}>
                <img src={Group246} alt={'Group246'} />
              </Zoom>
            </div>
          </div>
          <div className={'flex flex-row'}>
            <div className={'text'}>{textLocalized.getNationalSymbolsP3()}</div>
            <div className={'images'}>
              <Zoom zoomMargin={20} wrapStyle={{ marginBottom: 64 }}>
                <img src={verhovna_rada_flag} alt={'verhovna_rada_flag'} />
              </Zoom>
            </div>
          </div>

          <div id={'coatOfArms'} className={'content-header'} ref={coatOfArmsRef}>
            {intl.formatMessage({ id: 'regionInfoCoatOfArms' })}
          </div>
          <div className={'flex flex-row'}>
            <div className={'text'}>{textLocalized.getNationalSymbolsP4()}</div>
            <div className={'images'}>
              <Zoom zoomMargin={20} wrapStyle={{ marginBottom: 64 }}>
                <img src={Group137} alt={'Group137'} />
              </Zoom>
              <Zoom zoomMargin={20} wrapStyle={{ marginBottom: 64 }}>
                <img src={image31} alt={'image31'} />
              </Zoom>
            </div>
          </div>
          <div className={'flex flex-row'}>
            <div className={'text'}>{textLocalized.getNationalSymbolsP5()}</div>
            <div className={'images'}>
              <Zoom zoomMargin={20} wrapStyle={{ marginBottom: 64 }}>
                <img src={coins1} alt={'coins'} />
              </Zoom>
              <Zoom zoomMargin={20} wrapStyle={{ marginBottom: 64 }}>
                <img src={coins2} alt={'coin'} />
              </Zoom>
            </div>
          </div>
          <div className={'flex flex-row'}>
            <div className={'text'}>{textLocalized.getNationalSymbolsP5_1()}</div>
            <div className={'images'}>
              <div className={'flex flex-row relative'}>
                <div className={'flex flex-col flex-1 pr-24'}>
                  <Zoom zoomMargin={20} wrapStyle={{ marginBottom: 12 }}>
                    <img src={Coat_of_Arms_of_UNR} alt={'Frame'} style={{ height: 76 }} />
                  </Zoom>
                  <div className={'image-caption'}>{textLocalized.getCoatOfArmsDescription1()}</div>
                </div>
                <div className={'flex flex-col flex-1 pr-24'}>
                  <Zoom zoomMargin={20} wrapStyle={{ marginBottom: 12 }}>
                    <img src={Great_state_seal_of_the_UPR} alt={'Frame'} style={{ height: 76 }} />
                  </Zoom>
                  <div className={'image-caption'}>{textLocalized.getCoatOfArmsDescription2()}</div>
                </div>
                <div className={'flex flex-col flex-1'}>
                  <Zoom zoomMargin={20} wrapStyle={{ marginBottom: 12 }}>
                    <img src={KrychevskyGreat_State_Emblem_of_Ukrainian_Peoples_Republic} alt={'Frame'} style={{ height: 76 }} />
                  </Zoom>
                  <div className={'image-caption'}>{textLocalized.getCoatOfArmsDescription3()}</div>
                </div>
              </div>
            </div>
          </div>

          <div id={'anthem'} className={'content-header mt-24'} ref={anthemRef}>
            {intl.formatMessage({ id: 'regionInfoAnthem' })}
          </div>
          <div className={'flex flex-row'}>
            <div className={'text'}>{textLocalized.getNationalSymbolsP6()}</div>
            <div className={'images'}>
              <audio controls src={Anthem}>
                Your browser does not support the
                <code>audio</code> element.
              </audio>
              <div className={'flex flex-row relative'}>
                <div className={'flex flex-col flex-1 p-12 pt-24'}>
                  <Zoom zoomMargin={20}>
                    <img src={Mykhaylo_Verbytsky} alt={'Mykhaylo_Verbytsky'} height={168} />
                  </Zoom>
                  <div className={'image-caption'}>{textLocalized.getVerbytskyDescription()}</div>
                </div>
                <div className={'flex flex-col flex-1 p-12 pt-24'}>
                  <Zoom zoomMargin={20}>
                    <img src={Pavlo_Chubynsky} alt={'Pavlo_Chubynsky'} height={168} />
                  </Zoom>
                  <div className={'image-caption'}>{textLocalized.getChubynskyDescription()}</div>
                </div>
              </div>
              <div className={'flex flex-row relative'}>
                <div className={'flex flex-col flex-1 p-12 pt-24'}>
                  <Zoom zoomMargin={20}>
                    <img src={Shche_ne_vmerla_Ukraina} alt={'Shche_ne_vmerla_Ukraina'} />
                  </Zoom>
                </div>
                <div className={'flex flex-col flex-1 p-12 pt-24'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NationalSymbolsOfUkraine;
