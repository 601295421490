import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, Layout, Menu, MenuProps, notification, Space } from 'antd';
import LeftMenu from 'components/common/LeftMenu';
// import AppPageContext from 'components/common/AppPageContext';
import UserMenu from 'components/common/UserMenu';
import { useSelector } from 'react-redux';
import { UserSelector } from 'state/users/selectors';
import luxoftsvg from '../../../styles/assets/images/logo-white-new.svg';
import './Header.scss';
import { useHistory, useLocation } from 'react-router-dom';
import AppContext from '../../../common/AppContext';

import English_language from '../../../styles/assets/images/English_language.svg';
import Ukrainian_language from '../../../styles/assets/images/Ukrainian_language.svg';
import GeoHuntingLogo from '../../../styles/assets/images/GeoHuntingLogo.svg';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
// import { DownOutlined } from '@ant-design/icons'; // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);

const Header: FC = (): JSX.Element => {
  const intl = useIntl();
  const location = useLocation();
  const history = useHistory();
  const currentUser = useSelector(UserSelector);
  let initialTime;
  try {
    initialTime = dayjs().tz('Europe/Kyiv').format('HH:mm');
  } catch (e: any) {
    try {
      initialTime = dayjs().tz('Europe/Kiev').format('HH:mm');
    } catch (e1: any) {
      initialTime = '[No data]';
    }
  }
  const [UkraineTime, setUkraineTime] = useState<string>(initialTime);
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  // const { title: moduleHeader } = useContext(AppPageContext);
  const { locale, setLocale /*devMode, setDevMode*/ } = useContext(AppContext);

  const { Header: HeaderLayout } = Layout;

  const onPageLoad = () => {
    setTimeout(() => {
      setPageLoaded(true);
    }, 500);
  };

  useEffect(() => {
    onPageLoad();

    setInterval(() => {
      try {
        setUkraineTime(dayjs().tz('Europe/Kyiv').format('HH:mm'));
      } catch (e: any) {
        try {
          setUkraineTime(dayjs().tz('Europe/Kiev').format('HH:mm'));
        } catch (e1: any) {
          setUkraineTime('[No data]');
        }
      }
    }, 1000);
  }, []);

  /*const onLocaleChange = (newLocale: string): void => {
   setLocale(newLocale);
 };
 const onDevModeChecked = (checked: boolean): void => {
   setDevMode(checked);
 };*/

  const handleMenuClick: MenuProps['onClick'] = e => {
    setLocale(e.key);
    notification.destroy();
  };

  // @ts-ignore
  const menu = useMemo(
    () => (
      <Menu onClick={handleMenuClick} selectedKeys={[locale]} style={{ width: 162 }}>
        <Menu.Item key={'uk-UA'}>
          <div className={'flex items-center w-full h-full'}>
            <img className={'ml-4 mr-12'} src={Ukrainian_language} alt="UA" height={14} width={20} />
            <span className={'language-item'}>Українська</span>
          </div>
        </Menu.Item>
        <Menu.Item key={'en-US'}>
          <div className={'flex items-center w-full h-full'}>
            <img className={'ml-4 mr-12'} src={English_language} alt="EN" height={14} width={20} />
            <span className={'language-item'}>English</span>
          </div>
        </Menu.Item>
      </Menu>
    ),
    [locale],
  );

  return (
    <>
      {location?.pathname.includes('geo') || currentUser ? (
        <div className="header">
          <HeaderLayout className={`shadow-lg flex items-center justify-between header-box ${pageLoaded ? 'header-front' : ''}`}>
            <div className="flex flex-row items-center">
              <div
                className={clsx('flex flex-col pt-24 pb-24 pr-24 application-name cursor-pointer z-10')}
                onMouseDown={() => {
                  history.push('/geo/landing');
                }}
              >
                {/*<span className={clsx('text-sm')}>
                  <FormattedMessage id="Luxoft Geo" defaultMessage="Luxoft Geo" />
                </span>*/}
                {/*<span className="text-lg font-bold leading-8 select-none">{moduleHeader}</span>*/}
                <img className={'ml-4 ml-24'} src={GeoHuntingLogo} alt="UA" />
              </div>
            </div>
            {/*<div className="absolute w-full flex justify-center company-logo">
              <img src={luxoftsvg} alt="luxoft" height={42} />
            </div>*/}

            <div className="absolute w-full flex justify-center">
              <div className={'flex flex-col items-center'}>
                <div className={'time'}>{UkraineTime}</div>
                <div className={'time-label'}>{intl.formatMessage({ id: 'time' })}</div>
              </div>
            </div>
            <div className="flex flex-row items-center z-10">
              <UserMenu />
              <div className={'flex flex-row items-center'}>
                {/*<div className={'pr-12'}>
                <span className={'pr-4'}>dev mode</span>
                <Switch checked={devMode} onChange={onDevModeChecked} />
              </div>*/}
                <Dropdown
                  className={'language-select flex items-center p-18'}
                  placement={'bottomCenter'}
                  overlay={menu}
                  trigger={['click']}
                >
                  <span className={'cursor-pointer'} onClick={e => e.preventDefault()}>
                    <Space>
                      {locale === 'uk-UA' ? (
                        <img className={'ml-4'} src={Ukrainian_language} alt="UA" height={17} />
                      ) : (
                        <img className={'ml-4'} src={English_language} alt="EN" height={17} />
                      )}
                    </Space>
                  </span>
                </Dropdown>
                {/*
                <Select className="mr-24 language-select" value={locale} onChange={onLocaleChange}>
                  <Option value="uk-UA">
                    <div className={'flex items-center w-full h-full'}>
                      <img className={'ml-4 mr-12'} src={Ukrainian_language} alt="UA" height={14} width={20} />
                      <span className={'language-item'}>Українська</span>
                    </div>
                  </Option>
                  <Option value="en-US">
                    <div className={'flex items-center w-full h-full'}>
                      <img className={'ml-4 mr-12'} src={English_language} alt="EN" height={14} width={20} />
                      <span className={'language-item'}>English</span>
                    </div>
                  </Option>
                </Select>*/}
              </div>
              {<LeftMenu />}
            </div>
          </HeaderLayout>

          {/* Backside */}
          <HeaderLayout
            className={`shadow-lg flex items-center justify-between header-box header-back-box ${pageLoaded ? 'header-back' : ''}`}
          >
            {/*<div className="absolute w-full flex justify-center company-logo">
              <img src={luxoftsvg} alt="luxoft" height={42} />
            </div>*/}
            <div className="flex flex-row items-center">{currentUser && currentUser.admin && <LeftMenu />}</div>
            {/* <UserMenu /> */}
          </HeaderLayout>
        </div>
      ) : (
        <div className="header login">
          <HeaderLayout className={`shadow-lg flex items-center justify-between header-box ${pageLoaded ? 'header-front' : ''}`}>
            {/* <span className="pl-24 text-lg font-bold">
              <FormattedMessage
                id="Luxoft Geo"
                defaultMessage="Luxoft Geo"
              />
            </span> */}
            <div className="absolute w-full flex justify-center company-logo">
              <img src={luxoftsvg} alt="luxoft" height={42} />
            </div>
            <div style={{ visibility: 'hidden' }}>
              <span className="pr-24 text-lg font-bold">
                <FormattedMessage id="Luxoft Geo" defaultMessage="Luxoft Geo" />
              </span>
            </div>
          </HeaderLayout>
          <HeaderLayout
            className={`shadow-lg flex items-center justify-between header-box header-back-box ${pageLoaded ? 'header-back' : ''}`}
          >
            <div className="absolute w-full flex justify-center company-logo">
              <img src={luxoftsvg} alt="luxoft" height={42} />
            </div>
          </HeaderLayout>
        </div>
      )}
    </>
  );
};

export default Header;
