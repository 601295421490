import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { GeoJSON, MapContainer, Pane, TileLayer, useMapEvents, ZoomControl } from 'react-leaflet';
import { isPhone, isPortrait } from '../../../common/utils/commonUtils';
import { zoomToLayer } from '../../../common/utils/leafletUtils';
import AppContext from '../../../common/AppContext';
import { LatLngTuple } from 'leaflet';
import { getThemeColors } from '../../../common/themes/Themes';
import * as topojson from 'topojson-client';
import uajson from '../common/geojson/UA/ukraine-geo.json';
import Regions from '../common/components/Regions';
import CityDots from '../common/components/CityDots';
import * as d3 from 'd3';
import './Explore.scss';
import * as turf from '@turf/turf';
import Control from 'react-leaflet-custom-control';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Icon } from '@iconify/react';
import closeOutline from '@iconify-icons/eva/close-outline';
import RegionInfoPopup from '../common/components/RegionInfoPopup';
import { Checkbox, Divider, Drawer, Modal } from 'antd';
import uacitiesjson from '../common/geojson/UA-cities/ua-cities.json';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';

let selectedCity: string | undefined;
let setSelectedCity: any;
const initialLayers: { [key: string]: boolean } = {
  exploreNames: true,
  exploreRivers: true,
  exploreVyshyvanka: false,
  exploreDishes: false,
};

const Explore: FC = (): JSX.Element => {
  const { theme, locale } = useContext(AppContext);
  const intl = useIntl();

  const [map, setMap] = useState<any | undefined>(undefined);
  const [settingsPanelVisible, setSettingsPanelVisible] = useState(false);
  const [layers, setLayers] = useState<{ [key: string]: boolean }>(initialLayers);
  const [selectedRegion, selectRegion] = useState<any | undefined>(undefined);
  const geoJSONRef = useRef<any | undefined>(undefined);
  const cityCircles = useRef<any | undefined>(undefined);
  const zoomCenter = useRef<LatLngTuple | undefined>([48.8, 32]);
  const zoomLevel = useRef<number>(5);
  const themeColors = useMemo(() => getThemeColors(theme), [theme]);
  const currentZoom = useRef<number>(zoomLevel.current);
  const history = useHistory();

  const citiesWithTooltip = useRef<{ [key: string]: any }>({});
  const removeTooltipsTimeout = useRef<any>(undefined);

  // @ts-ignore
  const geoDataRegions = topojson.feature(uajson, uajson.objects.regions).features;

  // const southWest = L.latLng(53.4, 42.85);
  // const northEast = L.latLng(44.2, 21.15);
  // const bounds = L.latLngBounds(southWest, northEast);

  // function LogBoundsComponent() {
  //   const mapEvents = useMapEvents({
  //     zoomend: () => {
  //       console.log(mapEvents.getBounds());
  //     },
  //   });
  //
  //   return null;
  // }

  function getOpacitySmallCitiesTooltips() {
    // start showing starting from selected zoom level
    return Math.min(Math.max(0, currentZoom.current - 7), 1);
  }

  function getOpacityBigCitiesTooltips() {
    // start showing starting from selected zoom level
    return Math.min(Math.max(0, currentZoom.current - 6), 1);
  }

  function getOpacityBigCitiesDots() {
    // start showing starting from selected zoom level
    return Math.min(Math.max(0, currentZoom.current - 6), 1);
  }

  function getOpacityRegionTooltips() {
    // start showing starting from selected zoom level
    return 1 - Math.min(Math.abs(currentZoom.current - 6), 1);
  }

  function removeAllBoundTooltips() {
    for (const cityName of Object.keys(citiesWithTooltip.current)) {
      citiesWithTooltip.current[cityName].unbindTooltip();
    }
    citiesWithTooltip.current = {};
  }

  function updateTooltips() {
    if (layers.exploreNames && map && map.getPanes().mapPane !== undefined) {
      if (map.getZoom() <= 7) {
        if (!removeTooltipsTimeout.current) {
          removeTooltipsTimeout.current = setTimeout(() => {
            removeAllBoundTooltips();
          }, 1000);
        }
      } else {
        if (removeTooltipsTimeout.current) {
          clearTimeout(removeTooltipsTimeout.current);
          removeTooltipsTimeout.current = undefined;
        }
        const bounds = map.getBounds();
        const bboxPoly = turf.bboxPolygon([bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()]);

        const oldLayersWithTooltip = citiesWithTooltip.current;
        citiesWithTooltip.current = {};

        for (const cityName of Object.keys(cityCircles.current)) {
          const cityRef = cityCircles.current[cityName].ref;
          const isSmallCity = !cityCircles.current[cityName].isAdminCenter;
          const tooltipDirection = cityCircles.current[cityName].tooltipDirection;

          // console.log(
          //   turf.booleanContains(bboxPoly, {
          //     type: 'Feature',
          //     properties: {
          //       'marker-color': '#0f0',
          //     },
          //     geometry: {
          //       type: 'Point',
          //       coordinates: [cityName.getLatLng().lng, cityName.getLatLng().lat],
          //     },
          //   }),
          // );

          if (turf.booleanContains(bboxPoly, turf.point([cityRef.getLatLng().lng, cityRef.getLatLng().lat])) && isSmallCity) {
            citiesWithTooltip.current[cityName] = cityRef;
            if (typeof cityRef.getTooltip() == 'undefined' || cityRef.getTooltip() == null) {
              cityRef.bindTooltip(cityName, {
                permanent: true,
                direction: tooltipDirection || 'top',
                // direction: tooltipDirection !== undefined ? tooltipDirection : 'top',
                pane: 'tooltips',
                offset:
                  tooltipDirection === 'left'
                    ? [-5, 0]
                    : tooltipDirection === 'bottom'
                    ? [0, 5]
                    : tooltipDirection === 'right'
                    ? [5, 0]
                    : tooltipDirection === 'center'
                    ? [0, 0]
                    : [0, -5], // top by default
                // offset: tooltipDirection === 'left' ? [-5, 0] : [0, -5],
                className: `explore-city-tooltip city-tooltip-small`,
                interactive: true,
              });
              // cityRef.getTooltip().bringToBack();
            } else {
              delete oldLayersWithTooltip[cityName];
            }
          }
        }

        for (const id of Object.keys(oldLayersWithTooltip)) {
          oldLayersWithTooltip[id].unbindTooltip();
        }
      }
    }
  }

  function onZoomEnd() {
    updateTooltips();

    if (layers.exploreNames) {
      d3.selectAll('.explore-city.city-circle-small').interrupt();
      d3.selectAll('.explore-city.city-circle-small')
        .style('pointer-events', getOpacitySmallCitiesTooltips() === 0 ? 'none' : 'all')
        .transition()
        .duration(700)
        .ease(d3.easeCubicOut)
        .attr('stroke-opacity', getOpacitySmallCitiesTooltips())
        .attr('fill-opacity', getOpacitySmallCitiesTooltips());

      d3.selectAll('.explore-city-tooltip.city-tooltip-small').interrupt();
      d3.selectAll('.explore-city-tooltip.city-tooltip-small')
        .style('pointer-events', getOpacitySmallCitiesTooltips() === 0 ? 'none' : 'all')
        .transition()
        .duration(700)
        .ease(d3.easeCubicOut)
        .style('opacity', getOpacitySmallCitiesTooltips());

      d3.selectAll('.explore-region-tooltip').interrupt();
      d3.selectAll('.explore-region-tooltip').transition().duration(700).ease(d3.easeCubicOut).style('opacity', getOpacityRegionTooltips());

      d3.selectAll('.explore-city-tooltip.city-tooltip-big').interrupt();
      d3.selectAll('.explore-city-tooltip.city-tooltip-big')
        .style('pointer-events', getOpacityBigCitiesTooltips() === 0 ? 'none' : 'all')
        .transition()
        .duration(700)
        .ease(d3.easeCubicOut)
        .style('opacity', getOpacityBigCitiesTooltips());

      d3.selectAll('.explore-city.city-circle-big').interrupt();
      d3.selectAll('.explore-city.city-circle-big')
        .style('pointer-events', getOpacityBigCitiesDots() === 0 ? 'none' : 'all')
        .transition()
        .duration(700)
        .ease(d3.easeCubicOut)
        .attr('stroke-opacity', getOpacityBigCitiesDots())
        .attr('fill-opacity', getOpacityBigCitiesDots());
    }
  }

  function ZoomListenerComponent() {
    const mapEvents = useMapEvents({
      dragstart: () => {
        if (map && map.getPanes().mapPane !== undefined) {
          map.closePopup();
        }
      },
      moveend: () => {
        updateTooltips();
      },
      zoomstart: () => {
        if (map && map.getPanes().mapPane !== undefined) {
          map.closePopup();
        }
      },
      zoomend: () => {
        currentZoom.current = mapEvents.getZoom();

        onZoomEnd();
      },
    });

    return null;
  }

  useEffect(() => {
    removeAllBoundTooltips();
    onZoomEnd();
  }, [theme, locale]);

  removeAllBoundTooltips();
  onZoomEnd();

  function onRegionInfoPopupClose() {
    d3.selectAll('.region-info-city').interrupt();
    d3.selectAll('.region-info-city').attr('stroke-opacity', 0).attr('fill-opacity', 0);
    d3.selectAll('.region-info-city-tooltip').classed('selected', false);
    d3.selectAll('.region-info-city-tooltip').classed('active', false);

    setTimeout(() => {
      selectRegion(undefined);
      selectedCity = undefined;
    }, 100);
  }

  const onClose = (): void => {
    setSettingsPanelVisible(false);
  };

  const onToggle = (): void => {
    setSettingsPanelVisible(!settingsPanelVisible);
  };

  const toggleLayer = (layer: string): any => {
    layers[layer] = !layers[layer];
    setLayers(cloneDeep(layers));
  };

  return (
    <div className="flex flex-col flex-1 explore">
      <div className="geo-root flex-1 flex flex-row">
        <div className={'map-wrapper'}>
          <MapContainer
            attributionControl={false}
            center={zoomCenter.current}
            zoom={zoomLevel.current}
            minZoom={isPhone ? 4 : 4}
            maxZoom={10}
            // maxBounds={bounds}
            preferCanvas={false}
            // preferCanvas={theme === 'designcanvas'}
            // renderer={theme === 'designcanvas' ? L.canvas() : L.svg()}
            zoomControl={false}
            tap={false}
            ref={(ref: any) => {
              // if (ref && ref.map) {
              // @ts-ignore
              if (geoJSONRef && geoJSONRef.current) {
                setTimeout(() => {
                  if (map === undefined) {
                    setTimeout(() => {
                      // @ts-ignore
                      zoomToLayer(ref, geoJSONRef.current, false);
                    }, 0);
                  }

                  setMap(ref);

                  if (ref && !d3.select(ref.getPanes().patterns).select('svg').select('defs').node()) {
                    d3.select(ref.getPanes().patterns)
                      .select('svg')
                      .insert('defs', ':first-child')
                      .html(
                        '<pattern id="Ternopil" patternUnits="userSpaceOnUse" width="160" height="96">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Ternopil.png" x="0" y="0" width="160" height="96" />' +
                          '</pattern>' +
                          '<pattern id="Sumy" patternUnits="userSpaceOnUse" width="184" height="90">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Sumy.png" x="0" y="0" width="184" height="90" />' +
                          '</pattern>' +
                          '<pattern id="Kherson" patternUnits="userSpaceOnUse" width="143" height="94">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Kherson.png" x="0" y="0" width="143" height="94" />' +
                          '</pattern>' +
                          '<pattern id="Chernivtsi" patternUnits="userSpaceOnUse" width="147" height="90">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Chernivtsi.png" x="0" y="0" width="147" height="90" />' +
                          '</pattern>' +
                          '<pattern id="Khmelnytskyi" patternUnits="userSpaceOnUse" width="153" height="100">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Khmelnytskyi.png" x="0" y="0" width="153" height="100" />' +
                          '</pattern>' +
                          '<pattern id="Cherkasy" patternUnits="userSpaceOnUse" width="141" height="97">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Cherkasy.png" x="0" y="0" width="141" height="97" />' +
                          '</pattern>' +
                          '<pattern id="Chernihiv" patternUnits="userSpaceOnUse" width="214" height="97">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Chernihiv.png" x="0" y="0" width="214" height="97" />' +
                          '</pattern>' +
                          '<pattern id="Crimea" patternUnits="userSpaceOnUse" width="214" height="93">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Crimea.png" x="0" y="0" width="214" height="93" />' +
                          '</pattern>' +
                          '<pattern id="Kharkiv" patternUnits="userSpaceOnUse" width="158" height="97">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Kharkiv.png" x="0" y="0" width="158" height="97" />' +
                          '</pattern>' +
                          '<pattern id="Rivne" patternUnits="userSpaceOnUse" width="155" height="97">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Rivne.png" x="0" y="0" width="155" height="97" />' +
                          '</pattern>' +
                          '<pattern id="Dnipropetrovsk" patternUnits="userSpaceOnUse" width="136" height="97">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Dnipropetrovsk.png" x="0" y="0" width="136" height="97" />' +
                          '</pattern>' +
                          '<pattern id="Luhansk" patternUnits="userSpaceOnUse" width="102" height="96">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Luhansk.png" x="0" y="0" width="102" height="96" />' +
                          '</pattern>' +
                          '<pattern id="Lviv" patternUnits="userSpaceOnUse" width="171" height="93">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Lviv.png" x="0" y="0" width="171" height="93" />' +
                          '</pattern>' +
                          '<pattern id="Mykolaiv" patternUnits="userSpaceOnUse" width="165" height="100">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Mykolaiv.png" x="0" y="0" width="165" height="100" />' +
                          '</pattern>' +
                          '<pattern id="Odesa" patternUnits="userSpaceOnUse" width="134" height="88">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Odesa.png" x="0" y="0" width="134" height="88" />' +
                          '</pattern>' +
                          '<pattern id="Poltava" patternUnits="userSpaceOnUse" width="128" height="96">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Poltava.png" x="0" y="0" width="128" height="96" />' +
                          '</pattern>' +
                          '<pattern id="Kirovohrad" patternUnits="userSpaceOnUse" width="153" height="101">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Kirovohrad.png" x="0" y="0" width="153" height="101" />' +
                          '</pattern>' +
                          '<pattern id="Kyiv" patternUnits="userSpaceOnUse" width="171" height="92">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Kyiv.png" x="0" y="0" width="171" height="92" />' +
                          '</pattern>' +
                          '<pattern id="Zaporizhzhia" patternUnits="userSpaceOnUse" width="195" height="97">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Zaporizhzhia.png" x="0" y="0" width="195" height="97" />' +
                          '</pattern>' +
                          '<pattern id="Zakarpattia" patternUnits="userSpaceOnUse" width="141" height="96">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Zakarpattia.png" x="0" y="0" width="141" height="96" />' +
                          '</pattern>' +
                          '<pattern id="Zhytomyr" patternUnits="userSpaceOnUse" width="184" height="101">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Zhytomyr.png" x="0" y="0" width="184" height="101" />' +
                          '</pattern>' +
                          '<pattern id="Vinnytsia" patternUnits="userSpaceOnUse" width="194" height="93">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Vinnytsia.png" x="0" y="0" width="194" height="93" />' +
                          '</pattern>' +
                          '<pattern id="Volyn" patternUnits="userSpaceOnUse" width="148" height="87">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Volyn.png" x="0" y="0" width="148" height="87" />' +
                          '</pattern>' +
                          '<pattern id="Ivano-Frankivsk" patternUnits="userSpaceOnUse" width="179" height="97">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Ivano-Frankivsk.png" x="0" y="0" width="179" height="97" />' +
                          '</pattern>' +
                          '<pattern id="Donetsk" patternUnits="userSpaceOnUse" width="162" height="93">' +
                          '<image href="' +
                          process.env.PUBLIC_URL +
                          '/images/Vyshyvanka/Donetsk.png" x="0" y="0" width="162" height="93" />' +
                          '</pattern>',
                      );
                  }
                  //<defs>
                  //   <pattern id="img1" patternUnits="userSpaceOnUse" width="100" height="100">
                  //     <image href="wall.jpg" x="0" y="0" width="100" height="100" />
                  //   </pattern>
                  // </defs>
                }, 100);
              }
              // }
            }}
          >
            {/*<LogBoundsComponent />*/}
            <ZoomListenerComponent />
            <Pane name="rivers" style={{ zIndex: 10001, pointerEvents: 'none' }} />
            <Pane name="borders" style={{ zIndex: 10002, pointerEvents: 'none' }} />
            <Pane name="patterns" style={{ zIndex: 10002, pointerEvents: 'none' }} />
            <Pane name="city" style={{ zIndex: 10003 }} />
            <Pane name="markers" style={{ zIndex: 10004 }} />
            <Pane name="tooltips" style={{ zIndex: 10005 }} />
            <Pane name="popups" style={{ zIndex: 10006 }} />
            <TileLayer
              // url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png"
              url="https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}{r}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
              noWrap={false}
            />
            <GeoJSON
              data={geoDataRegions as GeoJSON.GeoJsonObject}
              style={() => {
                return {
                  fillColor: themeColors.region,
                  weight: 1,
                  opacity: 1,
                  color: 'transparent',
                  dashArray: '0',
                  fillOpacity: 1,
                };
              }}
            />

            <CityDots
              bigCityOpacity={0}
              smallCityOpacity={0}
              tooltipOpacity={0}
              smallCityRadius={1000}
              className={'explore-city'}
              tooltipClass={'explore-city-tooltip'}
              showAdminCenterOnly={false}
              showNameOnHover={false}
              showNames={false}
              showNamesAdminCenterOnly={true}
              cityCircles={cityCircles}
              // color={'#FFFFFF'}
              useDirection={false}
              interactive={true}
              map={map}
              onCityClick={(cityName: string) => {
                if (geoJSONRef.current) {
                  selectedCity = cityName;
                  const cityJsons = uacitiesjson.filter(city => Object.values(city.name).includes(cityName));
                  let oblast = '';
                  if (cityJsons.length > 0) {
                    oblast = cityJsons[0].admin_name;
                  }

                  const oblastLayer = geoJSONRef.current.getLayers().filter((layer: any) => {
                    return Object.values(layer.feature.properties.localized_name).includes(oblast);
                  });
                  if (oblastLayer.length > 0) {
                    selectRegion(oblastLayer[0]);

                    setTimeout(() => {
                      setSelectedCity(cityName);
                    }, 0);
                  }
                }
              }}
            />

            <Regions
              geoJSONRef={geoJSONRef}
              useOnClick={true}
              nameOpacity={0}
              showNameOnHover={false}
              showRivers={layers.exploreRivers}
              longRegionName={false}
              tooltipClass={'explore-region-tooltip'}
              onModalChange={onZoomEnd}
              selectedRegion={selectedRegion}
              selectRegion={selectRegion}
            />

            {layers.exploreVyshyvanka && (
              <GeoJSON
                data={geoDataRegions as GeoJSON.GeoJsonObject}
                pane={'patterns'}
                style={(feature: any) => {
                  const name: string = feature ? feature.properties?.name : '';
                  return {
                    fillColor: `url('#${name}')`,
                    weight: 1,
                    opacity: 1,
                    color: '#555',
                    // color: themeColors.regionBorder,
                    dashArray: '0',
                    fillOpacity: 1,
                  };
                }}
              />
            )}

            <Control position="bottomleft" prepend={true}>
              <div
                className={'control-button'}
                onMouseDown={() => {
                  history.push('/geo/landing');
                }}
              >
                {intl.formatMessage({ id: 'backToLanding' })}
              </div>
            </Control>
            <Control position="bottomright" prepend={false}>
              <div
                className={'control-button'}
                onMouseDown={() => {
                  zoomToLayer(map, geoJSONRef.current, false);
                }}
              >
                {intl.formatMessage({ id: 'ukraine' })}
              </div>
            </Control>
            <Control position="topright" prepend={false}>
              <div
                className={'control-button'}
                onMouseDown={() => {
                  onToggle();
                }}
              >
                {intl.formatMessage({ id: 'exploreSettings' })}
              </div>
            </Control>
            <ZoomControl position="bottomright" />
          </MapContainer>
          <Modal
            title={selectedRegion ? selectedRegion.feature.properties.localized_name[locale] : ''}
            centered
            destroyOnClose={true}
            closeIcon={<Icon icon={closeOutline} width={22} height={22} />}
            width={1200}
            visible={selectedRegion !== undefined}
            onOk={onRegionInfoPopupClose}
            onCancel={onRegionInfoPopupClose}
            footer={null}
            wrapClassName={`region-info-popup ${isPortrait() ? 'portrait' : 'landscape'}`}
          >
            <RegionInfoPopup
              selectedRegion={selectedRegion}
              selectedCity={selectedCity}
              onMount={setSelectedCityLocal => {
                setSelectedCity = setSelectedCityLocal;
              }}
            />
          </Modal>
          <Drawer
            className="settings-menu"
            title={<span className="text-xl text-black font-bold">{intl.formatMessage({ id: 'exploreSettings' })}</span>}
            placement="right"
            onClose={onClose}
            visible={settingsPanelVisible}
            closeIcon={<Icon icon={closeOutline} width={22} height={22} />}
            bodyStyle={{
              padding: 0,
              display: 'flex',
              flexDirection: 'column',
            }}
            width={350}
          >
            <div className={'settings-menu-content flex flex-col'}>
              <div className={clsx('text-base secondary-text')}>{intl.formatMessage({ id: 'exploreShow' })}</div>
              <Checkbox
                checked={layers.exploreNames}
                onClick={() => {
                  toggleLayer('exploreNames');
                }}
              >
                {intl.formatMessage({ id: 'exploreNames' })}
              </Checkbox>
              <Checkbox
                checked={layers.exploreRivers}
                onClick={() => {
                  toggleLayer('exploreRivers');
                }}
              >
                {intl.formatMessage({ id: 'exploreRivers' })}
              </Checkbox>
              <Checkbox
                checked={layers.exploreVyshyvanka}
                onClick={() => {
                  toggleLayer('exploreVyshyvanka');
                  if (layers.exploreVyshyvanka && layers.exploreNames) {
                    toggleLayer('exploreNames');
                  }
                }}
              >
                {intl.formatMessage({ id: 'exploreVyshyvanka' })}
              </Checkbox>
              <Checkbox
                checked={layers.exploreDishes}
                disabled
                onClick={() => {
                  toggleLayer('exploreDishes');
                }}
              >
                {intl.formatMessage({ id: 'exploreDishes' })}
              </Checkbox>
            </div>
            <Divider />
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default Explore;
