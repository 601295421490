import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from 'components/App';
import store from 'common/store';
import history from 'common/utils/historyUtils';

import 'styles/main.scss';
import 'styles/theme.scss';
import Auth from './components/common/Auth';

ReactDOM.render(
  <Provider store={store}>
    <Auth>
      <Router history={history}>
        <App />
      </Router>
    </Auth>
  </Provider>,
  document.getElementById('root'),
);
