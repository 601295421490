import * as types from 'state/auth/actionTypes';
import { ActionsTypes } from 'state/auth/actions';

export interface AuthState {
  error: any;
  loading: LoadingTypes;
}

const initialState: AuthState = {
  error: null,
  loading: 'idle',
};

const reducer = (state = initialState, action: ActionsTypes): AuthState => {
  switch (action.type) {
    case types.AUTH_LOADING: {
      return { ...state, loading: action.payload, error: null };
    }
    case types.AUTH_ERROR: {
      return { ...state, loading: 'idle', error: action.payload };
    }
    case types.AUTH_LOGIN_SUCCESS: {
      return initialState;
    }
    case types.AUTH_LOGOUT_SUCCESS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
