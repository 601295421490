import React, { FC, useContext, useMemo } from 'react';
import { isTouch } from '../../../../common/utils/commonUtils';
import { GeoJSON } from 'react-leaflet';
import { Tooltip } from '../../../../common/Tooltip/Tooltip';
import AppContext from '../../../../common/AppContext';
import { getThemeColors } from '../../../../common/themes/Themes';
import * as d3 from 'd3';
import L from 'leaflet';
import uajson from '../../common/geojson/UA/ukraine-geo.json';
import * as topojson from 'topojson-client';
import dayjs from 'dayjs';

interface TestRegionProps {
  geoJSONRef: any;
  questionsAndAnswers: { [key: string]: { correct: boolean | undefined; time: number } };
  answers: { [key: string]: string };
  currentQuestion: string;
  onFinishTest: () => void;
  nextQuestion: () => void;
}

const TestRegionsLicensePlate: FC<TestRegionProps> = ({
  geoJSONRef,
  currentQuestion,
  onFinishTest,
  questionsAndAnswers,
  answers,
  nextQuestion,
}): JSX.Element => {
  const { theme } = useContext(AppContext);
  const themeColors = useMemo(() => getThemeColors(theme), [theme]);
  let highlightedLayer: any;
  let selectedLayer: any;
  let answer: string | undefined;
  // const mylatesttap = 0;
  let startTime = dayjs();

  // @ts-ignore
  const geoDataRegions = topojson.feature(uajson, uajson.objects.regions).features;

  const resetHighlight = (layer: any) => {
    if (layer !== selectedLayer) {
      d3.select(layer._path).interrupt();
      d3.select(layer._path)
        .transition()
        .duration(300)
        .ease(d3.easeCubicOut)
        .attr('fill', 'transparent')
        .attr('stroke', themeColors.regionBorder);

      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        layer.bringToBack();
      }
    }
  };

  const resetHighlightAll = () => {
    if (geoJSONRef && geoJSONRef.current) {
      const geoJSON = geoJSONRef.current;
      for (const layerUa of geoJSON.getLayers()) {
        resetHighlight(layerUa);
      }
    }
  };

  const highlightLayer = (layer: any, doResetHighlight?: boolean, fill?: string, stroke?: string) => {
    if (doResetHighlight) {
      resetHighlight(highlightedLayer);
    }
    highlightedLayer = layer;

    if (layer !== undefined && layer !== null && layer !== selectedLayer) {
      d3.select(layer._path).interrupt();
      d3.select(layer._path)
        .transition()
        .duration(300)
        .ease(d3.easeCubicOut)
        .attr('fill', fill ? fill : themeColors.regionHighlight)
        .attr('stroke', stroke ? stroke : themeColors.regionHighlightBorder);

      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        layer.bringToFront();
      }
    }
  };

  const selectLayer = (layer: any) => {
    answer = layer.feature.properties.name;

    const correctAnswer = answers[currentQuestion];

    if (layer.feature.properties.localized_name['uk-UA'] !== correctAnswer) {
      highlightLayer(layer, false, themeColors.regionIncorrect, themeColors.regionIncorrectBorder);
    }

    // @ts-ignore
    questionsAndAnswers[currentQuestion].correct = layer.feature.properties.localized_name['uk-UA'] === correctAnswer;
    questionsAndAnswers[currentQuestion].time = dayjs().diff(startTime);
    const unansweredQuestions = Object.keys(questionsAndAnswers).filter(question => questionsAndAnswers[question].correct === undefined);
    if (unansweredQuestions.length === 0) {
      onFinishTest();
    }

    if (geoJSONRef && geoJSONRef.current) {
      const geoJSON = geoJSONRef.current;
      for (const layerUa of geoJSON.getLayers()) {
        // @ts-ignore
        if (layerUa.feature.properties.localized_name['uk-UA'] === correctAnswer) {
          highlightLayer(layerUa, false, themeColors.regionCorrect, themeColors.regionCorrectBorder);
        }
      }
    }

    setTimeout(() => {
      resetHighlightAll();

      setTimeout(() => {
        answer = undefined;
        nextQuestion();

        startTime = dayjs();
      }, 100);
    }, 1000);
  };

  const geoJsonHandlers = useMemo(
    () => ({
      mouseover: (e: any) => {
        if (!isTouch) {
          if (answer === undefined) {
            highlightLayer(e.layer);
          }
        }
      },
      // mousemove: e => {
      // Tooltip.updateTooltipPosition(e.originalEvent.clientX, e.originalEvent.clientY);
      // },
      mouseout: (e: any) => {
        if (!isTouch) {
          if (answer === undefined) {
            resetHighlight(e.layer);
          }
        }
        Tooltip.immediateCloseTooltip();
      },
      mousedown: (e: any) => {
        if (answer === undefined) {
          // if (isTouch) {
          //   const now = Date.now();
          //   const timesince = now - mylatesttap;
          //   if (timesince < 600 && timesince > 0) {
          //     // double tap
          //     selectLayer(e.layer);
          //   } else {
          //     // too much time to be a doubletap
          //     // highlightLayer(e.layer);
          //   }
          //
          //   mylatesttap = Date.now();
          // } else {
          selectLayer(e.layer);
          // }
        }
      },
    }),
    [answer, questionsAndAnswers, currentQuestion],
  );

  return (
    <>
      <GeoJSON
        pane={'borders'}
        data={geoDataRegions as GeoJSON.GeoJsonObject}
        eventHandlers={geoJsonHandlers}
        style={() => {
          return {
            fillColor: 'transparent',
            weight: 1,
            opacity: 1,
            color: themeColors.regionBorder,
            dashArray: '0',
            fillOpacity: 1,
          };
        }}
        ref={(ref: any) => {
          geoJSONRef.current = ref;
        }}
      />
    </>
  );
};

export default TestRegionsLicensePlate;
