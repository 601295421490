import * as types from 'state/data/actionTypes';
import { ActionsTypes } from 'state/data/actions';

export interface DataState {
  error: any;
  loading: LoadingTypes;
}

const initialState: DataState = {
  error: null,
  loading: 'idle',
};

const reducer = (state = initialState, action: ActionsTypes): DataState => {
  switch (action.type) {
    case types.DATA_LOADING: {
      return { ...state, loading: action.payload, error: null };
    }
    case types.DATA_LOADING_ERROR: {
      return { ...state, loading: 'idle', error: action.payload };
    }
    default:
      return state;
  }
};

export default reducer;
