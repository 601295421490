import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { deepCompareObj } from 'common/utils/commonUtils';
import UserForm from 'components/Administration/UserForm';

import { updateUser } from 'state/users/actions';
import { UPDATE_USER_SUCCESS, USERS_ERROR } from 'state/users/actionTypes';
import { UserDataSource } from 'types/base';
import { UpdateUserRequest } from 'types/models';

interface UpdateUserFromProps {
  user: UserDataSource;
  handleHideModal?: () => void;
  formObj?: FormObj;
  setFormObj?: (form: FormObj) => void;
}

const UpdateUserFrom: FC<UpdateUserFromProps> = ({ user, handleHideModal, formObj, setFormObj }): JSX.Element => {
  const dispatch = useDispatch<any>();
  const { userId } = user;

  useEffect(() => {
    formObj?.setFieldsValue({ ...user, password: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formObj, handleHideModal]);

  const handleUpdateFormSubmit = (values: UpdateUserRequest): void => {
    const updatedData = {
      ...deepCompareObj(user, values),
    };

    dispatch(updateUser(userId, updatedData)).then(({ type, payload }: any) => {
      if (type === UPDATE_USER_SUCCESS) {
        if (handleHideModal) handleHideModal();
      }
      if (type === USERS_ERROR) {
        if (payload.violations) {
          formObj.setFields(
            payload.violations.map((err: ErrorFields) => ({
              name: err.fieldName,
              errors: [err.message],
            })),
          );
        }
      }
    });
  };

  return <UserForm type="update" onFinish={handleUpdateFormSubmit} setFormObj={setFormObj} />;
};

export default UpdateUserFrom;
