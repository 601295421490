import React, { FC, useContext, useEffect, useState } from 'react';
import AppContext from '../../../../common/AppContext';
import { getMobileWikiUrl, getWikiUrl } from './CityDots';
import Zoom from 'react-medium-image-zoom';
import { useIntl } from 'react-intl';
import './RegionInfoPopup.scss';
import { getIconClassName } from '@uifabric/styling';

interface RegionInfoPopupDetailsPanelProps {
  regionInfo: any;
  onMount: (args: any) => void;
  onBack: () => void;
}

const RegionInfoPopupDetailsPanel: FC<RegionInfoPopupDetailsPanelProps> = ({ regionInfo, onMount, onBack }): JSX.Element => {
  const [selectedCity, setSelectedCity] = useState<string | undefined>(undefined);
  const { locale } = useContext(AppContext);
  const intl = useIntl();

  useEffect(() => {
    onMount([selectedCity, setSelectedCity]);
  }, [onMount, selectedCity]);

  return (
    <div className={'region-info flex'}>
      <div className={'region-description flex flex-col px-24'}>
        {!selectedCity && (
          <>
            <div className={'region-info-value pb-6'} dangerouslySetInnerHTML={{ __html: regionInfo.description }} />
            <div className={'region-info-header column-header'}>{intl.formatMessage({ id: 'regionInfoFoundationYear' })}</div>
            <div className={'region-info-value'}>{regionInfo.founded}</div>

            <div className={'region-info-header column-header'}>{intl.formatMessage({ id: 'regionInfoRegionalCenter' })}</div>
            <div className={'region-info-value'}>{regionInfo.regionalCenter}</div>
            <div className={'region-info-old-city-names'}>
              {regionInfo.capitalPreviousNames
                // .slice()
                // .reverse()
                .map((previousName: string) => {
                  return <div key={previousName}>{previousName}</div>;
                })}
            </div>

            <div className={'region-info-header column-header'}>{intl.formatMessage({ id: 'regionInfoCarRegionIdentifiers' })}</div>
            <div className={'region-info-value'}>{regionInfo.carNumbers}</div>
            <div className={'region-info-header column-header'}>{intl.formatMessage({ id: 'regionInfoTerritory' })}</div>
            <div className={'region-info-value'}>
              {regionInfo.territory} <span className={'region-info-value-comment'}>({regionInfo.territoryPercent})</span>
            </div>
            <div className={'region-info-header column-header'}>{intl.formatMessage({ id: 'regionInfoPopulation' })}</div>
            <div className={'region-info-value'}>
              {regionInfo.population} <span className={'region-info-value-comment pl-8'}>({regionInfo.populationDate})</span>
            </div>
            <div className={'region-info-header column-header'}>{intl.formatMessage({ id: 'regionInfoCoatOfArms' })}</div>
            <div className={'region-info-value'}>
              <div className={'flex flex-col items-center justify-center pt-8'}>
                <div className={'flex flex-row p-24'}>
                  {regionInfo.coatofarms.map((coatofarms: string) => {
                    return (
                      <div className={'flex-1'} key={coatofarms}>
                        <Zoom zoomMargin={20}>
                          <img
                            src={coatofarms}
                            alt={'coatOfArms'}
                            style={{ height: '230px', filter: 'drop-shadow(3px 4px 5px rgba(0, 0, 0, 0.4))' }}
                          />
                        </Zoom>
                      </div>
                    );
                  })}
                </div>
                <div className={'symbol-description'} dangerouslySetInnerHTML={{ __html: regionInfo.coatofarmsdescription.join('') }} />
              </div>
            </div>
            <div className={'region-info-header column-header'}>{intl.formatMessage({ id: 'regionInfoFlag' })}</div>
            <div className={'region-info-value'}>
              <div className={'flex flex-col items-center justify-center pt-8'}>
                <div className={'p-24'}>
                  <Zoom zoomMargin={20}>
                    <img
                      src={regionInfo.flag}
                      alt={'flag'}
                      style={{ height: '180px', borderRadius: 3, filter: 'drop-shadow(3px 4px 5px rgba(0, 0, 0, 0.4))' }}
                    />
                  </Zoom>
                </div>
                <div className={'symbol-description'} dangerouslySetInnerHTML={{ __html: regionInfo.flagdescription.join('') }} />
              </div>
            </div>
          </>
        )}
        {selectedCity && (
          <>
            <div className={'flex justify-between'}>
              <div
                className={'back flex flex-row items-center'}
                onMouseDown={() => {
                  setSelectedCity(undefined);
                  if (onBack) {
                    onBack();
                  }
                }}
              >
                <i className={getIconClassName('ChevronLeft') + ' pr-8'} /> {intl.formatMessage({ id: 'back' })}
              </div>
              <div
                className={'back flex flex-row items-center'}
                onMouseDown={() => {
                  setSelectedCity(undefined);
                  if (onBack) {
                    onBack();
                  }

                  const url = getWikiUrl(selectedCity, locale);
                  if (url !== '') {
                    window.open(url, '_blank')?.focus();
                  }
                }}
              >
                {intl.formatMessage({ id: 'wiki' })}
                <i className={getIconClassName('ChevronRight') + ' pl-8'} />
              </div>
            </div>
            <iframe style={{ height: '100%' }} title={'wiki'} src={getMobileWikiUrl(selectedCity, locale)} />
          </>
        )}
      </div>
    </div>
  );
};

export default RegionInfoPopupDetailsPanel;
